import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.css";
import '../homepage/Contact.css';
import { LiveHelp, ContactSupport, Email } from '@mui/icons-material';

const Contact = ({ openFAQ }) => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
  
    try {
      const response = await fetch('/.netlify/functions/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'feedback', // Differentiates this as a feedback email
          name: formData.name,
          email: formData.email,
          message: formData.message,
        }),
      });
  
      if (response.ok) {
        toast.success(t("toast.successMessage"), { autoClose: 3000 });
        setFormData({ name: '', email: '', message: '' }); // Clear form
      } else {
        toast.warn(t("toast.errorMessage"), { autoClose: 3000 });
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'An unexpected error occurred');
      toast.error(t("toast.errorMessage"), { autoClose: 3000 });
    }
  };
  
  const handleSupportEmail = async () => {
    setError('');
  
    try {
      const response = await fetch('/.netlify/functions/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          type: 'support', // Differentiates this as a support email
          name: formData.name || 'Anonymous',
          email: formData.email || 'support@facadewebapp.com',
          message: formData.message || 'Support request initiated.',
        }),
      });
  
      if (response.ok) {
        toast.success(t("toast.supportMessageSent"), { autoClose: 3000 });
        setFormData({ name: '', email: '', message: '' }); // Clear form
        setShowModal(false); // Close modal if open
      } else {
        toast.warn(t("toast.supportMessageFailed"), { autoClose: 3000 });
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'An unexpected error occurred');
      toast.error(t("toast.supportMessageFailed"), { autoClose: 3000 });
    }
  };
  

  return (
    <div className="container contact-container py-5">
      <h2 className="text-center mb-4">{t('contactForm.title')}</h2>
      <div className="contact-grid">
        {/* Left: Contact Form */}
        <div className="contact-form">
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <div className='contactIcon'>
            <Email />
             </div>
              <Form.Label>
               {t('contactForm.name')}
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={handleChange}
                value={formData.name}
                placeholder={t("contactForm.namePlaceholder")}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                {/* <i className="fas fa-envelope"></i> {t('contactForm.email')} */}
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                onChange={handleChange}
                value={formData.email}
                placeholder={t("contactForm.emailPlaceholder")}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                {/* <i className="fas fa-comment-alt"></i> {t('contactForm.message')} */}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="message"
                onChange={handleChange}
                value={formData.message}
                placeholder={t("contactForm.messagePlaceholder")}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              {t('submit')}
            </Button>
            {error && <p className="text-danger mt-2">{error}</p>}
          </Form>
        </div>

        {/* Right: Help Options */}
        <div className="help-options">
          <div className="help-card">
            <div className='contactIcon'>
              <LiveHelp />
            </div>
            <h4>{t('faqOptionTitle')}</h4>
            <button className="faq-button" onClick={openFAQ}>
              {t('goToIt')}
            </button>
          </div>
          <div className="help-card">
            <div className='contactIcon'>
              <ContactSupport />
            </div>
            <h4>{t('supportOptionTitle')}</h4>
            <button
              className="faq-button"
              onClick={() => setShowModal(true)} // Open modal
            >
              {t('goToIt')}
            </button>
          </div>
        </div>
      </div>

      {/* Modal for Support Request */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>{t('supportForm.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSupportEmail}>
            <Form.Group>
              <Form.Label>{t('supportForm.name')}</Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={handleChange}
                value={formData.name}
                placeholder={t("supportForm.namePlaceholder")}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('supportForm.email')}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                onChange={handleChange}
                value={formData.email}
                placeholder={t("supportForm.emailPlaceholder")}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{t('supportForm.message')}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="message"
                onChange={handleChange}
                value={formData.message}
                placeholder={t("supportForm.messagePlaceholder")}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              {t('goToIt')}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Contact;
