import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "bootstrap/dist/css/bootstrap.css";
import "../help/FAQ.css";

const FAQ = ({ isFAQOpen }) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: t("faq.question1"),
      answer: (
        <>
          {t("faq.answer1")}
          {/* <div className="faq-image-container">
            <img
              src="/assets/faq/clear_image_example.png"
              alt="Exemple d'image claire"
              className="faq-image"
            />
            <p className="faq-caption">
              {t("faq.captionExample1", "Exemple d'une image claire pour une détection optimale.")}
            </p>
          </div> */}
        </>
      ),
    },
    {
      question: t("faq.question2"),
      answer: t("faq.answer2"),
    },
    {
      question: t("faq.question3"),
      answer: t("faq.answer3"),
    },
    {
      question: t("faq.question4"),
      answer: t("faq.answer4"),
    },
  ];

  return (
    <div className="faq-container">
      <h2>{t("faq.title")}</h2>
      <p>{t("faq.intro")}</p>
      <div className="faq-accordion">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? "active" : ""}`}
            onClick={() => toggleAccordion(index)}
          >
            <h4 className="faq-question">{faq.question}</h4>
            {activeIndex === index && <div className="faq-answer">{faq.answer}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
