import React from 'react'
import { useEffect } from 'react';
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.css";
import "tippy.js/dist/tippy.css";
import "./itemsSelect/itemDisplaySelectionModal.css";
import { useTranslation } from "react-i18next";

function ItemSelection(props) {

  const { t, i18n } = useTranslation(["translation"]);

  useEffect(() => {
    const container = document.querySelector('.selectionContainer');

    if (container) {
        container.addEventListener('touchstart', (e) => {
            e.stopPropagation(); // Prevent Bootstrap from blocking scroll
        }, { passive: true });
    }
}, []);

  const handleDropdownChange = (e) => {
    props.handlePanel(e);
  };

  return (
    <>
   <DropdownButton
    title={
      <img
        className='addItemsButton'
        src="./icons/addItemsButton.png"
        height="30px" /* Reduce button size */
        alt="Items"
        title='Items'
      />
    }
    className="btn mt-1 pb-0 m-0"
    variant="primary"
    menuVariant="dark"
    size="sm"
    drop="up"
    onSelect={handleDropdownChange}
    // menuClassName="dropdown-menu" /* Ensure CSS styling applies */
>
    <div className='selectionContainer'>
        <div className='dropdownSection'>
            <div className='dropdownTitleSection'>{t("itemSelection-windows")}</div>

            <Dropdown.Item className='itemTypeTitle' eventKey="Casement">
                {t("itemSelection-casement")}
                <img className='addItemsIcons' src="./thumbnails/Window_Casement_Double.png" height={15} alt="Casement" />
            </Dropdown.Item>

            <Dropdown.Item className='itemTypeTitle' eventKey="Sash">
                {t("itemSelection-sash")}
                <img className='addItemsIcons' src="./thumbnails/Window_Sash_Double.png" height={15} alt="Sash" />
            </Dropdown.Item>

            <Dropdown.Item className='itemTypeTitle' eventKey="Slider">
                {t("itemSelection-slider")}
                <img className='addItemsIcons' src="./thumbnails/Window_Slider_Single.png" height={15} alt="Slider" />
            </Dropdown.Item>

            <Dropdown.Item className='itemTypeTitle' eventKey="Contemporary">
                {t("itemSelection-contemporary")}
                <img className='addItemsIcons' src="./thumbnails/Window_Contemporary_ThirdLeftDivMiddle.png" height={15} alt="Contemporary" />
            </Dropdown.Item>

            <Dropdown.Item className='itemTypeTitle' eventKey="Fixe">
                {t("itemSelection-fixed")}
                <img className='addItemsIcons' src="./thumbnails/Window_Fixed_HalfMoon.png" height={15} alt="Fixe" />
            </Dropdown.Item>

            <div className='dropdownTitleSection'>{t("itemSelection-doors")}</div>

            <Dropdown.Item className='itemTypeTitle' eventKey="Single Door">
                {t("itemSelection-singleDoor")}
                <img className='addItemsIcons' src="./thumbnails/Single_Embossed1AGlassAt1NoSidelight.png" height={30} alt="Single Door" />
            </Dropdown.Item>

            <Dropdown.Item className='itemTypeTitle' eventKey="Double Door">
                {t("itemSelection-doubleDoor")}
                <img className='addItemsIcons' src="./thumbnails/double_Embossed1AGlassAt1NoSidelight.png" height={30} alt="Double Door" />
            </Dropdown.Item>

            <Dropdown.Item className='itemTypeTitle' eventKey="Garage Door">
                {t("itemSelection-garageDoor")}
                <img className='addItemsIcons' src="./thumbnails/GarageDoor_Classic_9x7.png" height={30} alt="Garage Door" />
            </Dropdown.Item>

            <div className='dropdownTitleSection'>{t("itemSelection-accessories")}</div>

            <Dropdown.Item className='itemTypeTitle' eventKey="Accessories">
                {t("itemSelection-accessories")}
                <img className='addItemsIcons' src="./thumbnails/shutter_standard_white.png" height={30} alt={t("itemSelection-accessories")} />
            </Dropdown.Item>
        </div>
    </div>
</DropdownButton>



      <div>

      </div>

  </>
);
}

export default ItemSelection;
