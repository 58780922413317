import React, { useState } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.css";
import "tippy.js/dist/tippy.css";
import { useTranslation } from "react-i18next";
// import "./itemsSelect/itemDisplaySelectionModal.css";
import FlipIcon from '@mui/icons-material/Flip';
import Delete from '@mui/icons-material/Delete';
import Grid from '@mui/icons-material/Grid4x4TwoTone';
import Ungroup from '@mui/icons-material/GroupRemove';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ToolsSelection(props) {

 const { t, i18n } = useTranslation(["translation"]);


  const handleDropdownChange = (e, event) => {

    let action = e 
    if (action === "copy") {
      props.copyItem()
      return
    }
    if (action === "selectall") {
      props.selectAll()
      return
    }
    if (action === "cut") {
      props.cutItem()
      return
    }
    if (action === "removeall") {
      props.removeAllObjects2()
      return
    }
    if (action === "paste") {
      props.pasteItem()
      return
    }
    if (action === "clone") {
      props.copyAndPasteItem()
      return
    }
    if (action === "flipX") {
      props.flipX();  // Updated this line
      return;
    }
    if (action === "flipY") {
      props.flipY();  // Updated this line
      return;
    }
    if (action === "ungroupItems") {
      props.ungroupItems();  // Updated this line
      return;
    }
    if (action === "erase") {
      props.setModeType("erase")
      props.changeAction(action)
      props.setEraseMode(true)
      return;
    }
    if (action === "draw") {
      props.setModeType("draw")
      props.changeAction(action)
      props.setDrawMode(true)
      return;
    }
    if (action === "perspective") {
      props.changePerspective(true)
      return;
    }
    if (action === "grid") {
      props.drawGrid()
      return;
    }
    else { props.changeAction(action) }

  };


  return (
    <>
      <DropdownButton
        title={
          <img
            className="toolsButton"
            src="/icons/tools.png"
            height="40px"
            alt="Tools"
            title={t("toolsSelection-tools")}
          />
        }
        className="btn mt-1 btn-block pb-0 m-0"
        variant="primary"
        menuVariant="dark"
        size="sm"
        drop="up"
        autoClose="true"
        onSelect={handleDropdownChange}
      >
        <div className="dropdownSection">
          <div className="dropdownTitleSection">{t("toolsSelection-tools")}</div>
          <Dropdown.Item className="itemTypeTitle" eventKey="perspective">
            Perspective
            <>
              <img
                className="addItemsIcons"
                src="/icons/icons/perspective.svg"
                height={20}  // This ensures the same height is applied directly.
                alt="Perspective"
              />
            </>
          </Dropdown.Item>
          <Dropdown.Item className="itemTypeTitle" eventKey="draw">
            {t("toolsSelection-draw")}
            <>
              <img
                className="addItemsIcons"
                src="/icons/pen.png"
                height={20}
                alt="Draw"
              />
            </>
          </Dropdown.Item>
          <Dropdown.Item className="itemTypeTitle" eventKey="erase">
            {t("toolsSelection-eraser")}
            <>
              <img
                className="addItemsIcons"
                src="/icons/eraser-solid.png"
                height={20}
                alt="Eraser"
              />
            </>
          </Dropdown.Item>
          {/* <Dropdown.Item className="itemTypeTitle" eventKey="selectall">
            {t("app-selectAll")}
            <>
              <img
                className="addItemsIcons"
                src="./icons/selectAllButton.png"
                height={20}
                alt="Select All"
              />
            </>
          </Dropdown.Item> */}
          <Dropdown.Item className="itemTypeTitle" eventKey="removeall">
            {t("app-removeAll")}
            <>
              <img
                className="addItemsIcons"
                src="./icons/RemoveAllButton.png"
                height={20}
                alt="Remove All"
              />
            </>
          </Dropdown.Item>
          <Dropdown.Item className="itemTypeTitle" eventKey="cut">
            {t("toolsSelection-cut")}
            <>
              <Delete />
            </>
          </Dropdown.Item>
          <Dropdown.Item className="itemTypeTitle" eventKey="copy">
            {t("toolsSelection-copy")}
            <>
              <img
                className="addItemsIcons"
                src="/icons/copy.png"
                height={20}
                alt="Copy"
              />
            </>
          </Dropdown.Item>
          <Dropdown.Item className="itemTypeTitle" eventKey="paste">
            {t("toolsSelection-paste")}
            <>
              <img
                className="addItemsIcons"
                src="/icons/paste.png"
                height={20}
                alt="Paste"
              />
            </>
          </Dropdown.Item>
          <Dropdown.Item className="itemTypeTitle" eventKey="clone">
            {t("toolsSelection-clone")}
            <>
              <img
                className="addItemsIcons"
                src="/icons/paste.png"
                height={20}
                alt="clone"
              />
            </>
          </Dropdown.Item>
          <Dropdown.Item className="itemTypeTitle" eventKey="flipX">
            {t("toolsSelection-flipx")}
            <>
              <FlipIcon style={{ padding: "4px" }} />
            </>
          </Dropdown.Item>
          <Dropdown.Item className="itemTypeTitle" eventKey="flipY">
            {t("toolsSelection-flipy")}
            <>
              <FlipIcon style={{ transform: 'rotate(90deg)', padding: "4px" }} />
            </>
          </Dropdown.Item>
          {/* <Dropdown.Item className="itemTypeTitle" eventKey="grid">
            {t("toolsSelection-grid")}
            <>
              <Grid style={{ padding: "4px" }} />
            </>
          </Dropdown.Item> */}
          {/* <Dropdown.Item className="itemTypeTitle" eventKey="ungroupItems">
            {t("toolsSelection-ungroup")}
            <>
              <Ungroup style={{ padding: "4px" }} />
            </>
          </Dropdown.Item> */}

        </div>
      </DropdownButton>
    </>
  );
}

export default ToolsSelection;
