import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { db, storage } from "../../base";
import { doc, deleteDoc, collection, query, where, getDocs, updateDoc } from "firebase/firestore";
import { ref, listAll, deleteObject } from "firebase/storage";
import { Button, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AdminModal = () => {
    const [showAdminModal, setShowAdminModal] = useState(false);
    const [email, setEmail] = useState('');
    const { t } = useTranslation();

    const toggleAdminModal = () => {
        setShowAdminModal(!showAdminModal);
    };

    // Find all users by email and delete their projects
    const findUsersAndDeleteProjects = async () => {
        try {
            console.log("Searching for users with email:", email);
            const usersRef = collection(db, "users");
            const q = query(usersRef, where("email", "==", email));
            const userSnapshots = await getDocs(q);

            if (userSnapshots.empty) {
                toast.error("No users found with this email.");
                console.error("Users not found:", email);
                return;
            }

            console.log(`Found ${userSnapshots.docs.length} users with email ${email}`);

            for (const userDoc of userSnapshots.docs) {
                const userId = userDoc.id;
                const userData = userDoc.data();

                console.log("Processing user:", userId);

                // Retrieve all projects associated with userId
                const projectsRef = collection(db, "Projects");
                const projectsQuery = query(projectsRef, where("userId", "==", userId));
                const projectSnapshot = await getDocs(projectsQuery);

                if (projectSnapshot.empty) {
                    toast.info(`No projects found for user: ${userId}`);
                    console.warn("No projects found for user:", userId);
                } else {
                    const projectIds = projectSnapshot.docs.map(doc => doc.id);
                    console.log(`User ${userId} has ${projectIds.length} projects.`);

                    // Delete each project
                    for (const projectId of projectIds) {
                        console.log(`Deleting project document: ${projectId}`);
                        await deleteDoc(doc(db, "Projects", projectId));
                        console.log(`Project ${projectId} successfully deleted.`);
                        await deleteProjectImages(userId, projectId);
                        toast.success(`Deleted project ${projectId}`);
                    }
                }

                // Delete user's entire storage
                await deleteUserStorage(userId);

                // Remove projectIds field from user document if it exists
                if (userData.projectIds) {
                    console.log(`Removing projectIds field from user document ${userId}`);
                    await updateDoc(doc(db, "users", userId), { projectIds: [] });
                    console.log("User document updated.");
                    toast.info("Removed project references from user document.");
                }
            }

            toast.success("All projects, images, and references for all users with this email have been deleted.");

        } catch (error) {
            console.error("Error deleting user projects:", error);
            toast.error("Error deleting user projects.");
        }
    };

    // Delete all images related to a project
    const deleteProjectImages = async (userId, projectId) => {
        const folderPath = `usersProjectsImages/${userId}/canvasImages/`;
        const folderRef = ref(storage, folderPath);

        try {
            console.log(`Listing images for deletion in: ${folderPath}`);
            const { items, prefixes } = await listAll(folderRef);

            if (items.length === 0) {
                console.warn(`No images found for project: ${projectId}`);
            }

            // Delete individual images
            for (const fileRef of items) {
                console.log(`Deleting image: ${fileRef.fullPath}`);
                await deleteObject(fileRef);
                console.log(`Deleted image: ${fileRef.fullPath}`);
                toast.info(`Deleted image: ${fileRef.name}`);
            }

            // Recursively delete subfolders
            for (const prefix of prefixes) {
                console.log(`Deleting subfolder: ${prefix.fullPath}`);
                await deleteAllFilesInFolder(prefix.fullPath);
            }

        } catch (error) {
            console.error("Error deleting images:", error);
            toast.error("Error deleting project images.");
        }
    };

    // Recursively delete all files in a folder
    const deleteAllFilesInFolder = async (folderPath) => {
        const folderRef = ref(storage, folderPath);
        try {
            console.log(`Listing files in folder: ${folderPath}`);
            const { items, prefixes } = await listAll(folderRef);

            // Delete all files
            for (const fileRef of items) {
                console.log(`Deleting file: ${fileRef.fullPath}`);
                await deleteObject(fileRef);
                console.log(`Deleted file: ${fileRef.fullPath}`);
            }

            // Recursively delete all subfolders
            for (const prefix of prefixes) {
                await deleteAllFilesInFolder(prefix.fullPath);
            }

        } catch (error) {
            console.error("Error deleting folder:", folderPath, error);
        }
    };

    // Delete user's entire storage directory
    const deleteUserStorage = async (userId) => {
        const userStoragePath = `usersProjectsImages/${userId}/`;
        console.log(`Deleting all storage for user: ${userId}`);
        await deleteAllFilesInFolder(userStoragePath);
    };

    return (
        <>
            <Button onClick={toggleAdminModal}>{t('admin')}</Button>

            <Modal show={showAdminModal} onHide={toggleAdminModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t('adminPanel')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>{t('Enter User Email')}</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="Enter user email" 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)} 
                            />
                        </Form.Group>
                        <Button onClick={findUsersAndDeleteProjects} className="mt-3">
                            {t('Find and Delete User Projects')}
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={toggleAdminModal}>
                        {t('close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AdminModal;
