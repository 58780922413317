import React, { useRef, useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { signup, useAuth, login, db, auth, logout, googleSignIn, sendPasswordResetEmail, sendVerificationEmail, setDoc } from "../../base";
import "../auth/auth.css";
import { getAuth } from "firebase/auth";
import firebase from "../../base";
import {
  doc,
  getDoc, updateDoc
} from "firebase/firestore";
import { useTranslation } from "react-i18next";
import ContentModal from '../contentModal/ContentModal';
import CombinedTermsOfUse from '../legal/CombinedTermsOfUse';
import { Timestamp } from "firebase/firestore";
import { handleAnalyticsEvent } from "../../helpers/analyticsHandler";


function Auth({ closeSignup, closeUpload, setUserRole, handleLogOut, setCloseAccount, setLogin, handleAccept, handleDecline,
  isLoginOut, setIsLoginOut, setProjectName, loginSet, toJson, clearCanvas, termsAccepted }) {
  const [error, setError] = useState(null);
  const [userRegion, setUserRegion] = useState(null);
  const [isProfileFormVisible, setIsProfileFormVisible] = useState(false);
  const currentUser = useAuth();
  const [isPasswordRecovery, setIsPasswordRecovery] = useState(false);
  document.body.style.overflow = "hidden";
  const { t, i18n } = useTranslation(["translation"]);



  // show terms

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState(null);
  const [termsViewed, setTermsViewed] = useState(false);

  const handleOpenTermsModal = () => {
    setModalTitle(t('Terms of Use'));
    setModalContent(<CombinedTermsOfUse />);
    setTermsViewed(true); // Set the checkbox to checked when the terms are viewed
    setModalOpen(true);
  };

  const emailRef = useRef();
  const passwordRef = useRef();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const companyRef = useRef();

  useEffect(() => {
    if (isProfileFormVisible) {
      // Perform any necessary actions when the profile form becomes visible

    }
  }, [isProfileFormVisible]);
  // Fetch user's region on component mount
  useEffect(() => {
    fetch('https://ipwhois.app/json/')
      .then(response => response.json())
      .then(data => {
        setUserRegion(data.region);  // Adjust according to the API response structure
      });

  }, [userRegion]);


  // const actionCodeSettings = {
  //   // URL you want to redirect back to. The domain (www.yourdomain.com) for this
  //   // URL must be in the authorized domains list in the Firebase Console.
  //   url: 'http://localhost:3000/?emailVerified=true', // The URL to redirect to after the user clicks the verification link in the email
  //   handleCodeInApp: false, // This should be false unless you are handling the code in the app
  // };

  async function handleSignup() {
    setIsLoginOut(false);

    let missingFields = [];
    if (!emailRef.current.value) missingFields.push(t("auth-emailLabel"));
    if (!passwordRef.current.value) missingFields.push(t("auth-passwordLabel"));
    if (!firstNameRef.current.value) missingFields.push(t("auth-firstNameLabel"));
    if (!lastNameRef.current.value) missingFields.push(t("auth-lastNameLabel"));

    if (missingFields.length > 0) {
        let message = t("auth-pleaseEnter") + " " + missingFields.map(field =>
            field.charAt(0).toUpperCase() + field.slice(1)
        ).join(", ");
        alert(message);
        return;
    }

    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const firstName = firstNameRef.current?.value || "";
    const lastName = lastNameRef.current?.value || "";
    const userName = `${firstName} ${lastName}`;
    const companyName = companyRef.current?.value || null;

    try {
        const userCredential = await signup(email, password);

        const actionCodeSettings = {
            url: "https://facadewebapp.com/?emailVerified=true",
            handleCodeInApp: true,
        };
        await sendVerificationEmail(actionCodeSettings);

        const userId = userCredential.user.uid;
        const userDocRef = firebase.firestore().collection("users").doc(userId);

        const userData = {
            role: "user",
            email: email,
            emailConfirmed: false,
            firstName: firstName,
            lastName: lastName,
            userName: userName,
            companyName: companyName,
            planType: "Free",
            lastInteractionDate: Timestamp.now(),
            weeklyInteractionCount: 0,
            assistantInteractionCount: 0,
            lastLogginDate: Timestamp.now(),
            userRegion: userRegion
        };

        await userDocRef.set(userData);

        // **✅ Detect if user was a guest before signing up**
        const wasGuest = sessionStorage.getItem("wasGuest");

        if (wasGuest === "true") {
            handleAnalyticsEvent("guest_to_signup", "Signup", "Guest user signed up");
            sessionStorage.removeItem("wasGuest"); // **✅ Clear guest session flag**
        } else {
            handleAnalyticsEvent("direct_signup", "Signup", "User signed up directly");
        }

        alert(t("auth-emailNeedConfirm"));
        closeSignup(false);
    } catch (signupError) {
        console.error("Signup Error:", signupError);
        if (signupError.code === "auth/weak-password") {
            alert(t("auth-weakPassword"));
        } else if (signupError.code === "auth/email-already-in-use") {
            alert(t("auth-accountExists"));
        } else {
            alert(t("auth-signupError"));
        }
    }
}


  async function handleLogin() {
    setIsLoginOut(false);

    try {
      await login(emailRef.current.value, passwordRef.current.value);

      // Ensure currentUser is defined after login
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('No user is currently logged in.');
      }

      const userDocRef = doc(db, "users", currentUser.uid);

      // Update the last login date
      await updateDoc(userDocRef, { lastLogginDate: Timestamp.now() });

      window.history.pushState({}, document.title, "/");

      closeSignup(false);

    } catch (error) {
      console.error("Login Error: ", error); // Log the error for debugging
      alert(t("auth-invalidCredentials")); // You can modify this to show a more specific error message
    }
  }

  async function handleGoogleSignIn() {
    setIsLoginOut(false);

    try {
      await googleSignIn(); // Perform Google authentication
      const currentUser = auth.currentUser;

      // Define the user document reference
      const userDocRef = doc(db, "users", currentUser.uid);

      // Check if the user document already exists
      const docSnap = await getDoc(userDocRef);

      if (!docSnap.exists()) {
        // User does not exist, create new user data
        const userName = currentUser.displayName || "";
        const email = currentUser.email || "";

        const names = userName.split(' ');
        const firstName = names[0];
        const lastName = names.length > 1 ? names[names.length - 1] : '';

        const userData = {
          role: "user",
          firstName: firstName,
          lastName: lastName,
          userName: userName,
          email: email,
          emailConfirmed: false,
          companyName: "None",
          planType: "Free",
          lastInteractionDate: Timestamp.now(),
          weeklyInteractionCount: 0,
          assistantInteractionCount: 0,
          lastLogginDate: Timestamp.now(),
          userRegion: userRegion
        };

        await setDoc(userDocRef, userData);
      } else {
        // User already exists, you can update specific fields if necessary
        // For example, updating the last interaction date:
        await updateDoc(userDocRef, { lastLogginDate: Timestamp.now() });
      }

      closeSignup(false);
      closeUpload(true);
    } catch (error) {
      if (error.code === "auth/popup-closed-by-user") {
        alert(t("auth-googleSignInCanceled"));
      } else {
        alert(t("auth-googleSignInError"));
      }
    }
  }



  async function handlePasswordRecovery() {
    const email = emailRef.current.value;

    if (!email) {
      alert(t("auth-enterEmail"));
      return;
    }

    try {
      await sendPasswordResetEmail(email); // call the imported function
      alert(t("auth-passwordResetSent"));
      setIsPasswordRecovery(false);
    } catch (error) {
      console.error("Password Reset Error:", error);
      alert(t("auth-passwordResetError"));
    }
  }









  return (
    <Modal
      show={true}
      onHide={() => {
        closeSignup(false);
        document.body.style.overflow = "auto";
      }}
      // top
      dialogClassName="auth-modal"
      contentClassName="auth-modal-content"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title> {isLoginOut ? t("auth-logoutTitle") : (loginSet ? t("auth-loginTitle") : t("auth-signupTitle"))}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentUser && currentUser.uid ? (
          <Button
            variant="secondary"
            onClick={handleLogOut}
            className="logout-button"
          >
            {t("auth-logout")}
          </Button>
        ) : (
          <Form>
            {loginSet && !isPasswordRecovery && (
              <>
                <Form.Group controlId="formEmail">
                  <Form.Label>{t("auth-emailLabel")}</Form.Label>
                  <Form.Control
                    ref={emailRef}
                    type="email"
                    placeholder={t("auth-emailPlaceholder")}
                    autoComplete="email"
                  />
                </Form.Group>
                <Form.Group controlId="formPassword">
                  <Form.Label>{t("auth-passwordLabel")}</Form.Label>
                  <Form.Control
                    ref={passwordRef}
                    type="password"
                    placeholder={t("auth-passwordPlaceholder")}
                    autoComplete="current-password"
                  />
                </Form.Group>
                <Button variant="link" onClick={() => setIsPasswordRecovery(true)}>
                  {t("auth-forgotPassword")}
                </Button>
              </>
            )}
            {!loginSet && (
              <>
                <div className="infoBubble">
                  <h3>{t("auth-freesignup")}</h3>
                </div>
                <Form.Group controlId="formEmail">
                  <Form.Label>{t("auth-emailLabel")}</Form.Label>
                  <Form.Control
                    ref={emailRef}
                    type="email"
                    placeholder={t("auth-emailPlaceholder")}
                    autoComplete="email"
                  />
                </Form.Group>
                <Form.Group controlId="formPassword">
                  <Form.Label>{t("auth-passwordLabel")}</Form.Label>
                  <Form.Control
                    ref={passwordRef}
                    type="password"
                    placeholder={t("auth-passwordPlaceholder")}
                    autoComplete="new-password"
                  />
                </Form.Group>
                {isProfileFormVisible || !loginSet && (
                  <>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>{t("auth-firstNameLabel")}</Form.Label>
                      <Form.Control
                        ref={firstNameRef}
                        type="text"
                        placeholder={t("auth-firstNamePlaceholder")}
                      />
                    </Form.Group>
                    <Form.Group controlId="formLastName">
                      <Form.Label>{t("auth-lastNameLabel")}</Form.Label>
                      <Form.Control
                        ref={lastNameRef}
                        type="text"
                        placeholder={t("auth-lastNamePlaceholder")}
                      />
                    </Form.Group>
                    <Form.Group controlId="formCompanyName">
                      <Form.Label>{t("auth-companyNameLabel")}</Form.Label>
                      <Form.Control
                        ref={companyRef}
                        type="text"
                        placeholder={t("auth-companyNamePlaceholder")}
                      />
                    </Form.Group>
                  </>
                )}
                <ContentModal
                  show={modalOpen}
                  title={modalTitle}
                  content={modalContent}
                  handleAccept={() => {
                    // Accept terms/cookies logic
                    setModalOpen(false);
                  }}
                  handleDecline={() => {
                    // Decline terms/cookies logic
                    setModalOpen(false);
                  }}
                />

              </>

            )}
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!currentUser && loginSet && !isPasswordRecovery && (
          <>
            <Button
              variant="primary"
              disabled={currentUser}
              onClick={handleLogin}
            >
              {t("auth-login")}
            </Button>
            <Button
              variant="primary"
              disabled={currentUser}
              onClick={handleGoogleSignIn}
            >
              {t("auth-googleLogin")}
            </Button>
          </>
        )}
        {!currentUser && !loginSet && (
          // <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          //   <div>
          //     <input
          //       type="checkbox"
          //       checked={termsViewed}
          //       onChange={() => {}} // You need an onChange handler to make this checkbox not readOnly
          //     />
          //     <a href="/terms" onClick={(e) => { e.preventDefault(); handleOpenTermsModal(); }}>
          //       {t('consent.betaTerms')}
          //     </a>
          //   </div>
          <>
            <Button
              variant="primary"
              // disabled={!termsViewed} // Disable the button unless the terms have been viewed
              onClick={handleSignup}
            >
              {t("auth-signup")}
            </Button>

            <Button
              variant="primary"
              disabled={currentUser}
              onClick={handleGoogleSignIn}
            >
              {t("auth-googleSignUp")}
            </Button>

          </>
          // </div>
        )}

        {isPasswordRecovery && (
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Label>{t("auth-emailLabel")}</Form.Label>
              <Form.Control
                ref={emailRef}
                type="email"
                placeholder={t("auth-emailPlaceholder")}
              />
            </Form.Group>
            <Button className="emailToRecover" variant="primary" onClick={handlePasswordRecovery}>
              {t("auth-resetPassword")}
            </Button>
            <Button variant="link" onClick={() => setIsPasswordRecovery(false)}>
              {t("auth-cancel")}
            </Button>
          </Form>
        )}

      </Modal.Footer>
    </Modal>
  );
}

export default Auth;
