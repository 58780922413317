import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Spinner, Modal, Button } from 'react-bootstrap';
import { useAuth, db } from "../../../base";
import { getDoc, updateDoc, doc } from "firebase/firestore";
import DOMPurify from 'dompurify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";
import planLimits from "../../../plans/plansLimits";
import './FacadeDesigner.css';
import { colorList } from '../../colors/colorHelper'; // Import the helper file
import { getColorByName, normalizeToCamelCase, normalizeProposedColors } from '../../colors/colorUtils';
import DetectionButton from '../DetectionButton';

const FacadeDesigner = ({ projectChangeKey, projects, projectId, applyColorsToCanvasItems, handleShowLoader, handleHideLoader, newProjectImage, triggerAIDetectionForOpenedProject, detectAndApplyColors, setIsDetectionTriggered, fabricCanvas, isDetectionTriggered, resetNewProjectImage, userRole, isDesignerModalOpen, setDesignerModalOpen, handleAnalyticsEvent, setRoboflowFoundDetections, setGeminiScaleFactor, backgroundImage, setBackgroundImageUrlInApp, openDesigner, handleDesignerModalOpen }) => {
  const { t, i18n } = useTranslation(["translation"]);
  const currentUser = useAuth();
  const textAreaRef = useRef(null);
  const textareaContainerRef = useRef(null); // Add a ref for the textarea container
  const responseContainerRef = useRef(null);
  const modalBodyRef = useRef(null);

  const [userInput, setUserInput] = useState('');
  const [assistantResponse, setAssistantResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
  const [defaultQuestion, setDefaultQuestion] = useState('');
  const [isFirstInteraction, setIsFirstInteraction] = useState(true);
  const [threadId, setThreadId] = useState(null);
  const [runId, setRunId] = useState(null);
  const [imageFileId, setImageFileId] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [colorButtons, setColorButtons] = useState([]);
  const [proposedColors, setProposedColors] = useState({});
  const [conversationHistory, setConversationHistory] = useState([]); // Add this line
  const proposedColorsRef = useRef(proposedColors);
  const [isProjectSwitching, setIsProjectSwitching] = useState(false); // Track project switching state
  const [currentLanguage, setCurrentLanguage] = useState("en"); // Default to English
  const [errorMessage, setErrorMessage] = useState(""); // Error message state

  // Initialize language on component mount
  useEffect(() => {
    const language = initializeLanguage();
    setCurrentLanguage(language);
  }, []);

  useEffect(() => {
    proposedColorsRef.current = proposedColors;
  }, [proposedColors]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // useEffect(() => {
  //   if (textareaContainerRef.current) {
  //     // Scroll the textarea into view after the assistant response is updated
  //     textareaContainerRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [assistantResponse]); // Update positioning after each assistant response

  useEffect(() => {
    // Scroll to textareaContainerRef when assistant response is updated or loading finishes
    if (textareaContainerRef.current && !isLoading) {
      textareaContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [assistantResponse, isLoading]);

  useEffect(() => {
    const normalizeProposedColors = (colors, lang) => {
      const normalizedColors = {};
      Object.keys(colors).forEach(key => {
        const normalizedKey = normalizeItemKey(key);
        const translatedKey = itemMapping[lang][normalizedKey] || normalizedKey;
        normalizedColors[translatedKey] = colors[key];
      });
      return normalizedColors;
    };

    const normalizedColors = normalizeProposedColors(proposedColors, currentLanguage);
    setProposedColors(normalizedColors);
  }, [currentLanguage]); // Trigger when language changes


  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        if (currentUser.email.includes('guest')) {
          // Retrieve interaction counts from local storage for guest users
          const weeklyInteractionCount = parseInt(localStorage.getItem('guest_weeklyInteractionCount')) || 0;
          const assistantInteractionCount = parseInt(localStorage.getItem('guest_assistantInteractionCount')) || 0;

          // Set user data for guest, including setting the planType as 'Guest'
          setUserData({
            weeklyInteractionCount,
            assistantInteractionCount,
            planType: 'Guest',  // Set planType to 'Guest' for guest users
          });
        } else {
          // Retrieve data from Firestore for regular users
          const userRef = doc(db, "users", currentUser.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            setUserData(userDoc.data());
          }
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  useEffect(() => {
    proposedColorsRef.current = proposedColors;
  }, [proposedColors]);

  useEffect(() => {
    const hints = t('hints', { returnObjects: true });
    if (hints && hints.length > 0) {
      setDefaultQuestion(hints[0]);
    }
  }, [t]);

  useEffect(() => {

    setBackgroundImageUrlInApp(backgroundImageUrl)

    if (projectChangeKey) {


      setIsProjectSwitching(true);

      const project = projects.find((p) => p.id === projectId);

      if (project && project.projectJson) {
        try {
          const projectData = JSON.parse(project.projectJson);

          if (projectData && projectData.backgroundImage && projectData.backgroundImage.src) {

            handleProjectSwitch(projectData.backgroundImage.src);
          } else {
            console.warn("Project JSON does not contain a valid background image.");
          }
        } catch (error) {
          console.error("Error parsing project JSON:", error);
        }
      } else {
        console.warn("No valid project found for ID:", projectId);
      }
    }
  }, [projectChangeKey, projects, projectId, backgroundImageUrl]);


  useEffect(() => {
    if (projectChangeKey && !isProjectSwitching) {
      const project = projects.find((p) => p.id === projectId);

      if (project && project.projectJson) {
        try {
          const projectData = JSON.parse(project.projectJson);

          // Get backgroundImage URL from project JSON
          const imageUrl = projectData.backgroundImage || projectData.canvasImage || null;

          if (imageUrl) {
            if (imageUrl !== backgroundImageUrl) {
              setBackgroundImageUrl(imageUrl);
            }
          } else {
            console.warn("No background image URL found for the project.");
          }
        } catch (error) {
          console.error("Error parsing project JSON:", error);
        }
      }
    }
  }, [projectChangeKey, projects, projectId, isProjectSwitching, backgroundImageUrl]);




  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight;
    }
  }, [assistantResponse]);



  useEffect(() => {
    // Existing logic for processing assistantResponse

  }, [assistantResponse, t, i18n.language]);

  useEffect(() => {
    // console.log("Current Run ID in State:", runId);
  }, [runId]);

  const isMountedRef = useRef(false);

  useEffect(() => {
    if (openDesigner) {
      handleModalOpen();

      // Reset the openDesigner flag after opening the modal
      setTimeout(() => {

        handleDesignerModalOpen(false); // Ensure the parent function handles resetting
      }, 0);
    }
  }, [openDesigner, handleDesignerModalOpen]);

  useEffect(() => {
  
  }, [proposedColors, colorButtons]);
 
  
  const initializeLanguage = () => {
    // Use i18next stored language if available, fallback to browser language
    let language = localStorage.getItem("i18nextLng") ||
      (navigator.language.startsWith("fr") ? "fr" : "en");

    // Ensure i18n is using the correct language
    i18n.changeLanguage(language);

    // Return the language to be used for `currentLanguage` in state
    return language;
  };


  // Define item mapping for English and French
  const itemMapping = {
    fr: {
      "porte": "door",                    // Maps "porte" to "door"
      "fenêtres": "windows",              // Maps "fenêtres" to "windows"
      "fenetre": "windows",               // Maps "fenetre" (singular) to "windows"
      "porte de garage": "garagedoor",    // Maps "porte de garage" to "garagedoor"
      "fenetres": "windows",              // Handles missing accents
    },
    en: {
      "door": "Door",
      "windows": "Windows",
      "garagedoor": "Garage Door"
    }
  };

  const normalizeItemKey = (key) => {
    return key
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '') // Remove all spaces
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, '') // Remove accents
      .replace(/s$/, ''); // Remove trailing "s" if present
  };

  const normalizeText = (text) => {
    return text
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '') // Remove spaces
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, ""); // Remove accents
  };





  // Updated processAssistantResponse function in FacadeDesigner.js
  const processAssistantResponse = (response) => {
    // console.log("current language:", currentLanguage);

    // Updated regex to handle newlines and varying whitespace
    const summaryRegex = currentLanguage === 'fr'
      ? /<h3>Résumé des Couleurs Proposées<\/h3>\s*<p>([\s\S]*?)<\/p>/i
      : /<h3>Summary of Proposed Colors<\/h3>\s*<p>([\s\S]*?)<\/p>/i;

    const match = response.match(summaryRegex);

    if (match && match[1]) {
      const summary = match[1];

      // Split the summary into pairs
      const itemColorPairs = summary.split(/<br\s*\/?>|\n/).map(pair => pair.trim()).filter(Boolean);
      const combinedColors = {};

      itemColorPairs.forEach(pair => {
        const [item, colors] = pair.split(/:\s*(.+)/);
        if (item && colors) {
          const colorList = colors
            .split(currentLanguage === 'fr' ? ' et ' : ' and ')
            .map(color => normalizeToCamelCase(color.trim()));

          const itemKey = normalizeToCamelCase(item);

          // Translate the item based on current language and mapping
          let translatedItem;
          if (itemMapping[currentLanguage]) {
            const normalizedKey = normalizeToCamelCase(itemKey);
            translatedItem = itemMapping[currentLanguage][normalizedKey] || normalizedKey;
          } else {
            translatedItem = itemKey; // Fallback to the original itemKey
          }

          // console.log("Translated Item:", translatedItem);

          combinedColors[translatedItem] = colorList;
        }
      });

      // Update state with normalized and translated colors
      setProposedColors(prevColors => ({ ...prevColors, ...combinedColors }));

      // Create buttons for proposed colors
      const newColorButtons = Object.keys(combinedColors).flatMap(item => {
        const colors = combinedColors[item];
        return colors.length === 1 ? [`${item} ${colors[0]}`] : colors.map(color => `${item} ${color}`);
      });

      setColorButtons(Object.values(combinedColors).every(colors => colors.length === 1) ? ["Show Me"] : newColorButtons);
      // console.log("Combined Colors:", combinedColors);
      // console.log("Color Buttons:", newColorButtons);
    } else {
      console.warn("Regex match failed or no color summary found in assistant response.");
    }
  };




  const itemNameMapping = {
    "porte": "door",
    "portes": "door",
    "fenêtre": "windows",
    "fenêtres": "windows",
    "window": "windows",
    "garage door": "garagedoor", // Standardize "garage door" as "garagedoor" without space
    "garagedoor": "garagedoor"
  };

  // Function to normalize item names based on common variations
  const normalizeItemName = (name) => {
    const formattedName = name.toLowerCase().trim(); // Convert to lowercase and trim whitespace
    return itemNameMapping[formattedName] || formattedName; // Return the mapped name or the original if not mapped
  };
  const uploadImage = async (imageUrl) => {
    try {
      setStatusMessage(t('assistant.statusUploading'));
      const toastId = toast.info(t('assistant.toastUploading'), { autoClose: false });

      const backgroundImage = fabricCanvas.backgroundImage;
      const imageUrlFromCanvas = backgroundImage?.getSrc();
      const chosenImageUrl = imageUrl || imageUrlFromCanvas;

      if (!chosenImageUrl) {
        throw new Error('No valid image URL available for upload.');
      }

      // Upload the image to OpenAI and get the file ID
      const data = await uploadImageToOpenAI(chosenImageUrl);

      if (!data) {
        throw new Error('No file ID returned from OpenAI image upload.');
      }

      toast.update(toastId, { render: t('assistant.toastUploadComplete'), type: toast.TYPE.SUCCESS, autoClose: 5000 });

      // Trigger detections
      const predictions = await triggerRoboflowDetections(chosenImageUrl);
      if (!predictions || predictions.length === 0) {
        console.warn('No valid predictions found or no doors or windows in image.');
        toast.warning(t('assistant.noDetections')); // Notify the user about the lack of detections
      } else {
        setRoboflowFoundDetections(predictions); // Save detections to state if found
      }

      return data; // Return file ID for OpenAI interaction
    } catch (error) {
      console.error('Error in uploadImage:', error.message);
      toast.error(t('assistant.toastError'));
      setStatusMessage(t('assistant.statusError'));
      throw error;
    }
  };

  const handleOnlyDetections = async () => {
    try {
      // Notify the user that detection has started
      setStatusMessage(t("assistant.statusUploading"));
      const toastId = toast.info(t("assistant.toastUploading"), {
        autoClose: false,
      });

      // Retrieve the image URL from the canvas
      const backgroundImage = fabricCanvas.backgroundImage;
      const imageUrlFromCanvas = backgroundImage?.getSrc();



      if (!imageUrlFromCanvas) {
        throw new Error("No valid image URL available for detection.");
      }

      // Trigger Roboflow detection
      const predictions = await triggerRoboflowDetections(imageUrlFromCanvas);

      if (!predictions || predictions.length === 0) {
        console.warn("No valid predictions found.");
        toast.warning(t("assistant.noDetections"));
        return;
      }

      setRoboflowFoundDetections(predictions)

      // console.log("detections found", predictions)
      // Save detections to state
      setRoboflowFoundDetections(predictions);
      toast.success(t("assistant.toastComplete"));

      // Call the App-level detection function
      await triggerAIDetectionForOpenedProject();

      // Notify the user of completion
      setStatusMessage(t("assistant.statusComplete"));
    } catch (error) {
      console.error("Error during detection:", error.message);
      toast.error(t("assistant.toastError"));
      setStatusMessage(t("assistant.statusError"));
    }
  };

  // Helper Function: Preprocess Image for Gemini
  const preprocessImageForGemini = async (imageUrl) => {
    const targetMaxSize = 3072;
    const targetMinSize = 768;

    // Load the image
    const img = await loadImage(imageUrl);

    // Calculate the scaling factor
    const scaleFactor = calculateScalingFactor(img.width, img.height, targetMaxSize, targetMinSize);

    // Resize the image
    const scaledImage = resizeImage(img, scaleFactor);

    return { scaledImage, scaleFactor };
  };

  // Helper Function: Calculate Scaling Factor
  const calculateScalingFactor = (imageWidth, imageHeight, maxSize, minSize) => {
    const widthScale = maxSize / imageWidth;
    const heightScale = maxSize / imageHeight;
    const scale = Math.min(widthScale, heightScale);

    // If the image is smaller than the minimum size, scale it up
    return Math.max(scale, minSize / Math.max(imageWidth, imageHeight));
  };

  // Helper Function: Resize Image
  const resizeImage = (image, scaleFactor) => {
    const canvas = document.createElement("canvas");
    canvas.width = Math.round(image.width * scaleFactor);
    canvas.height = Math.round(image.height * scaleFactor);

    const context = canvas.getContext("2d");
    context.drawImage(image, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL(); // Convert to base64
  };

  // Helper Function: Load Image from URL
  const loadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous"; // Avoid CORS issues
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
      img.src = url;
    });
  };


  // const triggerGeminiDetections = async (imageUrl) => {
  //   try {
  //     const response = await fetch('/.netlify/functions/geminiDetections', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ imageUrl }),
  //     });

  //     if (!response.ok) {
  //       throw new Error(`Gemini API error: ${response.status}`);
  //     }

  //     // Parse the response
  //     const data = await response.json();

  //     if (!data || !data.boundingBoxes) {
  //       console.warn("No bounding boxes found in Gemini response.");
  //       return { boundingBoxes: [], scaleFactor: 1 }; // Default empty response
  //     }


  //     // Return boundingBoxes and scaleFactor
  //     return data;
  //   } catch (error) {
  //     console.error("Error triggering Gemini detections:", error);
  //     return { boundingBoxes: [], scaleFactor: 1 }; // Return defaults on error
  //   }
  // };

  // const triggerVisionDetections = async (imageUrl) => {
  //   try {
  //     const response = await fetch('/.netlify/functions/roboflowDetections', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({ imageUrl }),
  //     });

  //     if (!response.ok) {
  //       throw new Error(`Roboflow API error: ${response.status}`);
  //     }

  //     // Parse the response
  //     const data = await response.json();
  //     console.log("vision response", data)
  //     setGeminiFoundDetections(data.boundingBoxes)

  //     if (!data || !data.boundingBoxes) {
  //       console.warn("No bounding boxes found in Vision response.");
  //       return { boundingBoxes: [], scaleFactor: 1 }; // Default empty response
  //     }

  //     // Return boundingBoxes and scaleFactor
  //     return data;
  //   } catch (error) {
  //     console.error("Error triggering Vision detections:", error);
  //     return { boundingBoxes: [], scaleFactor: 1 }; // Return defaults on error
  //   }
  // };

  async function triggerRoboflowDetections(imageUrl) {

    try {
      const response = await fetch('/.netlify/functions/roboflowDetections', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ imageUrl }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch detections');
      }

      const result = await response.json();

      if (!result.predictions || !Array.isArray(result.predictions)) {
        console.error('Invalid API response format:', result);
        return [];
      }

      // Process predictions
      const processedPredictions = result.predictions
        .filter(prediction => prediction.class === 'door' || prediction.class === 'window')
        .map(prediction => ({
          class: prediction.class,
          confidence: prediction.confidence,
          tlbr: [
            prediction.y - prediction.height / 2,
            prediction.x - prediction.width / 2,
            prediction.y + prediction.height / 2,
            prediction.x + prediction.width / 2,
          ],
          detection_id: prediction.detection_id,
        }));

      return processedPredictions;
    } catch (error) {
      console.error('Error triggering RoboFlow detections:', error);
      return [];
    }
  }








  const uploadImageToOpenAI = async (imageUrl) => {
    try {
      // console.log("Uploading image to OpenAI with URL:", imageUrl);

      const response = await fetch('/.netlify/functions/openAIImageUpload', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ imageUrl }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Extract and validate the file_id
      const fileId = data.file_id;
      if (!fileId) {
        throw new Error('No file_id field found in the OpenAI response.');
      }

      // console.log("File ID extracted from response:", fileId);
      return fileId; // Return only the file_id
    } catch (error) {
      console.error('Error uploading image to OpenAI:', error.message);
      throw error;
    }
  };




  function parseBoundingBoxes(boundingBoxesString) {
    const lines = boundingBoxesString.split("\n").filter(line => line.trim() && !line.startsWith("Here are"));

    return lines.map(line => {
      const parts = line.trim().split(" ");
      const [top, left, bottom, right] = parts.slice(1, 5).map(Number);
      const boxClass = parts[5]; // "window" or "door"

      return {
        class: boxClass,
        tlbr: [top, left, bottom, right],
      };
    });
  }






  const startConversation = async (userInput, fileId) => {
    // console.log("Using runId in Start Conversation:", runId); // Log the runId value here for debugging 

    try {
      setStatusMessage(t('assistant.statusAnalyzing'));
      let toastId;

      // Check and set the appropriate toast message
      if (!toastId) {
        toastId = toast.info(
          statusMessage !== t('assistant.statusAnalyzing')
            ? t('assistant.toastEvaluating')
            : t('assistant.toastAnalyzing'),
          { autoClose: false }
        );
      } else {
        toast.update(toastId, {
          render: statusMessage !== t('assistant.statusAnalyzing')
            ? t('assistant.toastEvaluating')
            : t('assistant.toastAnalyzing'),
          autoClose: false
        });
      }


      const response = await fetch('/.netlify/functions/openAIConversation', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userInput, fileId, assistantId: 'asst_BEjQnYuZGaZvdVy5XCwaZcj5' }),
        // body: JSON.stringify({ userInput, fileId, assistantId: 'asst_oUcoz4KPgfGmzYGGjjREFGJi', threadId}),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setThreadId(data.threadId);
      // setRunId(data.runId);
      toast.update(toastId, { render: t('assistant.toastComplete'), type: toast.TYPE.SUCCESS, autoClose: 3000 });
      handleAnalyticsEvent('DesignerResponse', 'Designer', 'Designer Responded');

      //  console.log("Updated Thread ID and Run ID:", data.threadId, data.runId);

      // Ensure the response is treated as a string
      const responseText = typeof data.message === 'string' ? data.message : JSON.stringify(data.message);

      // Add the new conversation to the history
      setConversationHistory(prev => [{ question: userInput, response: responseText }, ...prev]);

      return responseText;
    } catch (error) {
      console.error('Error starting conversation:', error);
      toast.dismiss();
      toast.error(t('assistant.toastError'));
      setStatusMessage(t('assistant.statusError'));
      setIsLoading(false);
      throw error;
    }
  };

  const handleSubmit = async () => {

  // Reset the error message on submit
  setErrorMessage(""); 

  // Check if the input is empty and show the error message if true
  if (!userInput) {
    setErrorMessage(t("assistant.errorMessage"));
    return;
  }

    setIsLoading(true);

    try {
      const backgroundImage = fabricCanvas.backgroundImage;
      const imageUrlFromCanvas = backgroundImage?.getSrc();
      const chosenImageUrl = isFirstInteraction
        ? backgroundImageUrl || imageUrlFromCanvas
        : imageFileId;

      if (!chosenImageUrl) {
        throw new Error(t('assistant.noImage'));
      }

      const question = userInput.trim() || defaultQuestion;

      let fileIdToUse = imageFileId;

      // Upload image only on the first interaction
      if (isFirstInteraction && !imageFileId) {
        fileIdToUse = await uploadImage(chosenImageUrl);

        if (!fileIdToUse) {
          throw new Error('Failed to upload image and retrieve file ID.');
        }

        setImageFileId(fileIdToUse); // Update state for future interactions
      }


      // Start the conversation with the valid file ID
      const responseMessage = await startConversation(question, fileIdToUse);
      processAssistantResponse(responseMessage);

      setStatusMessage(t('assistant.statusComplete'));
      setIsFirstInteraction(false);
      updateInteractionCounts();
    } catch (error) {
      console.error('Error in handleSubmit:', error.message);
      setAssistantResponse((prev) => `${prev}\n\n${t('assistant.errorAi')}`);
      toast.error(t('assistant.errorNetwork'));
    } finally {
      setIsLoading(false);
      setUserInput('');
    }
  };








  const handleProjectSwitch = (newImageUrl) => {


    if (!newImageUrl || newImageUrl === backgroundImageUrl) {

      return;
    }

    setThreadId(null);
    setRunId(null);
    setImageFileId(null);
    setBackgroundImageUrl(newImageUrl); // Update the background image URL
    setUserInput('');
    setAssistantResponse('');
    setColorButtons([]);
    setProposedColors({});
    setIsFirstInteraction(true);
    setIsDetectionTriggered(false);
    setConversationHistory([]);
    setStatusMessage('');
  };





  const handleHintClick = (hintText) => {
    setUserInput(hintText);
    textAreaRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const startNewConversation = async () => {
    setUserInput('');
    setAssistantResponse('');
    setIsLoading(false);
    // handleProjectSwitch('');
    resetNewProjectImage();
    setConversationHistory([])
    if (currentUser) {
      const userRef = doc(db, "users", currentUser.uid);
      await updateDoc(userRef, { threadId: null, runId: null });
    }
  };

  const updateInteractionCounts = async (threadId, runId) => {

    // console.log("environement", process.env.REACT_APP_ENV )

    if (process.env.REACT_APP_ENV === 'development') {
      // console.log("Skipping interaction count updates in development mode.");
      return;
    }

    if (currentUser && currentUser.email.includes('guest')) {
      // Retrieve interaction counts from local storage
      let weeklyInteractionCount = parseInt(localStorage.getItem('guest_weeklyInteractionCount')) || 0;
      let assistantInteractionCount = parseInt(localStorage.getItem('guest_assistantInteractionCount')) || 0;

      // Increment the counts
      weeklyInteractionCount += 1;
      assistantInteractionCount += 1;

      // Update local storage
      localStorage.setItem('guest_weeklyInteractionCount', weeklyInteractionCount);
      localStorage.setItem('guest_assistantInteractionCount', assistantInteractionCount);

      // console.log("Guest interaction counts updated in local storage:", { weeklyInteractionCount, assistantInteractionCount });

      // Update the local state to trigger a re-render
      setUserData((prevData) => ({
        ...prevData,
        weeklyInteractionCount,
        assistantInteractionCount
      }));

    } else if (currentUser && userRole !== 'mainAdmin' && userRole !== 'admin') {
      const userRef = doc(db, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const weeklyInteractionCount = (userData.weeklyInteractionCount || 0) + 1;
        const assistantInteractionCount = (userData.assistantInteractionCount || 0) + 1;

        // console.log("Updating interaction counts in Firestore:", { weeklyInteractionCount, assistantInteractionCount, threadId, runId });

        const updateData = {
          weeklyInteractionCount,
          assistantInteractionCount,
        };

        if (threadId !== undefined) {
          updateData.threadId = threadId;
        }

        if (runId !== undefined) {
          updateData.runId = runId;
        }

        await updateDoc(userRef, updateData);

        // Update the local state to trigger a re-render
        setUserData((prevData) => ({
          ...prevData,
          weeklyInteractionCount,
          assistantInteractionCount
        }));
      }
    }
  };




  const convertTextUrlsToLinks = (text) => {
    const urlRegex = /www\.[\w\-\.]+(\.com|\.ca)/g;
    return text.replace(urlRegex, (url) => {
      return `<a href="http://${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  const createMarkup = (htmlContent) => {
    const content = typeof htmlContent === 'string' ? htmlContent : JSON.stringify(htmlContent);
    const processedContent = convertTextUrlsToLinks(content);
    return { __html: DOMPurify.sanitize(processedContent) };
  };


  const handleModalOpen = () => {
    setDesignerModalOpen(true);
    handleAnalyticsEvent('click', 'Designer', 'Designer Triggered'); // Log event when modal is opened
  };

  const renderButtons = () => {
    if (!colorButtons.length || !colorButtons[0]) {
      // If colorButtons is empty or the first element is undefined, return null or a fallback button
      return null;
    }

    // Safely access and split colorButtons[0]
    const buttonText = colorButtons[0];
    const [item, colorName] = buttonText.split(' ');

    // Ensure proposedColors[item] is available before accessing it
    const backgroundColor = getColorByName(proposedColors[item?.toLowerCase().replace(' ', '')]?.[0]);

    const handleClick = async () => {
      handleShowLoader();
      try {
        setDesignerModalOpen(false);

        // Normalize keys directly using `normalizeItemKey`
        const normalizedColors = {};
        Object.keys(proposedColorsRef.current).forEach((key) => {
          const normalizedKey = normalizeItemKey(key);
          normalizedColors[normalizedKey] = proposedColorsRef.current[key];
        });

        if (!isDetectionTriggered) {
          await detectAndApplyColors(normalizedColors); // Pass normalized colors
          setIsDetectionTriggered(true); // Mark detection as triggered
        } else {
          await applyColorsToCanvasItems(normalizedColors); // Only apply colors if detection has already been done
        }
      } catch (error) {
        console.error("Error during button click processing:", error);
      }
      handleHideLoader();
    };


    return (
      <div>
        <Button
          variant="outline-primary"
          className="color-button"
          style={{ margin: '5px', backgroundColor }}
          onClick={handleClick}
          title={colorName}
        >
          {isDetectionTriggered ? t('assistant.ApplyColors') : t('assistant.ShowMe')}
        </Button>
        <p>{t('assistant.buttonWarningMessage')}</p>
      </div>
    );
  };





  return (
    <>
      {!isDesignerModalOpen && !isMobile && (
        <div className="floating-button" onClick={handleModalOpen}>
          <img
            src="/rnewFacadeDesignerPro_cutout80.png"
            alt="Facade Designer Icon"
            className="designer-icon"
          />
          <span>{t('assistant.VirtualDesigner')}</span>
        </div>

      )}
      <Modal show={isDesignerModalOpen} onHide={() => setDesignerModalOpen(false)}>
        <Modal.Header closeButton>
          <div className="modal-header-content">
            <Modal.Title>{t("assistant.VirtualDesigner")}</Modal.Title>
            <div className="interaction-counts">
              {`${userData.weeklyInteractionCount || 0} ${t('assistant.outOf')} ${planLimits.designer[userData.planType]?.weeklyInteractions || 'N/A'} ${t('assistant.weekly')}`}
              <br />
              {`${userData.assistantInteractionCount || 0} ${t('assistant.outOf')} ${planLimits.designer[userData.planType]?.totalInteractions || 'N/A'} ${t('assistant.monthly')}`}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body ref={modalBodyRef}>
          <div className='avatarTitle'>
            <h4>{t("assistant.avatarTitle")} </h4>
          </div>
          <div className="avatar-container" style={{ textAlign: 'center', paddingBottom: "16px" }}>
            <img src={"/rnewFacadeDesignerPro_cutout300.png"} alt="Facade Designer Avatar" style={{ width: '200px', margin: '0 auto' }} />
          </div>
          <div className='avatarTitle'>
            <p>{t("assistant.hintExamples")}</p>
          </div>
          {/* <Button variant="primary" onClick={handleOnlyDetections}>
        Trigger Roboflow & AI Detection
      </Button> */}
          <div className="hints-grid">
            {t('hints', { returnObjects: true }).map((hint, index) => (
              <div key={index} className="hint-box" onClick={() => handleHintClick(hint)}>
                {hint}
              </div>
            ))}
          </div>

          {/* Display the assistant response if available */}
          {assistantResponse && (
            <div className="assistant-response">
              <p>{assistantResponse}</p>
            </div>
          )}

          {/* Move Spinner Here */}
          {isLoading && (
            <div style={{ textAlign: 'center', margin: '20px 0' }}>
              <Spinner animation="border" role="status" className="spinner">
                <span className="sr-only">{t('assistant.loading')}</span>
              </Spinner>
            </div>
          )}

          {!isLoading && (
            <>
              <p>{statusMessage}</p>
              <div ref={responseContainerRef}>
                {/* Render the conversation history in reverse order */}
                {conversationHistory.slice().reverse().map((conv, index) => (
                  <div key={index}>
                    <p><strong>{t("assistant.You")}:</strong> {conv.question}</p>
                    <div dangerouslySetInnerHTML={createMarkup(conv.response)} />
                  </div>
                ))}
              </div>

              <div className="color-buttons-container">
                {renderButtons()}
              </div>
              {/* User question section with ref for scrolling */}
              <div ref={textareaContainerRef}>
                <textarea
                  ref={textAreaRef}
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  disabled={isLoading}
                  placeholder={t("assistant.placeHolder")}
                />
                <div className="spinner-container">
                  <Button onClick={handleSubmit} disabled={isLoading}>{t("assistant.Submit")}</Button>
                </div>
                {/* Show the error message if there is one */}
                {errorMessage && <div className="error-message">{errorMessage}</div>}
              </div>
            </>
          )}
        </Modal.Body>

      </Modal>
    </>
  );


};

export default FacadeDesigner;