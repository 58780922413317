import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./EvaluationProgress.css"; // Import CSS

export const EvaluationProgress = ({ 
  isVisible, 
  isEvaluationComplete, 
  hasError, 
  onRetry, 
  onClose, 
  currentStep // ✅ New prop to track the step
}) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const maxTime = 30; // 🔹 Increased from 25 to 30 seconds for smoother updates
  const fastFinishTime = 1000; // Fast close when complete

  const progressSteps = [
    "uploading",
    "detecting",
    "analyzing",
    "applyingColors",
    "alternatives"
  ];

  useEffect(() => {
    if (!isVisible || hasError) return;

    setProgress(0);

    let interval = setInterval(() => {
      setProgress((prev) => (prev < 100 ? prev + 3.5 : 100)); // 🔹 Adjusted increment for 30s duration
    }, (maxTime * 1000) / 30);

    return () => clearInterval(interval);
  }, [isVisible, hasError]);

  useEffect(() => {
    if (isEvaluationComplete && !hasError) {
      setProgress(100);
      setTimeout(() => {
        onClose();
      }, fastFinishTime);
    }
  }, [isEvaluationComplete, hasError]);

  return (
    isVisible && (
      <div className="evaluation-overlay">
        <div className="evaluation-box">
          <div className="ai-avatar">🤖</div>

           <h3>{hasError ? t("evaluation.errorTitle") : t("evaluation.inProgressTitle")}</h3>
          <p>{hasError ? t("evaluation.errorMessage") : t(`evaluation.steps.${currentStep}`)}</p>

          {/* Progress Bar */}
          {!hasError && (
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{
                  width: `${progress}%`,
                  transition: isEvaluationComplete ? "width 1s ease-in-out" : "width 0.4s ease-in-out",
                }}
              ></div>
            </div>
          )}

          {/* Retry & Cancel Buttons if Error */}
          {hasError ? (
            <div className="error-actions">
              <button className="retry-btn" onClick={onRetry}>
                {t("evaluation.retryButton")}
              </button>
              <button className="cancel-btn" onClick={onClose}>
                {t("evaluation.cancelButton")}
              </button>
            </div>
          ) : (
            // Typing animation for AI
            <div className="typing-animation">
              {t("evaluation.typing")}
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default EvaluationProgress;
