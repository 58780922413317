import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useTranslation } from "react-i18next";
import "../accessories/accessoriesSelectionView.css"; // Adjust path as needed

// Import accessory images if not using the public directory
import transomThumbnail from "../../images/thumnails/Window_Fixed_HalfMoon.png";
import shutterThumbnail from "../../images/thumnails/shutter_standard_white.png";
import decorativeThumbnail from "../../images/thumnails/doorKnocker1_silver.png";

function AccessoriesSelectionView(props) {
  const { t } = useTranslation(["translation"]);

  function onAccessoryTypeSelect(accessoryType) {
    console.log(`Selected Accessory: ${accessoryType}`);
    props.setAccessoryType(accessoryType);
    props.setIsAccessoryTypeSelected(true);
    props.setIsAccessoriesPanelVisible(false);
    props.setIsPanelVisible(false)
  }

  return (
    <>
      <div className="closeBtn">
        <button className="btn btn-primary" onClick={() => props.setIsAccessoriesPanelVisible(false)}>
          {t("accessories-close")}
        </button>
      </div>
      <div className="selectionTitle">{t("accessories-availableAccessories")}</div>
      <div className="list bg-transparent">
        <ListGroup variant="flush">
          <ListGroup.Item disabled action onClick={() => onAccessoryTypeSelect('Transoms')} className="bg-transparent d-flex flex-column align-items-center">
            <div className="itemTypeTitle">{t("accessories-transoms")}</div>
            <img
              className='addItemsIcons'
              src={transomThumbnail}
              height={60}
              alt={t("accessories-transoms")}
            />
          </ListGroup.Item>
          <ListGroup.Item action onClick={() => onAccessoryTypeSelect('Shutters')} className="bg-transparent bg-transparent d-flex flex-column align-items-center">
            <div className="itemTypeTitle">{t("accessories-shutters")}</div>
            <img
              className='addItemsIcons'
              src={shutterThumbnail}
              height={60}
              alt={t("accessories-shutters")}
            />
          </ListGroup.Item>
          <ListGroup.Item action onClick={() => onAccessoryTypeSelect('Decorative')} className="bg-transparent d-flex flex-column align-items-center">
            <div className="itemTypeTitle">{t("accessories-trims")}</div>
            <img
              className='addItemsIcons'
              src={decorativeThumbnail}
              height={60}
              alt={t("accessories-trims")}
            />
          </ListGroup.Item>
        </ListGroup>
      </div>
    </>
  );
}

export default AccessoriesSelectionView;
