import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./DesignVariationCarousel.css";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { FaPalette } from "react-icons/fa";
import { useFindCarouselColor } from "../colors/useFindCarouselColor";

const DesignVariationCarousel = ({ 
    canvas, 
    applyColorToSpecificItems, 
    customProps, 
    recommendedColors = [], 
    mainColor, // ✅ Main color state from AI
    updateProgress
}) => {
    const { t, i18n } = useTranslation();
    const findCarouselColor = useFindCarouselColor();

    const [thumbnails, setThumbnails] = useState([]);
    const [selectedVariation, setSelectedVariation] = useState(0);
    const [showDrawer, setShowDrawer] = useState(false);
    const [originalCanvasState, setOriginalCanvasState] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasAppliedMainColor, setHasAppliedMainColor] = useState(false);

    useEffect(() => {
        if (canvas) {
            watchCanvasChanges();
        }
    }, [canvas]);

    const watchCanvasChanges = () => {
        canvas.on("object:modified", () => {
            console.log("🟡 Canvas modification detected! Rebuilding thumbnails...");
            rebuildThumbnails();
        });
    };

    // ✅ Apply `mainColor` FIRST before generating variations
    useEffect(() => {
        console.log("🟢 useEffect triggered - Checking conditions...");
        console.log("mainColor:", mainColor);
        console.log("canvas:", canvas);
        console.log("hasAppliedMainColor:", hasAppliedMainColor);
    
        if (mainColor && canvas && !hasAppliedMainColor) {
            console.log("✅ Applying AI main colors...");
    
            const { color: doorColor } = findCarouselColor(mainColor.doors);
            const { color: windowColor } = findCarouselColor(mainColor.windows);
    
            if (doorColor && windowColor) {


                applyColorToSpecificItems("doors", doorColor);
                applyColorToSpecificItems("window", windowColor);
    
                // 🔹 Force canvas to update immediately
                canvas.requestRenderAll();
    
                console.log("🎨 Main AI colors applied to canvas.");
                setOriginalCanvasState(canvas.toJSON([...customProps]));
                setHasAppliedMainColor(true);
    
                // 🔹 Ensure thumbnails are built AFTER canvas is fully updated
                setTimeout(() => {
                    rebuildThumbnails();
                }, 100);
            }
        }
    }, [mainColor, canvas, i18n.language]); 
    

    // ✅ Regenerate thumbnails when recommended colors update
    useEffect(() => {
        if (hasAppliedMainColor && recommendedColors.length > 0) {
            console.log("🎨 Detected AI color updates, rebuilding variations...");
            rebuildThumbnails();
        }
    }, [recommendedColors]);

    const rebuildThumbnails = () => {
        if (!canvas || !mainColor) return;
    
        setLoading(true);
        let variations = [];

        updateProgress("applyingColors")
    
        // ✅ First Thumbnail: Capture Main AI Colors
        const { color: mainDoorColor } = findCarouselColor(mainColor.doors);
        const { color: mainWindowColor } = findCarouselColor(mainColor.windows);
    
        applyColorToSpecificItems("doors", mainDoorColor);
        applyColorToSpecificItems("window", mainWindowColor);
        
        // 🔹 Force render update BEFORE capturing thumbnail
        canvas.requestRenderAll();

        updateProgress("alternatives")
    
        variations.push({
            thumbnail: canvas.toDataURL({ format: "jpeg", quality: 0.5 }),
            titleKey: t("originalDesign"),
            isOriginal: true
        });
    
        console.log("🎨 First thumbnail captured with AI base colors.");
    
        // ✅ Generate Predefined Variations
        const predefinedVariations = [
            { door: "rgba(0, 0, 0, 1)", window: "rgba(0, 0, 0, 1)", label: t("black") },
            { door: "rgba(255, 255, 255, 1)", window: "rgba(255, 255, 255, 1)", label: t("white") },
            { door: "rgba(0, 0, 0, 1)", window: "rgba(255, 255, 255, 1)", label: `${t("black")} / ${t("white")}` }
        ];
    
        for (let variation of predefinedVariations) {
            applyColorToSpecificItems("doors", variation.door);
            applyColorToSpecificItems("window", variation.window);
            canvas.requestRenderAll();
    
            variations.push({
                thumbnail: canvas.toDataURL({ format: "jpeg", quality: 0.5 }),
                titleKey: variation.label.charAt(0).toUpperCase() + variation.label.slice(1),
                doorColor: variation.door,
                windowColor: variation.window
            });
        }
    
        // ✅ AI-Recommended Additional Color Sets
        recommendedColors.forEach(({ doors, windows }) => {
            const { color: doorColor, name: doorName } = findCarouselColor(doors);
            const { color: windowColor, name: windowName } = findCarouselColor(windows);
    
            if (doorColor && windowColor) {
                applyColorToSpecificItems("doors", doorColor);
                applyColorToSpecificItems("window", windowColor);
                canvas.requestRenderAll();
    
                variations.push({
                    thumbnail: canvas.toDataURL({ format: "jpeg", quality: 0.5 }),
                    titleKey: `${doorName} / ${windowName}`,
                    doorColor,
                    windowColor
                });
            }
        });
    
        // 🔹 Restore Main AI Colors After Thumbnail Generation
        setTimeout(() => {
            applyColorToSpecificItems("doors", mainDoorColor);
            applyColorToSpecificItems("window", mainWindowColor);
            canvas.requestRenderAll();
            console.log("🎨 Restored canvas to main AI colors after variations were generated.");
        }, 200); // Small delay ensures all thumbnails are created first
    
        setThumbnails(variations);
        setShowDrawer(true); // ✅ Ensure the carousel opens
        setLoading(false);
    };
    
    
    const applyVariation = (index) => {
        if (!thumbnails || thumbnails.length === 0) return;

        setSelectedVariation(index);
        const variation = thumbnails[index];

        if (variation.isOriginal && originalCanvasState) {
            const confirmRevert = window.confirm(t("confirmRevert", "This will revert all changes to the original design. Continue?"));
            if (confirmRevert) {
                canvas.loadFromJSON(originalCanvasState, () => {
                    canvas.renderAll();
                });
            }
        } else if (variation.doorColor && variation.windowColor) {
            applyColorToSpecificItems("doors", variation.doorColor);
            applyColorToSpecificItems("window", variation.windowColor);
        }
    };

    return (
        <>
            {/* <Tippy content={t("generateVariations")} placement="bottom">
                <button className="generate-btn" onClick={rebuildThumbnails} disabled={loading}>
                    {loading ? t("loading", "Generating...") : <FaPalette size={20} />}
                </button>
            </Tippy> */}

            {/* Toggle Button to Hide/Show Panel */}
            <button 
                className={`toggle-drawer-btn ${showDrawer ? "open" : "closed"}`} 
                onClick={() => setShowDrawer(!showDrawer)}
            >
                {showDrawer ? "←" : "→"}
            </button>

            {/* Drawer - Variations Sidebar */}
            <div className={`variation-drawer ${showDrawer ? "open" : "closed"}`}>
                <div className="drawer-header">
                    <h4>{t("variations")}</h4>
                    {/* <button className="close-btn" onClick={() => setShowDrawer(false)}>✕</button> */}
                </div>

                <div className="variation-list">
                    {thumbnails.map((variation, index) => (
                        <div key={index} className="variation-item">
                            <p className="variation-title">{variation.titleKey}</p>
                            <img
                                src={variation.thumbnail}
                                alt={`Variation ${index}`}
                                className={`variation-thumbnail ${selectedVariation === index ? "selected" : ""}`}
                                onClick={() => applyVariation(index)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default DesignVariationCarousel;
