import { db } from "../../../base";
import { doc, updateDoc } from "firebase/firestore";
import i18n from "i18next";

/**
 * Fetches fileId from the project, sends it to OpenAI assistant for evaluation,
 * and stores the response in Firestore under the evaluation array.
 * 
 * @param {string} projectId - The ID of the project to evaluate.
 */
export const evaluateProjectImage = async (projectId, fileId, updateColorsState, updateMainColor) => {
    const userLanguage = i18n.language || navigator.language.split("-")[0];

    try {
        if (!fileId) {
            console.error("❌ No fileId provided for evaluation.");
            throw new Error("No fileId provided.");
        }

        const response = await fetch('/.netlify/functions/openAIProjectEvaluation', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                fileId,
                assistantId: "asst_jtJNwK7DTb1mr0mNFIstfuGp",
                language: userLanguage,
                requestAdditionalColors: true 
            }),
        });

        if (!response.ok) {
            throw new Error(`OpenAI function failed with status ${response.status}`);
        }

        const responseData = await response.json();
        
        // ✅ Extract AI evaluation structure
        const evaluation = responseData.evaluation || {};
        console.log("🔍 Extracted Full AI Response:", evaluation);

        if (!evaluation) {
            console.warn("⚠️ AI did not return a valid evaluation. Skipping Firebase update.");
            return;
        }

        const colorRecommendations = evaluation.color_recommendations || null;
        const additionalColorSets = evaluation.additional_color_sets || [];

        if (!colorRecommendations) {
            console.warn("⚠️ AI did not return `color_recommendations`. Using default colors.");
        }

        if (!additionalColorSets || additionalColorSets.length === 0) {
            console.warn("⚠️ AI did not return `additional_color_sets`. Using fallback colors.");
        }

        const fallbackColors = [
            { windows: "pebble", doors: "saddle Brown" },
            { windows: "sandstone", doors: "espresso" },
            { windows: "midnight Surf", doors: "iron Ore" },
            { windows: "classicRed", doors: "anthracite" }
        ];

        // ✅ Use AI colors, fallback only if needed
        const colorSets = additionalColorSets.length >= 4 ? additionalColorSets : fallbackColors; 

        // ✅ Ensure UI updates before Firestore
        if (updateMainColor && colorRecommendations) {
            console.log("🟢 Updating **Main Colors** state:", colorRecommendations);
            updateMainColor(colorRecommendations);
        }

        if (updateColorsState) {
            console.log("🎨 Updating **Variations Colors** state.");
            updateColorsState(colorSets);
        }

        // ✅ Update Firestore with the **full AI response** matching the structure from your image
        const projectRef = doc(db, "Projects", projectId);
        await updateDoc(projectRef, {
            evaluation: evaluation, // ✅ Store full AI response
        });

        console.log("✅ Full AI evaluation stored successfully for project:", projectId);

    } catch (error) {
        console.error("❌ Error evaluating project image:", error);
        throw error;
    }
};
