import { fabric } from "fabric-with-erasing";
import React, { useState, useEffect } from "react";
import { saveCanvasState } from "../../helpers/helpers";
import { useTranslation } from "react-i18next";
import "../zoomAndPan/zoomAndPan.css";
import { Modal, Button } from "react-bootstrap";

function ZoomAndPan(props) {
  const { t } = useTranslation();

  const [movementX, setMovementX] = useState(0);
  const [movementY, setMovementY] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);

  const applyCenteredZoom = (canvas, newZoom) => {
    const viewport = canvas.viewportTransform;
    const zoomPoint = new fabric.Point(canvas.width / 2, canvas.height / 2);

    viewport[0] = viewport[3] = newZoom;
    viewport[4] = zoomPoint.x - (zoomPoint.x - viewport[4]) * (newZoom / viewport[0]);
    viewport[5] = zoomPoint.y - (zoomPoint.y - viewport[5]) * (newZoom / viewport[3]);

    canvas.setViewportTransform(viewport);
    canvas.renderAll();
  };

  const handleSliderChangeX = (event) => {
    const newX = parseInt(event.target.value);
    props.canvas.relativePan(new fabric.Point(newX - movementX, 0));
    setMovementX(newX);
    saveCanvasState(props.canvas);
  };

  const handleSliderChangeY = (event) => {
    const newY = parseInt(event.target.value);
    props.canvas.relativePan(new fabric.Point(0, newY - movementY));
    setMovementY(newY);
    saveCanvasState(props.canvas);
  };

  const handleSliderChangeZoom = (event) => {
    const newZoomLevel = parseFloat(event.target.value);
    applyCenteredZoom(props.canvas, newZoomLevel);
    setZoomLevel(newZoomLevel);
    saveCanvasState(props.canvas);
  };

  const handleReset = () => {
    setMovementX(0);
    setMovementY(0);
    setZoomLevel(1);
    props.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
    saveCanvasState(props.canvas);
  };

  return (
    <Modal backdrop={false} show={props.show} onHide={props.onHide} dialogClassName="zoom-pan-modal">
      <Modal.Header closeButton>
        <Modal.Title className="small-title">{t("app-zoomandpan")}</Modal.Title>
      </Modal.Header>

      <Modal.Body className="slider-container">
        {/* Left-Right Slider */}
        <div className="slider-item">
          <input
            className="movement-x-slider"
            type="range"
            min="-100"
            max="100"
            value={movementX}
            onChange={handleSliderChangeX}
          />
          <label className="slider-label-x">{t("zoomAndPan-l-r")}</label>
        </div>

        {/* Up-Down Slider */}
        <div className="slider-item">
          <input
            className="movement-y-slider"
            type="range"
            min="-100"
            max="100"
            value={movementY}
            onChange={handleSliderChangeY}
          />
          <label className="slider-label">{t("zoomAndPan-u-d")}</label>
        </div>

        {/* Zoom Slider */}
        <div className="slider-item">
          <input
            className="zoom-slider"
            type="range"
            min="0.1"
            max="3"
            step="0.1"
            value={zoomLevel}
            onChange={handleSliderChangeZoom}
          />
          <label className="slider-label">Zoom</label>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Close
        </Button>
        <Button className="reset-button" onClick={handleReset}>
          Reset
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ZoomAndPan;
