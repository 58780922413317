
import i18n from 'i18next';



// Function to show a loading toast with a custom message
function showLoadingToast(message, isEvaluating) {
    // Removed the use of toast, as we no longer need it
    if (!isEvaluating) {
        console.log(message); // Display message in console instead of toast
    }
}

// Function to update the toast message and close it
function updateToast(toastId, message, type, isEvaluating) {
    // Removed the use of toast.update, log message instead
    if (!isEvaluating) {
        console.log(message); // Display updated message in console
    }
}

// Global constants for real door size and the default pixel size of a door image
const realDoorSize = { width: 36, height: 82.5 }; // in inches
// scaleFactor.width = 573 / 36;
// const doorPixelSize = { width: 573, height: 1268 }; // in pixels

//Function to calculate the project-specific scale factor based on detected door size
function calculateProjectScaleFactor(doorDetection) {
    const detectedDoorWidth = doorDetection.tlbr[3] - doorDetection.tlbr[1];
    const detectedDoorHeight = doorDetection.tlbr[2] - doorDetection.tlbr[0];

    // This scale factor tells us how many pixels represent an inch
    return {
        width: detectedDoorWidth / realDoorSize.width,
        height: detectedDoorHeight / realDoorSize.height,
    };
}


// Criteria for determining window and door types based on their real-world dimensions
const typeCriteria = [
    {
        class: "window",
        tName: "Casement_Single",
        preferredWidthRange: { min: 16, max: 40 }, // Adjusted upper limit to 38 inches
        possibleWidthRange: { min: 12, max: 36 }, // Still allows slightly smaller windows
        aspectRatioMin: 0.2, // Adjusted to allow square shapes
        aspectRatioMax: 1.2, // Adjusted to allow square shapes
    },
    {
        class: "window",
        tName: "Casement_Double",
        preferredWidthRange: { min: 40, max: 48 }, // Keeping the original range for Casement_Double
        possibleWidthRange: { min: 30, max: 52 },
        aspectRatioMin: 0.2,
        aspectRatioMax: 1.2,
    },
    {
        class: "window",
        tName: "Casement_Triple",
        preferredWidthRange: { min: 48, max: 96 }, // Example values
        possibleWidthRange: { min: 36, max: 102 }, // Example values
        aspectRatioMin: .66, // Adjust based on expected shapes
        aspectRatioMax: 1.66, // Adjust based on expected shapes
    },
    {
        class: "window",
        tName: "Casement_Quad",
        preferredWidthRange: { min: 64, max: 120 }, // Example values
        possibleWidthRange: { min: 60, max: 130 }, // Example values
        aspectRatioMin: .87, // Adjust based on expected shapes
        aspectRatioMax: 2.2, // Adjust based on expected shapes
    },

    {
        class: "door",
        tName: "Single_Door_Embossed1AGlassAt1NoSidelight",
        preferredWidthRange: { min: 30, max: 40 }, // Example values
        possibleWidthRange: { min: 28, max: 42 }, // Example values
        aspectRatioMin: 0.2, aspectRatioMax: 0.52,
    },
    {
        class: "door",
        tName: "Single_Door_Left_SideLight_Embossed1AGlassAt1Sidelight1A",
        preferredWidthRange: { min: 42, max: 60 }, // Example values
        possibleWidthRange: { min: 42, max: 64 }, // Example values
        aspectRatioMin: 0.52, aspectRatioMax: .74,
    },
    {
        class: "door",
        tName: "Single_Door_Right_Left_SideLight_Embossed1AGlassAt1Sidelight1A",
        preferredWidthRange: { min: 60, max: 68 }, // Example values
        possibleWidthRange: { min: 58, max: 70 }, // Example values
        aspectRatioMin: 0.73, aspectRatioMax: 0.83,
    },
    {
        class: "door",
        tName: "Double_Door_Embossed1AGlassAt1NoSidelight",
        preferredWidthRange: { min: 69, max: 72 }, // Example values
        possibleWidthRange: { min: 64, max: 72 }, // Example values
        aspectRatioMin: 0.84, aspectRatioMax: 0.87,
    },
];

function determineTNameFromDetectionWithAspectRatio(detection) {
    const width = detection.tlbr[3] - detection.tlbr[1];
    const height = detection.tlbr[2] - detection.tlbr[0];
    const aspectRatio = width / height;

    // console.log(detection)

    const matchingCriteria = typeCriteria.find(criteria =>
        detection.class === criteria.class &&
        aspectRatio >= criteria.aspectRatioMin &&
        aspectRatio <= criteria.aspectRatioMax);

    if (!matchingCriteria) {
        // console.log(`No matching type found for detection with aspect ratio: ${aspectRatio} and class: ${detection.class}`);
        return null;
    }

    return matchingCriteria.tName;
}


function determineTNameFromDetectionWithScaleFactor(detection, projectScaleFactor) {
    // Convert detection sizes from pixels to inches using the projectScaleFactor
    const widthInPixels = detection.tlbr[3] - detection.tlbr[1];
    const heightInPixels = detection.tlbr[2] - detection.tlbr[0];
    const realWidth = widthInPixels / projectScaleFactor.width;
    const realHeight = heightInPixels / projectScaleFactor.height;
    const aspectRatio = realWidth / realHeight;

    console.log("real width, real height", realWidth, realHeight, detection)

    // // Relaxed check for aspect ratio when width is between 34 and 38 inches
    // if (realWidth <= 40  && ) {
    //     // Relax the aspect ratio range for windows with width <= 38 inches
    //     if (aspectRatio >= 0.2 && aspectRatio <= 2.2) {
    //         return "Casement_Single";  // Force Casement_Single for windows <= 38 inches with relaxed aspect ratio
    //     }
    //     return "Casement_Single"; // Default to Casement_Single if it fits width condition
    // }

    // Find a matching criterion based on real-world dimensions and aspect ratios
    const matchingCriteria = typeCriteria.find(criteria => {
        if (detection.class !== criteria.class) return false;

        // First, check if the real-world width fits within any specified ranges for this type
        const fitsPreferredWidthRange = realWidth >= criteria.preferredWidthRange.min && realWidth <= criteria.preferredWidthRange.max;
        const fitsPossibleWidthRange = realWidth >= criteria.possibleWidthRange.min && realWidth <= criteria.possibleWidthRange.max;

        // If aspect ratio is considered, check that too
        const fitsAspectRatio = aspectRatio >= criteria.aspectRatioMin && aspectRatio <= criteria.aspectRatioMax;

        return (fitsPreferredWidthRange || fitsPossibleWidthRange) && fitsAspectRatio;

    });

    if (!matchingCriteria) {
        // console.log(`No matching type found for detection with aspect ratio: ${aspectRatio}, width: ${realWidth} inches, and class: ${detection.class}`);
        return null;
    }

    return matchingCriteria.tName;
}

function determineDoorOptionsFromAI(typeName) {
    let doorOptionsName;

    if (typeName.startsWith("Double_Door_Embossed")) {
        // Handles Double Door types
        doorOptionsName = "Double_Door_AI";
    } else if (typeName.includes("SideLight") && !typeName.includes("Right_Left_SideLight")) {
        // Handles Single Doors with SideLight (either right or left)
        doorOptionsName = "Single_Door_Left_SideLight_AI";
    } else if (typeName.includes("Right_Left_SideLight")) {
        // Handles standard Single Doors without 2 sidelights
        doorOptionsName = "Single_Door_Right_Left_SideLight_AI";
    } else if (typeName.startsWith("Single_Door_Embossed" && !typeName.includes("SideLight"))) {
        // Handles standard Single Doors without sidelights
        doorOptionsName = "Single_Door_AI";
    } else {
        // Default case to use the original typeName if no specific mapping applies
        doorOptionsName = typeName;
    }
    return doorOptionsName;
}

// Refactor the path construction logic into its own function
function constructPathForDetection(tName, detectionClass) {
    let localPath;
    if (detectionClass === "window") {
        localPath = `/windows/casement/Window_${tName}.png`;
    } else {
        if (tName.startsWith("Double_Door_")) {
            localPath = `/doors/double/${tName}.png`;
        } else if (tName.includes("SideLight") && !tName.includes("Right_Left")) {
            localPath = `/doors/singleLSidelight/${tName}.png`;
        } else if (tName.includes("Right_Left_SideLight")) {
            localPath = `/doors/singleRLSidelight/${tName}.png`;
        } else {
            localPath = `/doors/single/${tName}.png`;
        }
    }
    return localPath;
}

export async function triggerDetection(
    addItem, setTypeName, setWindowURL, fabricCanvas, roboflowFoundDetections,
    projectScaleFactor, setProjectScaleFactor, isEvaluating
) {
    // Removed toast calls and replaced with log
    console.log(i18n.t("startingDetection", roboflowFoundDetections));

    try {
        if (!roboflowFoundDetections || roboflowFoundDetections.length === 0) {
            console.error("No detections found.");
            return;
        }

        const filteredDetections = filterDetections(roboflowFoundDetections);

        let realScaleFactor;
        const doorDetection = filteredDetections.find(d => d.class === "door");
        let localScaleFactor = null;
        if (doorDetection) {
            const scaleX = fabricCanvas.backgroundImage ? fabricCanvas.backgroundImage.scaleX : 1;
            const heightInPixels = doorDetection.tlbr[2] - doorDetection.tlbr[0];
            realScaleFactor = heightInPixels / 82 * scaleX;
            localScaleFactor = calculateProjectScaleFactor(doorDetection);
            setProjectScaleFactor(localScaleFactor);
        }

        filteredDetections.forEach(detection => {
            let tName;
            if (localScaleFactor && detection.class !== "door") {
                tName = determineTNameFromDetectionWithScaleFactor(detection, localScaleFactor);
            } else {
                tName = determineTNameFromDetectionWithAspectRatio(detection);
            }

            if (!tName) return;

            const doorOptionsName = determineDoorOptionsFromAI(tName);
            const adjustedCoords = adjustDetectionCoordinates(detection, fabricCanvas);
            let localPath = constructPathForDetection(tName, detection.class);

            setTypeName(tName);
            setWindowURL(localPath);
            addItem(localPath, doorOptionsName, adjustedCoords, true, localScaleFactor, realScaleFactor);
        });

        console.log(i18n.t("detectionEnded"));
    } catch (error) {
        console.error("Error in AI detection:", error);
    }
}


// Helper: Calculate IoU (Intersection over Union)
const calculateIoU = (boxA, boxB) => {
    const [topA, leftA, bottomA, rightA] = boxA;
    const [topB, leftB, bottomB, rightB] = boxB;

    const intersectTop = Math.max(topA, topB);
    const intersectLeft = Math.max(leftA, leftB);
    const intersectBottom = Math.min(bottomA, bottomB);
    const intersectRight = Math.min(rightA, rightB);

    const intersectWidth = Math.max(0, intersectRight - intersectLeft);
    const intersectHeight = Math.max(0, intersectBottom - intersectTop);
    const intersectArea = intersectWidth * intersectHeight;

    const areaA = (bottomA - topA) * (rightA - leftA);
    const areaB = (bottomB - topB) * (rightB - leftB);

    const unionArea = areaA + areaB - intersectArea;

    return unionArea > 0 ? intersectArea / unionArea : 0;
};

// Helper: Filter out "window" when overlapping with "door"
const filterDetections = (detections) => {
    const filteredDetections = [];
    const processedIndexes = new Set();

    for (let i = 0; i < detections.length; i++) {
        if (processedIndexes.has(i)) continue;

        const detectionA = detections[i];

        for (let j = i + 1; j < detections.length; j++) {
            if (processedIndexes.has(j)) continue;

            const detectionB = detections[j];
            const iou = calculateIoU(detectionA.tlbr, detectionB.tlbr);

            if (iou > 0.5) { // Adjust IoU threshold as needed
                if (detectionA.class === "door" && detectionB.class === "window") {
                    processedIndexes.add(j); // Ignore the "window"
                } else if (detectionA.class === "window" && detectionB.class === "door") {
                    processedIndexes.add(i); // Ignore the "window"
                }
            }
        }

        if (!processedIndexes.has(i)) {
            filteredDetections.push(detectionA);
        }
    }

    return filteredDetections;
};




// Function to fetch AI detection results from your Netlify function
// async function fetchNetlifyFunctionForAIDetection(imageUrl) {
//     const response = await fetch('/.netlify/functions/aiDetectionsServer', {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ imageUrl }),
//     });
//     if (!response.ok) {
//         throw new Error('AI detection failed');
//     }
//     return await response.json();
// }


function adjustDetectionCoordinates(detection, fabricCanvas) {
    if (!detection.tlbr || detection.tlbr.length < 4) {
        console.error("Invalid detection.tlbr:", detection.tlbr);
        return null;
    }

    let scaleX = fabricCanvas.backgroundImage.scaleX;
    let scaleY = fabricCanvas.backgroundImage.scaleY;
    let offsetX = 0; // Default to no offset
    let offsetY = 0; // No Y-offset because the image is always aligned to the top

    const canvasWidth = fabricCanvas.width;  // 1200
    const canvasHeight = fabricCanvas.height; // 1200
    const bgImgWidth = fabricCanvas.backgroundImage.width * scaleX;
    const bgImgHeight = fabricCanvas.backgroundImage.height * scaleY;

    if (bgImgWidth < canvasWidth) {
        // Image is **taller** than it is wide (empty space on the sides)
        offsetX = (canvasWidth - bgImgWidth) / 2; // Center horizontally
    }

    // No vertical offset needed, because the image is anchored to the top

    // Adjust coordinates based on scaleX, scaleY, and offsets
    const adjustedCoords = {
        left: (detection.tlbr[1] * scaleX) + offsetX, // Adjust X
        top: detection.tlbr[0] * scaleY, // Y stays the same
        width: (detection.tlbr[3] - detection.tlbr[1]) * scaleX,
        height: (detection.tlbr[2] - detection.tlbr[0]) * scaleY,
        scaleX,
        scaleY,
        offsetX,
        offsetY // Always 0 since the image is aligned at the top
    };

    return adjustedCoords;
}











