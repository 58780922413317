import React, { useState, useEffect, useRef } from "react";
import { Rating } from "@mui/material";
import "./DetectionFeedback.css";

const DetectionFeedback = ({ 
  canvas, 
  projectImageUrl, 
  onSubmit, 
  onClose, 
  t 
}) => {
  const [rating, setRating] = useState(0);
  const [notes, setNotes] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedbackId, setFeedbackId] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false); // Default to collapsed
  const feedbackSubmitted = useRef(false);
  const isMobile = window.innerWidth <= 768; // Mobile detection

  useEffect(() => {
    const submitInitialFeedback = async () => {
      if (!canvas || feedbackSubmitted.current) return;

      feedbackSubmitted.current = true;

      const canvasDataUrl = canvas.toDataURL({
        format: "webp",
        quality: 0.8,
      });

      const initialFeedback = {
        rating: 0,
        notes: "",
        projectImageUrl,
        detectionImage: canvasDataUrl,
      };

      try {
        const feedbackId = await onSubmit(initialFeedback);
        setFeedbackId(feedbackId);
      } catch (error) {
        console.error("Error submitting initial feedback:", error);
      }
    };

    submitInitialFeedback();
  }, [canvas, projectImageUrl, onSubmit]);

  const handleSubmit = async () => {
    if (!feedbackId) return;

    setIsSubmitting(true);
    try {
      const updatedFeedback = {
        rating: rating || 0,
        notes: notes || "",
      };

      await onSubmit(updatedFeedback, feedbackId);
      // alert(t("feedback.updatedSuccess"));
    } catch (error) {
      console.error("Error updating feedback:", error);
      alert(t("feedback.updatedError"));
    } finally {
      setIsSubmitting(false);
      setIsExpanded(false); // Auto-close after submission
      if (onClose) onClose();
    }
  };

  const handleClose = () => {
    setIsExpanded(false);  // Collapse the feedback content
    if (onClose) onClose(); // Call onClose if passed in
  };

  return (
    <div className={`detection-feedback ${isExpanded ? 'expanded' : ''} ${isMobile ? "mobile-drawer" : ""}`}>
      <div className="feedback-toggle" onClick={() => setIsExpanded(true)}>
        ⭐ {t("feedback.rateDetection")}
      </div>

      {isExpanded && (
        <div className="feedback-content">
          <div className="feedback-header">
            {t("detectionAdvice")}
            <button className="close-button" onClick={handleClose}>×</button>
          </div>

          <div className="rating-section">
            <h3>{t("feedback.rateDetection")}</h3>
            <Rating
              name="detection-rating"
              value={rating}
              onChange={(event, newValue) => setRating(newValue)}
            />
          </div>

          <div className="notes-section">
            <h3>{t("feedback.notes")}</h3>
            <textarea
              rows="2"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder={t("feedback.notesPlaceholder")}
              style={{ resize: "vertical" }}
            />
          </div>

          <div className="submit-section">
            <button 
              className="submit-feedback-button" 
              onClick={handleSubmit} 
              disabled={isSubmitting}
            >
              {isSubmitting ? t("feedback.submitting") : t("feedback.submit")}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DetectionFeedback;
