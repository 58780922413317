import React, { useState, useRef, useEffect, useCallback, useLayoutEffect } from "react";
import { fabric } from "fabric-with-erasing";
import "bootstrap/dist/css/bootstrap.css";
import SiteNav from "./components/SiteNav";
import ItemSelection from "./components/ItemSelection";
// import AccessoriesSelection from "./components/accessories/AccessoriesSelection";
import ShapeSelection from "./components/ShapeSelection";
import ZoomAndPan from "./components/zoomAndPan/ZoomAndPan";
import UploadImage from "./components/uploadImage/UploadImage";
import { signInWithEmailAndPassword } from "firebase/auth"; // Import Firebase auth functions
import Auth from "./components/auth/Auth";
import Account from "./components/account/Account";
import CanvasPopup from "./components/canvasPopup/CanvasPopup";
import { useAuth } from "./base";
import ItemColors from "./components/ItemColors";
import CustomPresetColors from "./components/colors/CustomPresetColors";
import Grids from "./components/options/grids/Grids";
import ToolsSelection from "./components/tools/ToolsSelection";
import EraserDraw from "./components/eraserDraw/EraserDraw";
import Calibration from "./components/calibration/Calibration";
import CalibrationDisplay from "./components/calibration/CalibrationDisplay";
import TransumSelectionView from "./components/options/transums/TransumSelectionView";
import $ from "jquery";
import ItemSelectionView from "./components/itemSelectionView/ItemSelectionView";
import AccessoriesSelectionView from "./components/accessories/AccessoriesSelectionView";
import OptionsSelectionView from "./components/optionsSelectionView/OptionsSelectionView";
import DoorsSelectionView from "./components/doorsSelectionView/DoorsSelectionView";
import addTransum from "./otherJS/HandleTransums";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import { Modal } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { availableLanguages, } from './i18nextConf';
import { customProps } from "./helpers/helpers";
import {
  saveCanvasState,
  undo,
  redo,
  removeAllObjects,
  removeAllObjects2,
  fadeObjects,
  changeAction,
  enableDraw,
  enableEraser
} from "./helpers/helpers";
import { setObjectPerspective, getRelatedObject } from './helpers/fabricPerspective';
import {
  unGroupItems,
  groupItems,
  resetZoom,
  panZoomOut,
  panZoomIn,
  goLeft,
  goRight,
  goUp,
  goDown,
  downloadpng,
  objectfliphorizontal,
  objectflipvertical,
  cutItem,
  copyItem,
  pasteItem,
  copyAndPasteItem,
} from "./helpers/helpers";
import sizeAndPosition from "./otherJS/doorsSizeAndPosition";
import { addDoors } from "./components/doors/handleDoors";
import { addGarageDoors } from "./components/doors/handleGarageDoors";
import { addWindows } from "./components/windows/handleWindows";
import Home from "./components/homepage/Home";
// import PerspectiveTransformEditor from "./components/perspectiveEditor/PerspectiveTransformEditor";
// import PerspectiveTransformEditor from "./components/perspectiveEditor/ModalWithThreeJS";
// import BackgroundImageEdit from "./components/backgroundImageEdit/BackgroundImageEDit";
// import ImageEditor from "./components/imageEditor/ImageEditor";
// import EditItems from "./components/editItems/EditItems";
import Resizer from 'react-image-file-resizer';
import {
  db,
  getStorage,
  ref,
  storage,
  uploadString,
  uploadBytes,
  getDownloadURL,
  auth,
  logout
} from "./base";
import {
  addDoc, collection, updateDoc, doc, getDoc, query,
  where, getDocs, setDoc, arrayUnion, Timestamp
} from "firebase/firestore";
import { getMetadata } from "firebase/storage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import useContextMenu from "./hooks/useContextMenu";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import SaveIcon from "@mui/icons-material/Save";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import "./index.css";
import "./mediaqueries.css";
import Banner1 from "./components/banners/Banner1";
import NewsBanner from "./components/banners/NewsBanner";
//consent and terms
import CombinedTermsOfUse from "./components/legal/CombinedTermsOfUse";
import CookieConsent from "react-cookie-consent";
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import ReactDOM from 'react-dom';
import CombinedCookiePolicy from "./components/legal/CombinedCookiePolicy";
import ContentModal from './components/contentModal/ContentModal';
//email confirm
import EmailConfirmationSuccessModal from './components/emailConfirmation/EmailConfirmationSuccessModal';
//chat
import FacadeDesigner from './components/designer/FacadeDesigner';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import ContactFormModal from './ContactFormModal';
import { Aod } from "@mui/icons-material";
import CustomTooltip from './components/toolTips/CustomToolTip';
import AccessoriesSelection from "./components/accessories/AccessoriesSelection";
import addAccessoryImage from "./components/accessories/addAccessoryImage";
//AI selection
import { triggerDetection } from './components/AI/aiDetections';
import { CanvasTooltip } from './components/toolTips/CanvasToolTip'; // Adjust the import path as needed
import SecondaryNav from "./components/secondaryNav/SecondaryNav";
import ScrollToTopButton from "./components/homepage/ScrollToTopButton";
import LoadingToast from "./components/toasts/LoadingToast";
import { handleAnalyticsEvent } from "./helpers/analyticsHandler";

import { colorList } from "./components/colors/colorHelper"
import UserNoticeModal from './components/userMessage/UserNoticeModal';  // Import the modal component
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { getColorByName } from './components/colors/colorUtils';

import WallTextureCanvas from './components/AI/WallTextureCanvas'; // Assuming this component displays the detected walls and textures

import Hammer from 'hammerjs';
import ItemDetailsModal from "./components/ItemDetailsModal";
import DetectionFeedback from "./components/designer/FacadeDesigner/DetectionFeedback";
import DesignVariationCarousel from "./components/carousel/DesignVariationCarousel"
import MenuComponent from "./components/menu/MenuComponent";
import SelectionComponent from "./components/itemSelectionView/SelectionComponent";
import { evaluateProjectImage } from "./components/AI/utility/evaluateProjectImage"; // Import the evaluation function
import { EvaluationProgress } from "./components/AI/utility/EvaluationProgress"; // Import the evaluation function




let optionUrl = "";
// let itemColor = "rgba(0, 0, 0, 1)";

const App = () => {
  const { t, i18n } = useTranslation(["translation"]);

  const mainCanvasRef = useRef(null);
  const topBarRef = useRef(null);
  const menuRef = useRef(null);

  const currentUser = useAuth();
  const [canvas, setCanvas] = useState("");
  const [canvasProject, setCanvasProject] = useState("");
  const [upload, setUpload] = useState(false);
  const [uploadImageUrl, setUploadImageUrl] = useState(null);
  // const [auth, setAuth] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [login, setLogin] = useState(false);
  const [isLoginOut, setIsLoginOut] = useState(false);
  const [account, setAccount] = useState(false);
  const [isSelectedItem, setIsSelectedItem] = useState(false);
  const [typeName, setTypeName] = useState("");
  const [itemColor, setItemColor] = useState("rgba(0, 0, 0, 1)");
  const [canvasProjectImage, setCanvasProjectImage] = useState("");
  const [projectName, setProjectName] = useState("");
  const [imageName, setImageName] = useState("");
  const [newProjectCreationName, setNewProjectCreationName] = useState("");
  const [projectId, setProjectId] = useState(null);
  const [windowURL, setWindowURL] = useState(""); // window or door url
  const [windowGridType, setWindowGridType] = useState(""); // option type
  const [isSameType, setIsSameType] = useState(true);
  const [isMixMatched, setIsMixMatched] = useState(false);
  const [skewX, setSkewX] = useState(0);
  const [skewY, setSkewY] = useState(0);
  const [isSkewVisible, setIsSkewVisible] = useState(false);
  const [skewSliderLeft, setSkewSliderLeft] = useState("160px");
  const [skewSliderTop, setSkewSliderTop] = useState("65px");
  const [isObjSelected, setIsObjSelected] = useState(false);
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [isAccessoriesPanelVisible, setIsAccessoriesPanelVisible] = useState(false);
  const [isOptionsPanelVisible, setIsOptionsPanelVisible] = useState(false);
  const [isTransumPanelVisible, setIsTransumPanelVisible] = useState(false);
  const [isAvaillableDoorsPanelVisible, setIsAvaillableDoorsPanelVisible] =
    useState(false);
  const [itemToSearch, setItemToSearch] = useState("");
  const [isProjectList, setIsProjectList] = useState(false);

  const [isSelectedShape, setIsSelectedShape] = useState(false);
  const [glassToSearch, setGlassToSearch] = useState(""); //size of glass
  const [stringToSearch, setStringToSearch] = useState(""); //door name and type
  const [stringToSearchSide, setStringToSearchSide] = useState(""); //side name
  const [isItemAdded, setIsItemAdded] = useState(false);
  const [backgroundImageStorageUrl, setBackgroundImageStorageUrl] =
    useState("");
  const [canvasImageName, setCanvasImageName] = useState("");
  const [isTransum, setIsTransum] = useState(false);
  const [isLoaderFinish, setIsLoaderFinish] = useState(false);

  const [selectedObject, setSelectedObject] = useState(null);

  const [showEditPopup, setShowEditPopup] = useState(false);
  const [rotationAngle, setRotationAngle] = useState(0);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("");

  const [showLoader, setShowLoader] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const [isDragging, setIsDragging] = useState(false);
  const [isZoomModalOpen, setIsZoomModalOpen] = useState(false);
  const [showCanvasPopupModal, setShowCanvasPopupModal] = useState(false);
  const [currentAction, setCurrentAction] = useState('select');
  const [brushWidth, setBrushWidth] = useState(10);
  const [isObjectSelected, setIsObjectSelected] = useState(false);
  const [hasCanvasChanged, setHasCanvasChanged] = useState(false);
  const [eraseMode, setEraseMode] = useState(false);
  const [drawMode, setDrawMode] = useState(false);
  const [modeType, setModeType] = useState("");
  const [buttonClass, setButtonClass] = useState('before-after-button btn btn-light');
  const [bgImageProps, setBgImageProps] = useState([]);
  const [bgImagePositionProps, setBgImagePositionProps] = useState([])
  const [projects, setProjects] = useState([]);
  const [newProjectImage, setNewProjectImage] = useState("");

  //calibration tool
  const [scaleFactor, setScaleFactor] = useState(1);
  const [projectScaleFactor, setProjectScaleFactor] = useState(null);
  const [knownLength, setKnownLength] = useState(10);
  const [dimension, setDimension] = useState('width');
  const [unit, setUnit] = useState('inches');
  const [showCalibrationOverlay, setShowCalibrationOverlay] = useState(false);
  const [pixelLength, setPixelLength] = useState(null);
  const [isCalibrationMode, setCalibrationMode] = useState(false);
  const [isCalibrating, setIsCalibrating] = useState(false);
  const [selectedObjectHeightReal, setSelectedObjectHeightReal] = useState(0);
  const [selectedObjectWidthReal, setSelectedObjectWidthReal] = useState(0);
  const [realDimensions, setRealDimensions] = useState({ width: 0, height: 0 });
  const [calibrationGroup, setCalibrationGroup] = useState(null);
  const [selectedObjectId, setSelectedObjectId] = useState(null);
  const [calibrationDisplay, setCalibrationDisplay] = useState(false);
  const [hasCalibration, setHasCalibration] = useState(false);
  //admin
  const [userRole, setUserRole] = useState(null);
  //banner
  const [userPlan, setUserPlan] = useState(null);
  //client custom logo
  const [userCompanyName, setUserCompanyName] = useState(null);
  //cookies
  const [showConsentBanner, setShowConsentBanner] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const location = useLocation();
  //email confirm
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  //chat
  const [showModal, setShowModal] = useState(false);
  //itemscolor eye dropper
  const [eyePickerActiveColor, setEyePickerActiveColor] = useState('');
  const bgImagePositionPropsRef = useRef(null);
  //contact form
  const [showContactForm, setShowContactForm] = useState(false);
  //help in canonical
  const canonicalUrl = `https://facadewebapp.com${location.pathname}`;
  //beta news
  const [isNewsVisible, setIsNewsVisible] = useState(true);
  //accessories
  const [selectedAccessory, setselectedAccessory] = useState('');
  const [isAccessoryTypeSelected, setIsAccessoryTypeSelected] = useState(false);
  const [accessoryType, setAccessoryType] = useState('');
  const [isAccessoriesSelectionPanelVisible, setIsAccessoriesSelectionPanelVisible] = useState(false);
  //ai detection
  const [isDimensionsVisible, setIsDimensionsVisible] = useState(true);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(true);
  const [isUpdateMode, setIsUpdateMode] = useState(false);
  const [toastId, setToastId] = useState(null);
  const [uploadComplete, setUploadComplete] = useState(false); // State to track upload completion
  const [editComplete, setEditComplete] = useState(false); // State to track upload completion

  //walls and textures detections
  const [wallsAndTexturesDetections, setWallsAndTexturesDetections] = useState(null);
  const [detectionImage, setDetectionImage] = useState(null);
  const [detectionImageWidth, setDetectionImageWidth] = useState(null);
  const [detectionImageHeight, setDetectionImageHeight] = useState(null);

  //dVirtual designer


  const [isDesignerModalOpen, setDesignerModalOpen] = useState(false);
  const [isDetectionTriggered, setIsDetectionTriggered] = useState(false);
  const [designerTriggered, setDesignerTriggered] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const [projectChangeKey, setProjectChangeKey] = useState(0);

  const [movementX, setMovementX] = useState(0);
  const [movementY, setMovementY] = useState(0);
  const imageWidth = 5;
  const imageHeight = 20;

  const isGuestUser = currentUser && currentUser.email.startsWith("guest_");
  const tooltipMessage = t('tooltip-message', {
    defaultValue: "This feature is only available for registered users.",
  });
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [modalDetails, setModalDetails] = useState(null);
  const [isDoor, setIsDoor] = useState(false);
  const [activeObjectDetails, setActiveObjectDetails] = useState(null);
  const [selectedItemColor, setSelectedItemColor] = useState(null);
  const [roboflowFoundDetections, setRoboflowFoundDetections] = useState([]);
  const [geminiScaleFactor, setGeminiScaleFactor] = useState(null);
  const [detectionFeedbackVisible, setDetectionFeedbackVisible] = useState(false);
  const [webPDownloadURL, setWebPDownloadURL] = useState("");
  const [openDesigner, setOpenDesigner] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [isCanvasVisible, setIsCanvasVisible] = useState(true); // Tracks visibility of canvasContainer
  const [fileId, setFileId] = useState(true); // Tracks visibility of canvasContainer

  const [isEvaluating, setIsEvaluating] = useState(false);
  const [isEvaluationComplete, setIsEvaluationComplete] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [currentProjectId, setCurrentProjectId] = useState(null);
  const [currentFileId, setCurrentFileId] = useState(null);
  const [recommendedColors, setRecommendedColors] = useState([]);
  const [mainColors, setMainColors] = useState([]);

  const [currentStep, setCurrentStep] = useState("uploading");
  const [useAI, setUseAI] = useState(true); // ✅ AI Enabled by Default

// Function to update step
const updateProgress = (step) => {
  setCurrentStep(step);
};


  // useEffect(() => {
  //   if (process.env.NODE_ENV === 'development') {
  //     console.log("Clearing IndexedDB for development...");
  //     indexedDB.deleteDatabase('firebaseLocalStorageDb');
  //     indexedDB.deleteDatabase('firestore/[appName]');
  //   }
  // }, []);

  const handleScroll = () => {
    if (canvasContainerRef.current) { // Ensure ref is not null before accessing
      const containerTop = canvasContainerRef.current.getBoundingClientRect().top;
      const containerBottom = canvasContainerRef.current.getBoundingClientRect().bottom;
      const screenHeight = window.innerHeight;

      // If the bottom of the canvas container is higher than the bottom of the screen, position menu at the bottom of the canvas
      if (containerBottom <= screenHeight) {
        setIsFixed(false); // Menu sticks to the bottom of the canvas container
      } else {
        setIsFixed(true); // Menu is fixed at the bottom of the screen
      }
    }
  };



  // Use useLayoutEffect to ensure the component has fully mounted
  useLayoutEffect(() => {
    handleScroll(); // Check if the canvas is visible initially

    // Set up the scroll event listener after component mount
    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);



  let modalTitle, modalContent;
  if (location.pathname === '/cookies') {
    modalTitle = t('Cookie Policy');
    modalContent = <CombinedCookiePolicy />;
  } else if (location.pathname === '/terms') {
    modalTitle = t('Terms of Use');
    modalContent = <CombinedTermsOfUse />;
  }


  useEffect(() => {

    if (currentUser && !localStorage.getItem('importantNoticeDismissed')) {

      setShowNotice(true);
    }
  }, [currentUser]);

  const handleCloseNotice = () => {
    setShowNotice(false);

    localStorage.setItem('importantNoticeDismissed', 'true');

  };

  useEffect(() => {
    if (location.pathname === '/cookies' || location.pathname === '/terms') {
      setModalOpen(true);
    } else {
      setModalOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const userConsentCookie = document.cookie.split('; ').find(row => row.startsWith('user_consent='));
    setTermsAccepted(userConsentCookie)


  }, [termsAccepted]);

  useEffect(() => {
    // Parse the query parameters
    const queryParams = new URLSearchParams(location.search);

    // Assuming currentUser being true means the user is logged in
    if (currentUser) {
      // Determine if the user is navigating back from the email verification link
      const emailVerifiedParam = queryParams.get('emailVerified') === 'true';

      // Function to handle post-email-verification logic
      const handleEmailVerified = async () => {
        const userDocRef = doc(db, 'users', currentUser.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();

          // Proceed only if the email hasn't been marked as confirmed in Firestore
          if (!userData.emailConfirmed && currentUser.emailVerified) {
            // Update Firestore to mark the email as confirmed
            await updateDoc(userDocRef, { emailConfirmed: true });

            // Send the welcome email now that the email is confirmed
            sendWelcomeEmail({
              email: currentUser.email,
              name: userData.userName,
            }, i18n.language);

            // Show confirmation modal or perform other UI updates as needed
            setShowConfirmationModal(true);
            setShowCanvasPopupModal(false);
          }
        } else {
          console.error('User details not found in Firestore');
        }
      };

      // Check if the user has verified their email via the link (emailVerifiedParam)
      if (emailVerifiedParam && currentUser.emailVerified) {
        handleEmailVerified();
      }
    }

    // Additional logic to handle 'login' query parameter for showing login modal, if necessary
    if (queryParams.get('login') === 'true') {
      setLogin(true);
      // No need to call setShowAuthModal(true) here since currentUser being true implies the user is logged in
    }
  }, [currentUser, location.search, i18n.language]);




  const sendWelcomeEmail = async (user, language) => {
    const payload = {
      userData: {
        email: user.email,
        name: user.name
      },
      language: language
    };

    // http://localhost:9999/.netlify/functions/sendWelcomeEmail /.netlify/functions/sendWelcomeEmail

    try {
      const response = await fetch('/.netlify/functions/sendWelcomeEmail', {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const data = await response.json();

    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  const originalRotationAngle = 0;

  let lastX = 0;
  let lastY = 0;
  let pausePanning = false;
  let zoomStartScale = 1;
  let rightDecorativeGlass;
  let rightSidelightDecorativeGlass;
  let leftSidelightDecorativeGlass;
  let rightLeftSidelightDecorativeGlassLeft;
  let rightLeftSidelightDecorativeGlassRight;



  const initCanvas = () => {
    // Create a new Fabric.js canvas instance
    const canvas = new fabric.Canvas("canv", {
      index: 0,
      state: [],
      // backgroundColor: "#333",
      stateaction: true,
      fireRightClick: true,
      selection: false
    });

    // Enable high-quality image smoothing
    const ctx = canvas.getContext('2d');
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = 'high';

    return canvas;
  };

  let canvasHeight = canvas.height;
  let canvasWidth = canvas.width;

  // toast for consent
  const ConsentToast = () => (
    <div style={{ textAlign: 'center' }}>
      <div>{t('toast.message')}</div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
        <button style={{ marginRight: '10px' }} onClick={handleAccept}>
          {t('toast.accept')}
        </button>
        <button onClick={handleDecline}>
          {t('toast.decline')}
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    // Check if the cookies are set at the start
    if (document.cookie.includes("user_consent_beta=true") && document.cookie.includes("user_consent=true")) {
      setShowConsentBanner(false);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Check for the presence of the user_consent cookie using the native browser API
      const userConsentCookie = document.cookie.split('; ').find(row => row.startsWith('user_consent='));
      if (!userConsentCookie) {
        toast(<ConsentToast />, {
          position: "bottom-center",
          autoClose: false,
          closeOnClick: false,
          pauseOnHover: true,
          closeButton: false,
          toastId: "consent-toast"
        });
      }
    }, 20000); // 20 seconds delay

    return () => clearTimeout(timer);
  }, []);


  //   const {
  //     anchorPoint,
  //     showMenu,
  // } = useContextMenu(menuRef, isDragging);
  useEffect(() => {
    const fetchUserRole = async () => {
      if (!currentUser) {
        setIsAccountModalOpen(false);
        return;
      }

      try {
        const userDocRef = doc(db, "users", currentUser.uid);

        // Cache-first fetch for role and plan
        const userDocSnapshot = await getDoc(userDocRef, { source: 'cache' });

        if (userDocSnapshot.exists()) {
          const { role, planType, companyName } = userDocSnapshot.data();
          setUserRole(role);
          setUserPlan(planType);
          setUserCompanyName(companyName);
        } else {
          console.warn(`No user data found for UID: ${currentUser.uid}`);
        }
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    fetchUserRole();
  }, [currentUser]);


  useEffect(() => {
    // fixed the double canvas by conditionning creation of the canvas with the current user state
    if (currentUser) {
      // handleShowLoader();
      setIsLoaderFinish(false);
      setCanvas(initCanvas());
      // handleHideLoader(); // Move hideLoader() inside the timeout callback

    }
  }, [currentUser]);

  // Function to update Fabric.js settings based on zoom level
  const updateFabricSettings = (zoom = 1) => {
    const controlOffset = 15 / zoom; // Adjust control offset based on zoom level
    const cornerSize = 30 / zoom; // Adjust corner size based on zoom

    fabric.Object.prototype.set({
      cornerSize,
      cornerStyle: "circle",
      cornerHitTolerance: 10 / zoom,
      hasControls: true,
      lockUniScaling: false, // Allow non-uniform scaling
    });

    // Dynamically adjust control positions
    fabric.Object.prototype.controls = {
      ...fabric.Object.prototype.controls,
      tl: new fabric.Control({
        x: -0.5,
        y: -0.5,
        offsetX: -controlOffset,
        offsetY: -controlOffset,
        actionHandler: fabric.controlsUtils.scalingEqually,
        cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
      }),
      tr: new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetX: controlOffset,
        offsetY: -controlOffset,
        actionHandler: fabric.controlsUtils.scalingEqually,
        cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
      }),
      bl: new fabric.Control({
        x: -0.5,
        y: 0.5,
        offsetX: -controlOffset,
        offsetY: controlOffset,
        actionHandler: fabric.controlsUtils.scalingEqually,
        cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
      }),
      br: new fabric.Control({
        x: 0.5,
        y: 0.5,
        offsetX: controlOffset,
        offsetY: controlOffset,
        actionHandler: fabric.controlsUtils.scalingEqually,
        cursorStyleHandler: fabric.controlsUtils.scaleCursorStyleHandler,
      }),
    };
  };

  useEffect(() => {
    // handleShowLoader();
    if (!canvas) {
      handleHideLoader();
      return;
    }

    canvas.setDimensions({
      width: 1200,
      height: 1200,
    });

    updateFabricSettings(1);

    // Add zoom event listener to dynamically update control settings
    const handleZoom = () => {
      const zoom = canvas.getZoom();
      updateFabricSettings(zoom); // Update control settings based on zoom level
    };

    canvas.on("mouse:wheel", (opt) => {
      const delta = opt.e.deltaY;
      let zoom = canvas.getZoom();
      zoom *= 0.999 ** delta;
      zoom = Math.max(0.2, Math.min(zoom, 5)); // Limit zoom level
      canvas.setZoom(zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
      handleZoom(); // Trigger control update after zoom change
    });
    canvasHeight = canvas.height;
    canvasWidth = canvas.width;
    initCanvasEvents(setIsDragging);
    initKeyboardEvents();
    if (projectName) setIsLoaderFinish(true);
    // handleHideLoader();

    // Cleanup events on unmount
    return () => {
      canvas.off("mouse:wheel");
      document.removeEventListener("keyup", initKeyboardEvents);
    };
  }, [canvas]);

  const handlePan = (deltaX, deltaY) => {
    panCanvas(canvas, { x: deltaX, y: deltaY });
  };


  // Set up Hammer.js for gesture recognition
  useEffect(() => {
    if (!canvas) return;

    const container = canvasContainerRef.current;
    let zoomStartScale = canvas.getZoom();
    const originalZoom = 1;  // Assume this is the original zoom level of the canvas
    const minZoomLevel = originalZoom;  // Minimum zoom level should be the original size

    if (container) {

      const hammer = new Hammer(container);
      hammer.get('pinch').set({ enable: true });
      hammer.get('pan').set({ direction: Hammer.DIRECTION_ALL });

      // Function to check if any object is active
      const isObjectActive = () => {
        const activeObject = canvas.getActiveObject();

        return activeObject !== null && activeObject !== undefined;
      };

      // Handle pinch start to initialize zoom level
      hammer.on('pinchstart', () => {
        if (isObjectActive()) return;  // Disable pinch if an object is active
        zoomStartScale = canvas.getZoom();

      });

      // Handle pinch to zoom in/out with restricted minimum zoom level
      hammer.on('pinch', (e) => {
        if (isObjectActive()) return;  // Disable zoom if an object is active
        const newZoomLevel = Math.max(minZoomLevel, zoomStartScale * e.scale);  // Restrict zoom-out level


        applyCenteredZoom(canvas, newZoomLevel);
      });

      const PAN_SENSITIVITY = 0.15;

      // Handle pan to move the canvas, conditionally applied if no object is active
      hammer.on('pan', (e) => {
        if (isObjectActive()) return;  // Disable pan if an object is active

        const deltaX = e.deltaX * PAN_SENSITIVITY;
        const deltaY = e.deltaY * PAN_SENSITIVITY;

        panCanvas(canvas, { x: deltaX, y: deltaY });

      });

      // Reset panning position on pan end for smooth control
      hammer.on('panend', () => {

      });

      // Clean up Hammer.js instance
      return () => {
        hammer.destroy();

      };
    } else {
      console.log("Container not found for Hammer.js initialization");
    }
  }, [canvas]);

  const handleDesignerModalOpen = useCallback(() => {

    // Open the designer modal
    // After opening the modal, reset openDesigner to false
    setTimeout(() => {
      setOpenDesigner(false);
    }, 0); // Reset immediately after opening to prepare for next use
  }, []);












  const handleTouchMoveX = (newX) => {
    const deltaX = newX - movementX;
    setMovementX(newX);
    panCanvas(canvas, { x: deltaX, y: 0 });
  };

  const handleTouchMoveY = (newY) => {
    const deltaY = newY - movementY;
    setMovementY(newY);
    panCanvas(canvas, { x: 0, y: deltaY });
  };



  function panCanvas(canvas, delta) {
    const vpt = canvas.viewportTransform.slice(0); // Clone the viewport transform

    // Apply the delta
    vpt[4] += delta.x;
    vpt[5] += delta.y;

    // Get scaled canvas dimensions
    const canvasWidth = canvas.getWidth() * canvas.getZoom();
    const canvasHeight = canvas.getHeight() * canvas.getZoom();

    // Set container boundaries (update as per actual container size)
    const containerWidth = 1280;
    const containerHeight = 720;

    // Boundary checks to keep the canvas within bounds
    vpt[4] = Math.min(0, Math.max(vpt[4], containerWidth - canvasWidth));
    vpt[5] = Math.min(0, Math.max(vpt[5], containerHeight - canvasHeight));

    // Apply the new viewport transform to the canvas

    canvas.setViewportTransform(vpt);
    canvas.renderAll();
  }



  const applyCenteredZoom = (canvas, newZoom) => {
    const zoomPoint = new fabric.Point(canvas.width / 2, canvas.height / 2);


    // Directly apply zoom at the specified point
    canvas.zoomToPoint(zoomPoint, newZoom);
    canvas.requestRenderAll();
  };


  useEffect(() => {
    if (selectedObject) {

      setIsSelectedItem(true)
      // React to changes in selectedObject

    }
  }, [selectedObject]);


  useEffect(() => {
    if (canvas) {
      // Handle selection events
      const handleSelection = (event) => {
        const selectedObject = event.selected?.[0];
        if (selectedObject) {
          // Extract itemColor (fill or stroke)
          const color = selectedObject.fill || selectedObject.stroke || "rgba(255, 255, 255, 1)";
          setSelectedItemColor(color); // Save to state
        }
      };

      const clearSelection = () => {
        setSelectedItemColor(null); // Reset the color when selection is cleared
      };

      // Listen to canvas events
      canvas.on("selection:created", handleSelection);
      canvas.on("selection:updated", handleSelection);
      canvas.on("selection:cleared", clearSelection);

      canvas.on("object:added", (event) => {
        const addedObject = event.target; // Get the added object

        if (addedObject && addedObject._objects?.[1]) {
          // Reset the custom property on the object
          addedObject._objects[1].windowGridType = null;
        }

        // Reset modal-related state
        setWindowGridType(null);
        setIsDoor(false);
      });

      return () => {
        if (canvas) {
          canvas.off("selection:created", handleSelection);
          canvas.off("selection:updated", handleSelection);
          canvas.off("selection:cleared", clearSelection);
        }
      };
    }
  }, [canvas]);


  useEffect(() => {
    if (canvas) {

      updateColor(itemColor);
    }
  }, [itemColor, canvas]);

  useEffect(() => {
    if (!projectName) {
      setShowCanvasPopupModal(true);
    }
  }, [projectName]);

  useEffect(() => {
    if (canvas) {
      canvas.forEachObject((obj) => {
        if (eraseMode) {

          // console.log("opacity custom prop :", obj.originalOpacity)
          if (!obj.originalOpacity) obj.originalOpacity = obj.opacity || 1; // Store original opacity if not set
          obj.set({ opacity: 0.4 }); // Apply eraser opacity
        } else {
          obj.set({ opacity: obj.originalOpacity || 1 }); // Revert to original opacity
        }
      });
      canvas.renderAll();
    }
  }, [canvas, eraseMode]);


  useEffect(() => {
    if (canvasProject) {
      const scaleFactor = canvasProject.scaleFactor || null;

      if (scaleFactor !== null) {
        setHasCalibration(true);
        setScaleFactor(scaleFactor);

      } else {
        setHasCalibration(false);
      }
    }
  }, [canvasProject]);


  useEffect(() => {
    if (canvas) {
      const handleSelection = (options) => {

        if (options.selected[0]) {
          let relevantId;

          if (options.selected[0].windowStackImageId) {
            relevantId = options.selected[0].windowStackImageId;
          } else if (options.selected[0].doorStackImageId) {
            relevantId = options.selected[0].doorStackImageId;
          }


          if (relevantId !== selectedObjectId) {
            setSelectedObjectId(relevantId);

            const selectedObject = options.selected[0];
            const actualWidth = selectedObject.width * selectedObject.scaleX;
            const actualHeight = selectedObject.height * selectedObject.scaleY;

            const realWidth = (selectedObject.width * selectedObject.scaleX) * scaleFactor;
            const realHeight = (selectedObject.height * selectedObject.scaleY) * scaleFactor;

            setRealDimensions({ width: realWidth.toFixed(2), height: realHeight.toFixed(2) });

          }
        }
      };

      canvas.on('selection:created', handleSelection);
      canvas.on('selection:updated', handleSelection);

      // Also handle the case where the selection is cleared
      canvas.on('selection:cleared', () => {
        setSelectedObjectId(null);
      });

      return () => {
        canvas.off('selection:created', handleSelection);
        canvas.off('selection:updated', handleSelection);
        canvas.off('selection:cleared');
      };
    }
  }, [selectedObjectId, canvas, scaleFactor]);


  useEffect(() => {
    if (calibrationGroup && isCalibrationMode) {

      const handleModified = () => {

        const newPixelLengthScaled = dimension === 'width'
          ? calibrationGroup.width * calibrationGroup.scaleX
          : calibrationGroup.height * calibrationGroup.scaleY;

        handlePixelLengthSet(newPixelLengthScaled);
      };


      calibrationGroup.on('modified', handleModified);
      calibrationGroup.on('selected', handleModified);
      calibrationGroup.on('upddated', handleModified);


      // Cleanup: remove the event listener when the component is unmounted or conditions change
      return () => {
        calibrationGroup.off('modified', handleModified);
        calibrationGroup.off('selected', handleModified);
        calibrationGroup.off('upddated', handleModified);
      };
    }
  }, [calibrationGroup, isCalibrationMode, dimension]);

  useEffect(() => {
    if (canvas) {
      const toggleScrolling = () => {
        if (canvas.isDrawingMode) {
          document.body.classList.add('no-scroll');
          console.log("scrolling off")
        } else {
          document.body.classList.remove('no-scroll');
        }
      };

      // Attach listener to detect changes in `isDrawingMode`
      canvas.on('mouse:down', toggleScrolling);
      canvas.on('mouse:up', toggleScrolling);
      canvas.on('mouse:out', toggleScrolling);

      // Cleanup on unmount
      return () => {
        canvas.off('mouse:down', toggleScrolling);
        canvas.off('mouse:up', toggleScrolling);
        canvas.off('mouse:out', toggleScrolling);
      };
    }
  }, [canvas.isDrawingMode]);



  useEffect(() => {
    if (canvas) {

      const handleModified = (options) => {
        if (options.target) {

          const modifiedObject = options.target;
          const actualWidth = modifiedObject.width * modifiedObject.scaleX;
          const actualHeight = modifiedObject.height * modifiedObject.scaleY;

          const realWidth = (modifiedObject.width * modifiedObject.scaleX) * scaleFactor;
          const realHeight = (modifiedObject.height * modifiedObject.scaleY) * scaleFactor;

          // Update your React state to reflect the new dimensions
          setRealDimensions({ width: realWidth.toFixed(2), height: realHeight.toFixed(2) });
        }
      };

      canvas.on('object:modified', handleModified);

      return () => {
        canvas.off('object:modified', handleModified);
      };
    }
  }, [canvas, scaleFactor]);

  useEffect(() => {
    // Extract the language code from the URL path
    const languageCode = window.location.pathname.split('/')[1];

    // Check if the extracted language code is in the list of available languages
    if (availableLanguages.includes(languageCode)) {
      i18n.changeLanguage(languageCode);
    } else {
      // If not, fallback to browser's language setting
      const browserLang = i18n.language || window.navigator.language.split('-')[0];
      i18n.changeLanguage(browserLang);
    }
  }, [i18n, location.pathname]);



  const canvasContainerRef = useRef(null);

  const knownLengthRef = useRef(knownLength);

  useEffect(() => {
    knownLengthRef.current = knownLength;
  }, [knownLength]);

  useEffect(() => {
    if (showCalibrationOverlay || !showCalibrationOverlay) {
      function handleOverlayClosed() {
        if (canvas && calibrationGroup) {
          // Remove the calibration square tool and deselect it
          canvas.discardActiveObject();
          canvas.remove(calibrationGroup);
          canvas.requestRenderAll();
        }
      }

      // Function to toggle canvas object visibility
      const toggleCanvasObjectsVisibility = (isVisible) => {
        if (!canvas) return; // Guard clause in case canvas is not available
        canvas.discardActiveObject();
        const objects = canvas.getObjects();
        objects.forEach((obj) => {
          if (obj !== calibrationGroup) {
            obj.set('visible', isVisible);
          }
        });
        canvas.requestRenderAll();
      };


      if (showCalibrationOverlay) {
        toggleCanvasObjectsVisibility(false);

        // Add your Calibration tool here, if needed
        // toggleCalibrationMode()

      } else if (!showCalibrationOverlay) {
        toggleCanvasObjectsVisibility(true);

        // Remove the Calibration tool
        handleOverlayClosed();
      }

      // Listen to the custom 'overlayClosed' event
      window.addEventListener('overlayClosed', handleOverlayClosed);

      return () => {
        // Remove the event listener when the component unmounts
        window.removeEventListener('overlayClosed', handleOverlayClosed);
      };
    }
  }, [canvas, calibrationGroup, showCalibrationOverlay]);

  useEffect(() => {
    if (topBarRef.current) {
      topBarRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [projectName, isFocus]);


  const toggleOptionsPanel = () => {
    setIsOptionsPanelVisible((prevValue) => !prevValue);
  };



  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);


  const handleShowLoader = () => {
    setShowLoader(true);
    setIsLoaderFinish(false);
  };

  const handleHideLoader = () => {
    setTimeout(() => {
      setShowLoader(false);
    }, 1000);
    setIsLoaderFinish(true);
  };



  async function canvasBackground(url, scaleFactorOverride = null) {
    return new Promise((resolve, reject) => {
      if (url) {
        clearCanvas(); // Clear previous objects before adding new ones
        fabric.Image.fromURL(url, (oImg) => {
          if (oImg) {
            // If scaleFactorOverride is passed, use it, otherwise calculate the scale factor
            const scaleFactor = scaleFactorOverride || scaleAndPositionImage(oImg);
            resolve(scaleFactor); // Resolve with the scale factor
          } else {
            reject("Error loading image");
          }
        }, { crossOrigin: "anonymous" });
      } else {
        reject("No image URL provided");
      }
    });
  }




  //  scale canvas image for better fit
  function scaleAndPositionImage(bgImage) {
    // get the scale
    // it is the min of the 2 ratios
    let scale_factor = Math.min(
      canvas.width / bgImage.width,
      canvas.height / bgImage.height
    );

    // Lets get the new width and height based on the scale factor
    let newWidth = bgImage.width * scale_factor;
    let newHeight = bgImage.height * scale_factor;

    // get the top left position of the image
    // in order to center the image within the canvas
    let x = canvas.width / 2 - newWidth / 2;
    let y = 0;

    // setBgImageProps(prevProps => [
    //   ...prevProps,
    //   {
    //     x: x,
    //     y: y,
    //     newWidth: newWidth,
    //     newHeight: newHeight,
    //   }
    // ]);


    setBgImageProps({
      scaleX: scale_factor,
      scaleY: scale_factor,
      left: x,
      top: y,
      newWidth: newWidth,
      newHeight: newHeight,
    });


    // When drawing the image, we have to scale down the image
    // width and height in order to fit within the canvas

    canvas.setBackgroundImage(
      bgImage,
      canvas.renderAll.bind(canvas),
      {
        scaleY: scale_factor,
        scaleX: scale_factor,
        left: x,
        top: y,
        originX: "left", // Set the origin of transformation to the left
        originY: "top", // Set the origin of transformation to the top
      },
      {
        crossOrigin: "Anonymous",
      }
    );

    return scale_factor; // Return the scale factor to be used elsewhere
  }

  fabric.Object.NUM_FRACTION_DIGITS = 8;


  // add a custom property
  fabric.Object.prototype.toObject = (function (toObject) {
    return function (propertiesToInclude) {
      propertiesToInclude = (propertiesToInclude || []).concat([
        "windowType",
        "optionType",
        "itemType",
        "realWidth",   // Include realWidth
        "realHeight",  // Include realHeight
        "isDimension"  // Example: Include isDimension if used
      ]);
      return toObject.apply(this, [propertiesToInclude]);
    };
  })(fabric.Object.prototype.toObject);



  const handleActionChange = (action) => {
    if (action === "erase") {
      setEraseMode(true);
    }
    if (action === "draw") {
      setDrawMode(true);
    }
    if (action === "undo") {
      setEraseMode(false);
      setModeType("regular")
    }

    setCurrentAction(action);
    changeAction(action, canvas, brushWidth); // Pass the width to changeAction
  };


  const handleWidthChange = (width) => {
    setBrushWidth(width); // Update the brush width state
    if (currentAction === 'erase') {
      enableEraser(canvas, width);
    } else if (currentAction === 'draw') {
      enableDraw(canvas, width);
    }
    // //saveCanvasState(canvas);
  };

  function extractGridTypeFromUrl(url) {
    // Decode URL to handle encoded characters like '%2F' 
    const decodedUrl = decodeURIComponent(url);

    // Use a regular expression to find the Grid Type
    const regex = /Grid(\d+)_GridOnly/;
    const match = decodedUrl.match(regex);

    // If a match is found, return it
    if (match && match[1]) {
      return 'Grid' + match[1];
    }

    // Otherwise, return a default or null
    return null;
  }

  function extractDecorativeGlassFromUrl(url) {
    setIsDoor(true)
    // Decode URL to handle encoded characters like '%2F'
    const decodedUrl = decodeURIComponent(url);

    // Extract the relevant portion between "doors/" and the file name
    const match = decodedUrl.match(/doors\/decorativeGlass\/([^/]+)\/([^_]+)_([^_]+)_[^ ]+/);

    if (match && match.length >= 4) {
      const manufacturer = match[1]; // e.g., Novatech
      const model = `${match[2]} ${match[3]}`; // e.g., Cachet Patine
      return `${manufacturer} ${model}`; // Combine into desired format
    }

    // Return a default value if no match is found
    return "Unknown Decorative Glass";
  }



  // replace objects filler images like the optionUrl to grid or decorativeGlass image
  function setSrc(tmpObject, gridUrl) {

    var i = tmpObject;
    if (tmpObject.type === "polygon" && tmpObject.usePerspective) {
      i = tmpObject.originObject;
    }

    // Reset windowGridType and isDoor to avoid stale values
    if (i && i.item(1)) {
      i.item(1).set("windowGridType", null);
    }
    setWindowGridType(null); // Reset global state
    setIsDoor(false); // Assume it's not a door unless determined otherwise

    // Extract grid type and decorative glass
    const gridOption = extractGridTypeFromUrl(gridUrl);
    const decorativeGlass = extractDecorativeGlassFromUrl(gridUrl);



    // Determine if the selected item is a door based on the gridUrl
    const isDoorItem = !!decorativeGlass && gridUrl.includes("doors");
    setIsDoor(isDoorItem); // Update global state

    // Determine the appropriate value for windowGridType
    const windowGridTypeValue = isDoorItem ? decorativeGlass : gridOption || "Unknown";

    // Update windowGridType for all relevant cases
    if (i && i.item(1)) {
      i.item(1).set("windowGridType", windowGridTypeValue);
    }
    setWindowGridType(windowGridTypeValue); // Update global state

    const objectStackPosition1 = i._objects[1];
    const objectStackPosition2 = i._objects[2];
    const objectStackPosition3 = i._objects[3];
    const objectStackPosition4 = i._objects[4];
    const objectStackPosition5 = i._objects[5];
    const objectStackPosition6 = i._objects[6];
    const objectStackPosition7 = i._objects[7];
    const objectStackPosition8 = i._objects[8];
    const objectStackPosition9 = i._objects[9];
    const objectStackPosition10 = i._objects[10];
    const objectStackPosition11 = i._objects[11];
    const objectStackPosition12 = i._objects[12];

    // Removed `i.type === "group"`. For groups, it's because of this function call.
    if (isSameType) {
      canvas.getActiveObjects().forEach((tObject) => {
        var object = tObject;
        if (tObject.type === "polygon" && tObject.usePerspective) {
          object = tObject.originObject;
        }
        // we separate the types so there is no error when select all

        const selectedObjectStackPosition1 = object._objects[1];
        const selectedObjectStackPosition2 = object._objects[2];

        if (gridUrl.includes("NoGrid")) {
          fabric.util.loadImage(
            gridUrl,
            function (img) {
              let switchOption = img;
              objectStackPosition1.setElement(switchOption);
              setObjectPerspective(canvas);
              canvas.renderAll();

              selectedObjectStackPosition1.filters = [];
              selectedObjectStackPosition1.applyFilters();
              setObjectPerspective(canvas);
              canvas.renderAll();
            },
            null,
            "anonymous"
          );
        }

        if (
          object.type === "group" &&
          !i.windowType.includes("Door") &&
          !gridUrl.includes("NoGrid")
        ) {
          selectedObjectStackPosition1.filters = [];
          selectedObjectStackPosition1.applyFilters();
          setObjectPerspective(canvas);
          canvas.renderAll();

          // console.log("object itemColor", object.itemColor)

          var filter = new fabric.Image.filters.BlendColor({
            color: object.itemColor,
            mode: "multiply",
            alpha: 0.95,
          });
          selectedObjectStackPosition1.filters[0] = filter;
          selectedObjectStackPosition1.applyFilters();
          setObjectPerspective(canvas);
          canvas.renderAll();
        }
        if (
          object.type === "group" &&
          selectedObjectStackPosition1.optionType.includes("Door")
        ) {
          selectedObjectStackPosition1.set("cutoutSize", glassToSearch);
        } else {
          return;
        }
        setObjectPerspective(canvas);
        canvas.renderAll();
      });
    }
    // we switch decorative glass option

    if (!i.windowType.includes("Door")) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;

          objectStackPosition1.setElement(switchOption);
          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente4") &&
      i.itemUrl.includes("Double_Door") &&
      !i.itemUrl.includes("Right_Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption5 = img;
          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;

          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption7 = img;

          objectStackPosition7.setElement(switchOption7);

          objectStackPosition7.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption8 = img;

          objectStackPosition8.setElement(switchOption8);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente4") &&
      i.itemUrl.includes("Double_Door_Right_Left_SideLight") &&
      !i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption5 = img;
          objectStackPosition9.setElement(switchOption5);

          objectStackPosition9.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;

          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption7 = img;

          objectStackPosition7.setElement(switchOption7);

          objectStackPosition7.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption8 = img;

          objectStackPosition8.setElement(switchOption8);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let leftSidelight = img;

          objectStackPosition5.setElement(leftSidelight);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          // //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let rightSidelight = img;

          objectStackPosition10.setElement(rightSidelight);

          objectStackPosition10.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          // //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente4") &&
      i.itemUrl.includes("Double_Door_Right_Left_SideLight") &&
      i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption5 = img;
          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;

          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption7 = img;

          objectStackPosition7.setElement(switchOption7);

          objectStackPosition7.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption8 = img;

          objectStackPosition8.setElement(switchOption8);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente4") &&
      i.itemUrl.includes("Single_Door") &&
      !i.itemUrl.includes("Sidelight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente4") &&
      i.itemUrl.includes("Single_Door") &&
      i.itemUrl.includes("SidelightFull") &&
      !i.itemUrl.includes("Right_Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente4") &&
      !i.itemUrl.includes("Double_Door") &&
      !i.itemUrl.includes("Right_SideLight") &&
      !i.itemUrl.includes("Full") &&
      i.itemUrl.includes("Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption3 = img;

          objectStackPosition4.setElement(switchOption3);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        leftSidelightDecorativeGlass,
        function (img) {
          let leftSidelight = img;

          objectStackPosition5.setElement(leftSidelight);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          // //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente4") &&
      !i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("Right_SideLight") &&
      !i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption3 = img;

          objectStackPosition4.setElement(switchOption3);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightSidelightDecorativeGlass,
        function (img) {
          let rightSidelight = img;

          objectStackPosition5.setElement(rightSidelight);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          // //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente5") &&
      !i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("Right_SideLight") &&
      !i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption3 = img;

          objectStackPosition4.setElement(switchOption3);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption4 = img;

          objectStackPosition5.setElement(switchOption4);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightSidelightDecorativeGlass,
        function (img) {
          let rightSidelight = img;

          objectStackPosition6.setElement(rightSidelight);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          // //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente5") &&
      !i.itemUrl.includes("Double_Door") &&
      !i.itemUrl.includes("Right_SideLight") &&
      !i.itemUrl.includes("Full") &&
      i.itemUrl.includes("Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption3 = img;

          objectStackPosition4.setElement(switchOption3);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOption4 = img;

          objectStackPosition5.setElement(switchOption4);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          // //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        leftSidelightDecorativeGlass,
        function (img) {
          let leftSidelight = img;

          objectStackPosition6.setElement(leftSidelight);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente5") &&
      i.itemUrl.includes("Double_Door") &&
      !i.itemUrl.includes("Right_Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;
          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption7 = img;

          objectStackPosition7.setElement(switchOption7);

          objectStackPosition7.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption8 = img;

          objectStackPosition8.setElement(switchOption8);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption9 = img;

          objectStackPosition9.setElement(switchOption9);

          objectStackPosition9.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption10 = img;

          objectStackPosition10.setElement(switchOption10);

          objectStackPosition10.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente5") &&
      i.itemUrl.includes("Double_Door_Right_Left_SideLight") &&
      !i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;
          objectStackPosition11.setElement(switchOption6);

          objectStackPosition11.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption7 = img;

          objectStackPosition7.setElement(switchOption7);

          objectStackPosition7.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption8 = img;

          objectStackPosition8.setElement(switchOption8);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption9 = img;

          objectStackPosition9.setElement(switchOption9);

          objectStackPosition9.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption10 = img;

          objectStackPosition10.setElement(switchOption10);

          objectStackPosition10.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let leftSidelight = img;

          objectStackPosition6.setElement(leftSidelight);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let rightSidelight = img;

          objectStackPosition12.setElement(rightSidelight);

          objectStackPosition12.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente5") &&
      i.itemUrl.includes("Double_Door_Right_Left_SideLight") &&
      i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;
          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption7 = img;

          objectStackPosition7.setElement(switchOption7);

          objectStackPosition7.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption8 = img;

          objectStackPosition8.setElement(switchOption8);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption9 = img;

          objectStackPosition9.setElement(switchOption9);

          objectStackPosition9.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption10 = img;

          objectStackPosition10.setElement(switchOption10);

          objectStackPosition10.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente5") &&
      i.itemUrl.includes("Single_Door")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("Corriente5") &&
      i.itemUrl.includes("Single_Door") &&
      i.itemUrl.includes("SidelightFull")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption3 = img;

          objectStackPosition3.setElement(switchOption3);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      !i.itemUrl.includes("Double_Door") &&
      !i.itemUrl.includes("Right_SideLight") &&
      !i.itemUrl.includes("Left_SideLight") &&
      !i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      !i.itemUrl.includes("Double_Door") &&
      !i.itemUrl.includes("Right_SideLight") &&
      i.itemUrl.includes("Full") &&
      i.itemUrl.includes("Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      !i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("Right_SideLight") &&
      !i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightSidelightDecorativeGlass,
        function (img) {
          let rightSidelight = img;

          objectStackPosition4.setElement(rightSidelight);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      !i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("Right_SideLight") &&
      i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      !i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("Left_SideLight") &&
      !i.itemUrl.includes("Right_Left_SideLight") &&
      !i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        leftSidelightDecorativeGlass,
        function (img) {
          let leftSidelight = img;

          objectStackPosition4.setElement(leftSidelight);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      i.itemUrl.includes("Double_Door") &&
      !i.itemUrl.includes("Right_Left_SideLight") &&
      !i.itemUrl.includes("Sidelight_Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;

          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      i.itemUrl.includes("Single_Door") &&
      !i.itemUrl.includes("Right_Left_SideLight") &&
      i.itemUrl.includes("Sidelight_Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("SidelightFull")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition4.setElement(switchOption4);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;

          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let switchOption = img;
          objectStackPosition4.setElement(switchOption);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let switchOption = img;
          objectStackPosition8.setElement(switchOption);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      !i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("Right_Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          fabric.util.loadImage(
            rightLeftSidelightDecorativeGlassLeft,
            function (img) {
              let switchOption = img;
              objectStackPosition4.setElement(switchOption);

              objectStackPosition4.set({
                ownCaching: false,
                dirty: true,
              });
            },
            null,
            "anonymous"
          );
          setObjectPerspective(canvas);
          canvas.renderAll();

          fabric.util.loadImage(
            rightLeftSidelightDecorativeGlassRight,
            function (img) {
              let switchOption = img;
              objectStackPosition5.setElement(switchOption);

              objectStackPosition5.set({
                ownCaching: false,
                dirty: true,
              });

              setObjectPerspective(canvas);
              canvas.renderAll();
            },
            null,
            "anonymous"
          );

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      i.itemUrl.includes("3AGlassAt123") &&
      i.itemUrl.includes("Double_Door") &&
      i.itemUrl.includes("Right_Left_SideLight")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption2 = img;

          objectStackPosition2.setElement(switchOption2);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption1 = img;

          objectStackPosition3.setElement(switchOption1);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption4 = img;

          objectStackPosition7.setElement(switchOption4);

          objectStackPosition7.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption5 = img;

          objectStackPosition5.setElement(switchOption5);

          objectStackPosition5.set({
            ownCaching: false,
            dirty: true,
          });

          let switchOption6 = img;

          objectStackPosition6.setElement(switchOption6);

          objectStackPosition6.set({
            ownCaching: false,
            dirty: true,
          });
        },
        null,
        "anonymous"
      );

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let switchOption = img;
          objectStackPosition4.setElement(switchOption);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let switchOption = img;
          objectStackPosition8.setElement(switchOption);

          objectStackPosition8.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      !objectStackPosition2.optionType &&
      objectStackPosition1.optionType.includes("Single_Door")
    ) {
      i.itemUrl.includes("3AGlassAt3");


      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;

          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
          setIsObjSelected(true);
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes("Double_Door") &&
      !objectStackPosition1.optionType.includes(
        "Double_Door_Right_Left_SideLight"
      )
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;
          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });
          let switchOptionRight = img;

          objectStackPosition2.setElement(switchOptionRight);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes("Single_Door_Right_SideLight") &&
      !i.itemUrl.includes("NoGlass") &&
      !i.itemUrl.includes("3AGlassAt123")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;

          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          if (!i.itemUrl.includes("Full")) {
            fabric.util.loadImage(
              rightSidelightDecorativeGlass,
              function (img) {
                let rightSidelight = img;

                objectStackPosition2.setElement(rightSidelight);

                objectStackPosition2.set({
                  ownCaching: false,
                  dirty: true,
                });

                setObjectPerspective(canvas);
                canvas.renderAll();
                setIsObjSelected(true);
                //saveCanvasState(canvas);
              },
              null,
              "anonymous"
            );
          }
          setObjectPerspective(canvas);
          canvas.renderAll();
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes("Single_Door_Right_SideLight") &&
      !i.itemUrl.includes("Full") &&
      i.itemUrl.includes("NoGlass")
    ) {
      fabric.util.loadImage(
        rightSidelightDecorativeGlass,
        function (img) {
          let rightSidelight = img;

          objectStackPosition1.setElement(rightSidelight);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes("Single_Door_Left_SideLight") &&
      !i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("NoGlass")
    ) {

      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switchOption = img;

          objectStackPosition1.setElement(switchOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          if (!i.itemUrl.includes("Full")) {
            fabric.util.loadImage(
              leftSidelightDecorativeGlass,
              function (img) {
                let leftSidelight = img;

                objectStackPosition2.setElement(leftSidelight);

                objectStackPosition2.set({
                  ownCaching: false,
                  dirty: true,
                });

                setObjectPerspective(canvas);
                canvas.renderAll();
                setIsObjSelected(true);
                //saveCanvasState(canvas);
              },
              null,
              "anonymous"
            );
          }
          setObjectPerspective(canvas);
          canvas.renderAll();
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes("Single_Door_Left_SideLight") &&
      !i.itemUrl.includes("Full") &&
      i.itemUrl.includes("NoGlass")
    ) {
      fabric.util.loadImage(
        leftSidelightDecorativeGlass,
        function (img) {
          let leftSidelight = img;

          objectStackPosition1.setElement(leftSidelight);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          setIsObjSelected(true);
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes(
        "Single_Door_Right_Left_SideLight"
      ) &&
      !i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("NoGlass")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switcLeftSidelightOption = img;

          objectStackPosition1.setElement(switcLeftSidelightOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let switchDoorOptiont = img;

          objectStackPosition2.setElement(switchDoorOptiont);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();

          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let switcRightSidelightOption = img;

          objectStackPosition3.setElement(switcRightSidelightOption);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();

          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setIsObjSelected(true);
      //saveCanvasState(canvas);

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes(
        "Single_Door_Right_Left_SideLight"
      ) &&
      !i.itemUrl.includes("Full") &&
      i.itemUrl.includes("NoGlass")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switcLeftSidelightOption = img;

          objectStackPosition1.setElement(switcLeftSidelightOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let switcRightSidelightOption = img;

          objectStackPosition2.setElement(switcRightSidelightOption);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();

          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setIsObjSelected(true);
      //saveCanvasState(canvas);

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes(
        "Double_Door_Right_Left_SideLight"
      ) &&
      !i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("NoGlass") &&
      !i.itemUrl.includes("FlatWith") &&
      !i.itemUrl.includes("With") &&
      !i.itemUrl.includes("Tao")
    ) {
      fabric.util.loadImage(
        rightDecorativeGlass,
        function (img) {
          let switcRightSideDoubleDoor = img;

          objectStackPosition3.setElement(switcRightSideDoubleDoor);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switcLeftSideDoubleDoor = img;

          objectStackPosition1.setElement(switcLeftSideDoubleDoor);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let switchRightSidelightOption = img;

          objectStackPosition2.setElement(switchRightSidelightOption);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();

          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let switcRightSidelightOption = img;

          objectStackPosition4.setElement(switcRightSidelightOption);

          objectStackPosition4.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();

          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setIsObjSelected(true);
      //saveCanvasState(canvas);

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes(
        "Double_Door_Right_Left_SideLight"
      ) &&
      i.itemUrl.includes("Full") &&
      !i.itemUrl.includes("NoGlass")
    ) {
      fabric.util.loadImage(
        rightDecorativeGlass,
        function (img) {
          let switcRightSideDoubleDoor = img;

          objectStackPosition2.setElement(switcRightSideDoubleDoor);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switcLeftSideDoubleDoor = img;

          objectStackPosition1.setElement(switcLeftSideDoubleDoor);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setIsObjSelected(true);
      //saveCanvasState(canvas);

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes(
        "Double_Door_Right_Left_SideLight"
      ) &&
      !i.itemUrl.includes("Full") &&
      (i.itemUrl.includes("NoGlass") ||
        i.itemUrl.includes("FlatWith") ||
        i.itemUrl.includes("With") ||
        i.itemUrl.includes("Tao"))
    ) {
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let switchRightSidelightOption = img;

          objectStackPosition2.setElement(switchRightSidelightOption);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();

          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let switcRightSidelightOption = img;

          objectStackPosition1.setElement(switcRightSidelightOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();

          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setIsObjSelected(true);
      //saveCanvasState(canvas);

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes(
        "Single_Door_Right_Left_SideLight"
      ) &&
      !i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switcLeftSidelightOption = img;

          objectStackPosition1.setElement(switcLeftSidelightOption);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          //saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassLeft,
        function (img) {
          let switchDoorOptiont = img;

          objectStackPosition2.setElement(switchDoorOptiont);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });
        },
        null,
        "anonymous"
      );
      setObjectPerspective(canvas);
      canvas.renderAll();

      fabric.util.loadImage(
        rightLeftSidelightDecorativeGlassRight,
        function (img) {
          let switcRightSidelightOption = img;

          objectStackPosition3.setElement(switcRightSidelightOption);

          objectStackPosition3.set({
            ownCaching: false,
            dirty: true,
          });
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setIsObjSelected(true);
      //saveCanvasState(canvas);

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else if (
      objectStackPosition1.optionType.includes(
        "Double_Door_Right_Left_SideLight"
      ) &&
      i.itemUrl.includes("Full")
    ) {
      fabric.util.loadImage(
        rightDecorativeGlass,
        function (img) {
          let switcRightSideDoubleDoor = img;

          objectStackPosition2.setElement(switcRightSideDoubleDoor);

          objectStackPosition2.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          ////saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );
      fabric.util.loadImage(
        optionUrl,
        function (img) {
          let switcLeftSideDoubleDoor = img;

          objectStackPosition1.setElement(switcLeftSideDoubleDoor);

          objectStackPosition1.set({
            ownCaching: false,
            dirty: true,
          });

          setObjectPerspective(canvas);
          canvas.renderAll();
          ////saveCanvasState(canvas);
        },
        null,
        "anonymous"
      );

      setObjectPerspective(canvas);
      canvas.renderAll();
      setIsObjSelected(true);
      ////saveCanvasState(canvas);

      setObjectPerspective(canvas);
      canvas.renderAll();
    } else return;
    setObjectPerspective(canvas);
    // canvas.discardActiveObject();
    canvas.renderAll();
    setIsSelectAll(false); // Adjust based on your state management
  }

  //We add option to selected group
  function addOption(gridUrl, fabricObject = null) {
    let gridOption = extractGridTypeFromUrl(gridUrl);
    setWindowGridType(gridOption);

    if (gridUrl.includes("NoGrid")) {
      optionUrl = gridUrl;

      canvas.getActiveObjects().forEach((object) => {
        if (object.type === "group" || object.type === "polygon") {
          setSrc(object, gridUrl);
        } else {
          return;
        }
      });
    }

    if (gridUrl.includes("Grid")) {
      optionUrl = gridUrl;

      canvas.getActiveObjects().forEach((object) => {
        let filterColor;

        if (object.type === "group") {
          const targetObject = object._objects[2]; // Access index 2 of _objects
          setTypeName(targetObject.windowType);

          // Extract color from filters
          if (targetObject.filters && targetObject.filters.length > 0) {
            const colorFilter = targetObject.filters[0]; // Assuming first filter is relevant
            if (colorFilter && colorFilter.color) {
              filterColor = colorFilter.color; // Get the color from the filter
              setItemColor(filterColor); // Update the itemColor state
            }
          }

          // Set grid URL and re-render
          setSrc(object, gridUrl);
          canvas.renderAll();
        }

        if (object.type === "polygon" && object.usePerspective) {
          const originTargetObject = object.originObject._objects[2];
          setTypeName(originTargetObject.windowType);

          // Extract color from filters
          if (originTargetObject.filters && originTargetObject.filters.length > 0) {
            const colorFilter = originTargetObject.filters[0]; // Assuming first filter is relevant
            if (colorFilter && colorFilter.color) {
              filterColor = colorFilter.color; // Get the color from the filter
              setItemColor(filterColor); // Update the itemColor state
            }
          }

          // Set grid URL and re-render
          setSrc(object, gridUrl);
          canvas.renderAll();
        }
      });
    }


    if (gridUrl.includes("doors")) {
      optionUrl = gridUrl;

      // Extract decorative glass
      const decorativeGlass = extractDecorativeGlassFromUrl(gridUrl);
      if (fabricObject && fabricObject._objects?.[1]) {
        fabricObject._objects[1].windowGridType =
          decorativeGlass || "Unknown Decorative Glass";
      }

      rightDecorativeGlass = gridUrl;

      if (windowURL.includes("Sidelight1A")) {
        const regex = /326_.../;
        const replacementString = "118_948";
        const updatedURL = gridUrl.replace(regex, replacementString);
        rightSidelightDecorativeGlass = updatedURL;
        leftSidelightDecorativeGlass = updatedURL;
        rightLeftSidelightDecorativeGlassLeft = updatedURL;
        rightLeftSidelightDecorativeGlassRight = updatedURL;
      } else if (!windowURL.includes("SidelightFull")) {
        rightSidelightDecorativeGlass = gridUrl.replace("326_", "118_");
        rightLeftSidelightDecorativeGlassRight = gridUrl.replace("326", "118");
        leftSidelightDecorativeGlass = gridUrl.replace("326", "118");
        rightLeftSidelightDecorativeGlassLeft = gridUrl.replace("326", "118");
      }

      canvas.getActiveObjects().forEach((object) => {
        if (object.type === "group")
          setTypeName(object._objects[2].windowType);
        if (object.type === "polygon" && object.usePerspective)
          setTypeName(object.originObject._objects[2].windowType);

        canvas.renderAll();
        if (object.type === "group" || object.type === "polygon") {
          if (!object.itemUrl.includes("Full")) {
            if (windowURL.includes("Sidelight1A")) {
              const regex = /326_.../;
              const replacementString = "118_948";
              const updatedURL = gridUrl.replace(regex, replacementString);
              rightSidelightDecorativeGlass = updatedURL;
            } else {
              rightSidelightDecorativeGlass = gridUrl.replace("326_", "118_");
              leftSidelightDecorativeGlass = gridUrl.replace("326", "118");
              rightLeftSidelightDecorativeGlassRight = gridUrl.replace(
                "326",
                "118"
              );
              rightLeftSidelightDecorativeGlassLeft = gridUrl.replace(
                "326",
                "118"
              );
            }
          }

          setSrc(object, gridUrl);
          canvas.renderAll();
        } else {
          return;
        }
      });

      // canvas.discardActiveObject();
      canvas.renderAll();
    }

    setIsSelectAll(false);
  }





  // We switch color
  function updateColor(colorChoice) {
    // Update windowGridOption


    // Unify to a single color format if needed
    const selectedColor = unifyColorFormat(colorChoice || itemColor);
    setItemColor(selectedColor);

    canvas.getActiveObjects().forEach(async (tmpObject) => {

      var object = tmpObject;

      console.log("obbject", object)
      if (tmpObject.type === "polygon" && tmpObject.usePerspective) {
        object = tmpObject.originObject;
      }
      object.itemColor = selectedColor;
      tmpObject.itemColor = selectedColor;

      if (object.type === "group") {
        const objectsInGroup = object.getObjects();

        if ((object.itemUrl && object.itemUrl.includes("Window")) || object.itemUrl.includes("GarageDoor")) {
          updateColorForObject(objectsInGroup[objectsInGroup.length - 1], selectedColor);

          // Retrieve windowGridType from the object at index 1
          const objectIndex1 = objectsInGroup[1];

          if (objectIndex1 && objectIndex1.windowGridType && objectIndex1.windowGridType.includes("Grid") && !objectIndex1.windowGridType.includes("NoGrid")) {
            updateColorForObject(objectIndex1, selectedColor);
          }
        }

        if (object.itemUrl && object.itemUrl.includes("Door")) {
          updateColorForObject(objectsInGroup[objectsInGroup.length - 3], selectedColor);
        }
      }

      if (tmpObject.itemType === 'accessory') {
        updateColorForObject(tmpObject, selectedColor);
      }

      if (object.type === "circle" || object.type === "rect" || object.type === "triangle") {
        object.set({
          fill: selectedColor,
          alpha: 0.9,
        });
      } else if (object.type === "path") {
        object.set({
          stroke: selectedColor,
        });
      }

      setObjectPerspective(canvas, tmpObject);
    });
    // canvas.discardActiveObject();
    canvas.requestRenderAll();
    saveCanvasState(canvas);
    setIsSelectAll(false);
  }


  function updateColorForObject(obj, selectedColor) {

    console.log("obj in update color", obj)
    if (!obj || !obj.filters) return;

    const hasBlendColorFilter = obj.filters.some(filter => filter instanceof fabric.Image.filters.BlendColor);

    if (hasBlendColorFilter) {
      // Clear and reapply filters
      obj.filters = [];
      obj.applyFilters();

      const filter = new fabric.Image.filters.BlendColor({
        color: selectedColor,
        mode: "multiply",
        alpha: 0.95,
      });
      obj.filters.push(filter);
      obj.applyFilters();
      canvas.requestRenderAll();
    }

  }

  function unifyColorFormat(color) {
    // Convert color to a single format (e.g., Hex)
    // Implementation here...
    return color;
  }
  const extractItemColor = (object) => {
    if (object.itemColor) {
      return object.itemColor; // Use the custom itemColor if present
    }
    if (object.fill) {
      return object.fill; // Use the fill property as a fallback
    }
    return "rgba(255, 255, 255, 1)"; // Default to white
  };


  const addItem = (window, tName, coords = null, isAICall = false, scaleFactor = null, realScaleFactor) => {
    let newCoords = null;
    let itemColor = null;

    const currentSelectedObject = getSelectedObject();

    // console.log("coords in add item", coords)

    // console.log("selected object", currentSelectedObject)

    if (currentSelectedObject) {
      newCoords = {
        left: currentSelectedObject.left,
        top: currentSelectedObject.top,
        scaleX: currentSelectedObject.scaleX,
        scaleY: currentSelectedObject.scaleY,
        width: currentSelectedObject.getScaledWidth(),
        height: currentSelectedObject.getScaledHeight(),
      };

      // Extract color from the selected object
      itemColor = extractItemColor(currentSelectedObject);

      canvas.remove(currentSelectedObject);
    }

    const finalCoords = coords || newCoords;
    const properties = buildProperties(
      window,
      tName,
      finalCoords,
      isAICall,
      scaleFactor,
      realScaleFactor,
      itemColor // Pass the extracted color here
    );



    if (window.includes("Door") && !window.includes("GarageDoor")) {
      addDoors(canvas, properties, () => { }, realScaleFactor);
    } else if (window.includes("Window")) {
      addWindows(canvas, properties, () => { }, realScaleFactor);
    } else if (window.includes("Garage")) {
      addGarageDoors(canvas, properties, () => { });
    }

    canvas.renderAll();
  };


  // Helper Function: Apply Gemini Scale Factor
  const applyGeminiScaleFactor = (coords, geminiScaleFactor) => {
    if (!coords) return null;

    return {
      left: coords.left * geminiScaleFactor,
      top: coords.top * geminiScaleFactor,
      width: coords.width * geminiScaleFactor,
      height: coords.height * geminiScaleFactor,
    };
  };





  function buildProperties(window, tName, coords, isAICall, scaleFactor, realScaleFactor, designerTriggered) {
    // Construct and return the properties object based on provided parameters
    return {
      window: window,
      tName: tName,
      isDimensionsVisible: isDimensionsVisible,
      typeName: tName,
      windowURL: windowURL,
      windowGridType: windowGridType,
      optionUrl: optionUrl,
      glassToSearch: glassToSearch,
      stringToSearch: stringToSearch,
      stringToSearchSide: stringToSearchSide,
      rightDecorativeGlass: rightDecorativeGlass,
      rightSidelightDecorativeGlass: rightSidelightDecorativeGlass,
      leftSidelightDecorativeGlass: leftSidelightDecorativeGlass,
      rightLeftSidelightDecorativeGlassLeft:
        rightLeftSidelightDecorativeGlassLeft,
      rightLeftSidelightDecorativeGlassRight:
        rightLeftSidelightDecorativeGlassRight,
      designerTriggered,
      // Include coords if provided
      ...coords,
      isAICall, // Include the AI flag directly
      scaleFactor,
      realScaleFactor,
      fill: itemColor, // Apply the extracted color
      isSelectAll,
      // More properties can be added as necessary
    };
  }


  function getSelectedObject() {
    return canvas.getActiveObject();
  }

  // // Function to open the modal
  // const openDetailModal = (details, imageUrl, itemColor) => {
  //   setModalDetails({ details, imageUrl, itemColor });
  //   setIsDetailModalOpen(true);
  // };

  // Function to close the modal
  const closeDetailModal = () => {
    setIsDetailModalOpen(false);
    setModalDetails(null); // Clear modal data
  };


  function openItemDetailsModal(targetObject) {
    if (!targetObject) {
      console.warn("No target object provided for modal.");
      return;
    }

    console.log("Target Object:", targetObject);

    let filterColor = "N/A"; // Default value for color

    // Extract the correct filter based on the type and number of stacked images
    if (targetObject._objects && Array.isArray(targetObject._objects)) {
      const isDoor = targetObject.itemType === "doors";
      const targetIndex = isDoor
        ? targetObject._objects.length - 2 // Second-to-last for doors
        : targetObject._objects.length - 1; // Last for windows

      const targetSubObject = targetObject._objects[targetIndex];

      if (targetSubObject?.filters?.length > 0) {
        const blendColorFilter = targetSubObject.filters.find((filter) => filter.type === "BlendColor");
        filterColor = blendColorFilter?.color || "N/A";
      }
    }

    // Extract details from the target object
    const details = {
      windowType: targetObject.windowType || "N/A",
      optionType: capitalizeFirstLetter(targetObject.optionType || "N/A"),
      itemType: capitalizeFirstLetter(targetObject.itemType || "N/A"),
      windowGridType: targetObject.itemType === "doors"
        ? targetObject?._objects?.[1]?.windowGridType || t("details.none") // Assign decorativeGlass for doors
        : targetObject?._objects?.[1]?.windowGridType || t("details.none"), // Assign windowGridType for others
      realWidth: Number.isFinite(targetObject.realWidth) ? `${targetObject.realWidth} inches` : "N/A",
      realHeight: Number.isFinite(targetObject.realHeight) ? `${targetObject.realHeight} inches` : "N/A",
      color: filterColor, // Use the dynamically extracted filter color
    };

    // Extract the image URL
    const imageUrl = targetObject.itemUrl || null;

    // Match the color in your predefined color list
    const matchedColor = colorList.find((color) => color.color === filterColor);
    const itemColorName = matchedColor ? matchedColor.name : "N/A";

    // Prepare modal data
    const modalData = {
      details: { ...details, itemColor: itemColorName },
      imageUrl: imageUrl,
      itemColor: filterColor,
    };

    // console.log("Setting modal details with:", modalData);

    // Set modal details
    setModalDetails(modalData);

    // Open the modal after ensuring details are updated
    setTimeout(() => {
      // console.log("Opening modal after details are set.");
      setIsDetailModalOpen(true);
    }, 0);
  }





  function capitalizeFirstLetter(string) {
    if (!string) return "N/A";
    return string
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase() // Convert to lowercase for consistent formatting
      .split(" ") // Split into words
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
      .join(" "); // Join back with spaces
  }


  // Add event listener for context menu suppression
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault(); // Prevent the browser's default context menu
  });

  let pressTimer;

  function initCanvasEvents(setIsDragging) {
    canvas.off("mouse:down");
    canvas.off("mouse:up");
    canvas.off("object:modified");
    canvas.off("object:moved");
    canvas.off("mouse:wheel");
    canvas.off("touch:gesture");
    canvas.off("object:selected");
    canvas.off("selection:cleared");
    canvas.off("selection:created");
    canvas.off("selection:updated");
    canvas.off("touch:drag");
    canvas.on({
      "touch:gesture": function (e) {


        if (e.e.touches && e.e.touches.length == 2) {
          pausePanning = true;
          var point = new fabric.Point(e.self.x, e.self.y);
          if (e.self.state === "start") {
            zoomStartScale = canvas.getZoom();
          }
          var delta = zoomStartScale * e.self.scale;
          canvas.zoomToPoint(point, delta);
          pausePanning = false;
        }
      },

      "object:added": (e) => {
        setHasCanvasChanged(true);
        saveCanvasState(canvas, hasCanvasChanged);
        setWindowURL(e.target.itemUrl)
      },

      "object:removed": (e) => {
        setHasCanvasChanged(true);
        saveCanvasState(canvas, hasCanvasChanged);
      },

      "object:selected": function (e) {
        pausePanning = true;
        updateSkew();
        updateObjectSelected();
      },
      "selection:created": function (e) {
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
          const isDoorSelected = activeObject.itemType === "doors"; // Check if the selected item is a door
          setIsDoor(isDoorSelected); // Set the state based on the item type
          setSelectedObject(activeObject); // Update the selected object state
        }
        if (e.selected && e.selected.length > 0) {
          const activeObject = e.selected[0];
          setSelectedObject(activeObject);
        } else {
          console.log("No target found in selection:created event.");
        }

        setIsObjectSelected(true);
        updateSkew();
        setObjectPerspective(canvas);
      },
      "selection:updated": function () {
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
          const isDoorSelected = activeObject.itemType === "doors"; // Check if the selected item is a door
          setIsDoor(isDoorSelected); // Set the state based on the item type
          setSelectedObject(activeObject); // Update the selected object state
        }
        updateSkew();
        updateObjectSelected();
        setObjectPerspective(canvas);
      },
      "selection:cleared": function () {
        pausePanning = false;
        updateSkew();
        setSelectedObject(null);
        setIsObjectSelected(false);
        updateObjectSelected();
        if (!setIsObjSelected) {
          setIsOptionsPanelVisible(false);
        }
      },

      "touch:drag": function (e) {
        if (
          pausePanning === false &&
          undefined !== e.e.layerX &&
          undefined !== e.e.layerY
        ) {
          var currentX = e.e.layerX;
          var currentY = e.e.layerY;
          var xChange = currentX - lastX;
          var yChange = currentY - lastY;

          if (
            Math.abs(currentX - lastX) <= 50 &&
            Math.abs(currentY - lastY) <= 50
          ) {
            var delta = new fabric.Point(xChange, yChange);
            canvas.relativePan(delta);
          }

          lastX = e.e.layerX;
          lastY = e.e.layerY;
        }
      },
      "mouse:down": function (e) {

        if (canvas.isDrawingMode) {
          // Prevent default behavior for drawing or erasing
          if (e.e.button === 0) { // Left mouse button
            e.e.preventDefault();
            e.e.stopPropagation();
          }
          return; // Skip further processing for drawing/erasing
        }

        // Existing logic for mouse:down starts here

        // Long press handling (if needed)
        // pressTimer = setTimeout(() => {
        //   if (e.target) {
        //     // Long press detected
        //     openItemDetailsModal(e.target); // Open your modal with the selected object
        //   }
        // }, 800); // 800ms for a long press

        const mobileActiveObject = canvas.getActiveObject();

        if (mobileActiveObject) {
          setWindowURL(mobileActiveObject.itemUrl);
          setIsSelectedItem(true)
        }

        // Right-click handling
        if (e.e.button === 2) { // Right-click (button 2)
          console.log("Right-click detected");

          const target = canvas.findTarget(e.e); // Get the object under the cursor
          if (target) {
            canvas.setActiveObject(target); // Programmatically set the target as the active object
            setSelectedObject(target); // Update the state with the selected object
            canvas.renderAll(); // Ensure the canvas updates visually
            // Prevent the browser's default context menu
            e.e.preventDefault();
            e.e.stopPropagation();

            // Open the modal with the selected item's details
            openItemDetailsModal(target);
            return; // Stop further processing for right-click
          } else {
            console.log("No target found under right-click.");
          }
        }

        // Left-click or other mouse interactions
        if (e.e.button === 0) { // Left-click (button 0)
          setIsObjSelected(true);

          const activeObject = canvas.getActiveObject(); // Get the active object
          if (activeObject) {
            // Logic for when an object is selected
            activeObject.offsetX = e.e.offsetX;
            activeObject.offsetY = e.e.offsetY;

            setIsSelectedItem(true);
            setIsItemAdded(true);
          } else {
            // Logic for when no object is selected
            setIsMixMatched(false);
            setIsSelectedItem(false);
            return;
          }

          if (e.target && e.target.type !== "group") {
            if (
              e.target.type === "polygon" &&
              e.target.usePerspective &&
              e.target.originObject.type === "group"
            ) {
              setTypeName(e.target.originObject.windowType);
              setWindowURL(e.target.originObject.itemUrl);
              setIsSelectedItem(true);
              e.target.opacity = 0.4;
              canvas.requestRenderAll();
              return;
            }
            // Additional logic for specific types of objects
            setIsSelectedItem(false);
            setIsOptionsPanelVisible(false);
            setIsSelectedShape(true);
            return;
          }

          if (e.target) {
            setTypeName(e.target.windowType);
            setWindowURL(e.target.itemUrl);
            setIsSelectedItem(true);
            e.target.opacity = 0.4;
          }

          canvas.requestRenderAll();
        }
      },



      "mouse:up": function (e) {

        // clearTimeout(pressTimer); // Clear the timer on mouse up

        if (e.target) {
          e.target.opacity = 1;
          canvas.requestRenderAll();
        }
        setHasCanvasChanged(true);
        saveCanvasState(canvas, hasCanvasChanged);

      },

      "object:moved": function (e) {
        // setHasCanvasChanged(true);
        // saveCanvasState(canvas, hasCanvasChanged); 
        e.target.opacity = 0.4;
        // bringAccessoriesToFront(canvas);
      },
      "object:moving": function (e) {

        setIsDragging(true);
      },
      "object:modified": function (e) {
        setHasCanvasChanged(true);

        setIsDragging(false);
        // saveCanvasState(canvas, hasCanvasChanged);
      },

      // "mouse:wheel": function (opt) {
      //   //https://codepen.io/MarsAndBack/pen/MWKzYPe
      //   //saveCanvasState(canvas, canvas.getZoom());
      //   var delta = opt.e.deltaY;
      //   var zoom = canvas.getZoom();
      //   zoom *= 0.999 ** delta;
      //   if (zoom > 20) zoom = 20;
      //   if (zoom < 0.01) zoom = 0.01;
      //   canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      //   opt.e.preventDefault();
      //   opt.e.stopPropagation();
      // },
    });
  }

  function initKeyboardEvents() {
    const handleKeyUp = (e) => {
      // Prevent unintended behaviors (optional for keyup)
      e.preventDefault();
      e.stopPropagation();

      // Check for specific key combinations
      if (e.key === "Delete" || e.key === "Backspace") {
        setIsAvaillableDoorsPanelVisible(false);
        setIsPanelVisible(false);
        setIsOptionsPanelVisible(false)
        setIsPanelVisible(false)
        // Call removeObject() if necessary
        console.log("Delete or Backspace pressed");
      }
      if (e.ctrlKey && e.key === "z") {
        console.log("Undo triggered");
        e.preventDefault(); // Prevent browser's undo
        handleUndo();
      }
      if (e.ctrlKey && e.key === "y") {
        console.log("Redo triggered");
        e.preventDefault(); // Prevent browser's undo
        handleRedo();
      }
    };

    // Attach the keyup listener
    document.addEventListener("keyup", handleKeyUp);

    // Cleanup the listener on component unmount
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }



  function removeObject() {
    var activeObject = canvas.getActiveObject();
    if (!activeObject) return;
    if (activeObject.type === "activeSelection") {
      activeObject.forEachObject(function (object) {
        canvas.remove(object);
        if (canvas.getActiveObject() < 1) {
          setIsItemAdded(false);
        }
      });
    } else {
      canvas.remove(activeObject);
      if (canvas.getActiveObject() < 1) {
        setIsItemAdded(false);
      }
    }
  }

  const checkForSeletedItems = () => {
    const aObj = canvas.getActiveObject(); // Get the active object
    console.log("Active Object Details:", aObj);

    let isSelect = false;
    let typeName = null;
    let isDoorSelected = false;

    if (aObj) {
      isSelect = true;

      if (aObj.type === "group") {
        typeName = aObj.windowType || aObj._objects?.[2]?.windowType || null;
      } else {
        typeName = aObj.windowType || null;
      }

      isDoorSelected = typeName?.includes("Door");
    }

    // Update state with active object details
    setActiveObjectDetails({
      isSelected: isSelect,
      typeName,
      isDoorSelected,
      itemType: aObj?.itemType || null,
      windowURL: aObj?.windowImageURL || null,
    });

    console.log("Selection State:", {
      isSelect,
      typeName,
      isDoorSelected,
    });

    return isSelect;
  };


  function checkSelectedObjectType() {
    var aObj = canvas.getActiveObject();
    let windowTypes = [];
    var ttName = null;
    var isMix = false;
    var isSame = true;
    var type = "";
    if (aObj && aObj.type === "polygon") {
      aObj = aObj.originObject;
    }
    if (aObj && aObj.type === "group") {
      if (aObj._objects && aObj._objects[2]) {
        var tName = aObj._objects[2].windowType;
        if (ttName && tName !== ttName) {
          // we check to seeif all items added on canvas are the same type, important for otions
          isSame = false;
        }
        type = tName;
        ttName = tName;
      }
      if (aObj._objects && !windowTypes.includes(aObj._objects[2].windowType)) {
        windowTypes.push(aObj._objects[2].windowType);
      }
      if (windowTypes.length > 1) {
        isMix = true;
      } else if (windowTypes.length === 1) {
        type = windowTypes[0];
      }
    } else if (aObj && aObj.type === "activeSelection") {
      aObj.getObjects().forEach(function (o) {
        if (o._objects && o._objects[2]) {
          var tName = o._objects[2].windowType;
          if (ttName && tName !== ttName) {
            // we check to seeif all items added on canvas are the same type, important for otions
            isSame = false;
          }
          type = tName;
          ttName = tName;
        }
        if (o._objects && !windowTypes.includes(o._objects[2].windowType)) {
          windowTypes.push(o._objects[2].windowType);
        }
      });
      if (windowTypes.length > 1) {
        isMix = true;
      } else if (windowTypes.length === 1) {
        type = windowTypes[0];
      }
    }
    setIsMixMatched(isMix);
    setIsSameType(isSame);
    setTypeName(type);

    return {
      isMix: isMix,
      isSame: isSame,
      type: type,
    };
  }

  function handleProjectName(pName) {
    setProjectName(pName);
  }

  async function toJson() {
    setIsProjectList(false);

    let latestBgProps = null;
    if (bgImageProps && bgImageProps.length > 0) {
      latestBgProps = bgImageProps[bgImageProps.length - 1];
      canvas['backgroundImageProps'] = latestBgProps;
    } else {
      latestBgProps = canvas['backgroundImageProps'];
    }

    // Generate PNG data URL from canvas
    let dataURL = canvas.toDataURL({
      format: 'png',
      multiplier: 1,
    });

    if (latestBgProps) {
      const { x, y, newWidth, newHeight } = latestBgProps;
      dataURL = canvas.toDataURL({
        format: 'png',
        left: x,
        top: y,
        width: newWidth,
        height: newHeight,
        multiplier: 1,
      });
    }

    const userId = currentUser.uid;
    const originalFilename = `${projectName.replaceAll(" ", "_")}_${userId}.png`;

    try {
      const storage = getStorage();

      // Storage ref for original treated image
      const treatedStorageRef = ref(
        storage,
        `usersProjectsImages/${userId}/canvasImages/canvasImagesTreated/${originalFilename}`
      );
      await uploadString(treatedStorageRef, dataURL, 'data_url');
      const originalDownloadURL = await getDownloadURL(treatedStorageRef);

      // Resize and upload PNG image
      const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
      };
      const file = dataURItoBlob(dataURL);
      let resizedDataURL = await new Promise((resolve, reject) => {
        Resizer.imageFileResizer(
          file,
          800,
          800,
          'PNG',
          100,
          0,
          (uri) => {
            resolve(uri);
          },
          'base64'
        );
      });

      const resizedFilename = `${projectName.replaceAll(" ", "_")}_${userId}_resized.png`;
      const resizedStorageRef = ref(
        storage,
        `usersProjectsImages/${userId}/canvasImages/canvasImagesResized/${resizedFilename}`
      );
      await uploadString(resizedStorageRef, resizedDataURL, 'data_url');
      const resizedDownloadURL = await getDownloadURL(resizedStorageRef);

      // Generate and upload WebP image
      let webPDataURL = canvas.toDataURL({
        format: 'webp',
        quality: 0.8,  // Adjust quality for WebP here
        multiplier: 1,
      });
      const webPFilename = `${projectName.replaceAll(" ", "_")}_${userId}_webp.webp`;
      const webPStorageRef = ref(
        storage,
        `usersProjectsImages/${userId}/canvasImages/canvasImagesWebP/${webPFilename}`
      );
      await uploadString(webPStorageRef, webPDataURL, 'data_url');
      const webPDownloadURL = await getDownloadURL(webPStorageRef);

      // Return an array of URLs for use in other functions
      return {
        originalTreated: originalDownloadURL,
        resized: resizedDownloadURL,
        webP: webPDownloadURL
      };

    } catch (error) {
      console.error("Error generating download URL or uploading:", error);
      // Optionally handle errors or state updates here
    }

    canvas.renderAll();
  }


  // Function to get the selected item's type
  const getSelectedItemType = () => {
    if (!canvas) return null;

    const activeObject = canvas.getActiveObject();
    if (activeObject) {
      return activeObject.itemType || null; // Return `itemType` or `null` if not present
    }
    return null; // No active object
  };








  function toggleDimensionsOnCanvas(isVisible) {
    const objects = canvas.getObjects();

    objects.forEach((obj) => {
      if (obj.isDimension) { // Check the custom attribute
        obj.visible = isVisible;
      }
    });

    canvas.requestRenderAll();
  }



  const toggleDimensionsVisibility = () => {
    const newVisibility = !isDimensionsVisible;
    setIsDimensionsVisible(newVisibility); // Update state
    toggleDimensionVisibilityOnCanvas(canvas, !isDimensionsVisible);
    // Toggle visibility of all dimension objects on the canvas
    toggleDimensionsOnCanvas(newVisibility); // Use the new state immediately

  };

  const toggleDimensionVisibilityOnCanvas = (canvas, isVisible) => {
    canvas.getObjects().forEach((obj) => {
      if (obj.isDimension) {  // Check if the object has the isDimension property
        obj.set('visible', isVisible);  // Set visibility based on the checkbox state
      }
    });
    canvas.renderAll();  // Re-render the canvas to reflect visibility changes
  };


  // Ensure `proposedColors` is normalized and passed correctly
  const detectAndApplyColors = async (proposedColors) => {

    handleShowLoader()
    // Normalize proposed colors to handle different casing or formatting
    const filteredColors = Object.keys(proposedColors)
      .filter(key => key.toLowerCase() !== 'garagedoor' && key.toLowerCase() !== 'portedugarage')
      .reduce((acc, key) => {
        // Normalize the key to singular form if it's 'windows'
        let normalizedKey = key.toLowerCase().replace(/\s+/g, '');
        if (normalizedKey === 'windows') {
          normalizedKey = 'window';  // Use singular form for consistency
        }
        acc[normalizedKey] = proposedColors[key];
        return acc;
      }, {});


    try {
      if (!isDetectionTriggered) {
        await triggerAIDetectionForOpenedProject(filteredColors);
        setIsDetectionTriggered(true);
      } else {

      }

      await saveUpdate();
      canvas.renderAll();

      const objects = canvas.getObjects();
      if (objects.length === 0) {
        console.error("No objects detected on the canvas. Colors will not be applied.");
        return;
      }

      applyColorsToCanvasItems(filteredColors); // Use filtered colors

      // Save canvas as WebP after detection
      const canvasDataUrl = canvas.toDataURL({
        format: "webp",
        quality: 0.8,
      });

      const webPFilename = `${projectName.replaceAll(" ", "_")}_${currentUser.uid}_detections.webp`;
      const webPStorageRef = ref(
        storage,
        `usersProjectsImages/${currentUser.uid}/canvasImages/canvasImagesWebP/${webPFilename}`
      );

      await uploadString(webPStorageRef, canvasDataUrl, "data_url");
      const downloadURL = await getDownloadURL(webPStorageRef);

      if (!detectionFeedbackVisible) { // Ensure feedback is only shown once
        setDetectionFeedbackVisible(true);
      }

      return downloadURL;

    } catch (error) {
      console.error('Error during detection and color application:', error);
    } finally {
      setDesignerTriggered(true);
      toggleDimensionsOnCanvas(false);
      handleHideLoader()
    }
  };

  const handleFeedbackSubmit = async (feedback, feedbackId) => {
    try {
      const feedbackRef = feedbackId
        ? doc(db, "AIDetectionsRatings", currentUser.uid, "Feedback", feedbackId) // Update existing feedback
        : doc(collection(db, "AIDetectionsRatings", currentUser.uid, "Feedback")); // Create new feedback

      const feedbackData = {
        ...feedback,
        timestamp: new Date().toISOString(),
        userId: currentUser.uid,
        projectName,
      };

      await setDoc(feedbackRef, feedbackData, { merge: true }); // Create or update the feedback document
      return feedbackRef.id; // Return the ID of the feedback document
    } catch (error) {
      console.error("Error submitting feedback:", error);
      throw error;
    }
  };



  // Function to fetch AI detection from the Netlify function
  async function fetchNetlifyFunctionForWallsAndTexturesDetection(imageUrl) {

    try {
      const response = await fetch('/.netlify/functions/directai-handler', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ imageUrl }),
      });

      if (!response.ok) {
        throw new Error('AI detection failed');
      }


      const data = await response.json();
      // console.log('DirectAI response data:', data); // Log the API response
      return data;
    } catch (error) {
      console.error('Error in fetchNetlifyFunctionForWallAndTextureDetection:', error);
      throw error;
    }
  }

  async function getImageMetadata(imageUrl) {
    // Create a reference to the file in Firebase Storage
    const storageRef = ref(storage, imageUrl);

    try {
      // Fetch the metadata using Firebase SDK
      const metadata = await getMetadata(storageRef);
      // console.log("Metadata:", metadata); // This includes content-type, size, etc.

      // Create an Image object to get the width and height of the image
      const img = new Image();
      img.src = imageUrl;

      return new Promise((resolve, reject) => {
        img.onload = function () {
          const imageWidth = img.width;
          const imageHeight = img.height;
          // console.log("Image dimensions:", { imageWidth, imageHeight });
          resolve({ imageWidth, imageHeight });
        };

        img.onerror = function (err) {
          reject(err);
        };
      });

    } catch (error) {
      console.error("Error fetching metadata:", error);
      return null;
    }
  }

  // Function to trigger the DirectAI detection
  const handleWallsAndTexturesDetection = async () => {

    function constructImageUrl(userId, projectName) {
      const encodedUserId = encodeURIComponent(userId);
      const baseNameUnderscore = projectName.replaceAll(" ", "_");
      const encodedProjectNameOriginal = encodeURIComponent(`${baseNameUnderscore}_${userId}`);

      const imagePathOriginal = `usersProjectsImages/${encodedUserId}/canvasImages/${encodedProjectNameOriginal}`;
      const originalImageUrl = `https://firebasestorage.googleapis.com/v0/b/facadewebapp.appspot.com/o/${encodeURIComponent(imagePathOriginal)}?alt=media`;

      return originalImageUrl;
    }

    const imageURL = constructImageUrl(currentUser.uid, projectName);
    setDetectionImage(imageURL)

    getImageMetadata(imageURL).then(({ imageWidth, imageHeight }) => {
      console.log(`Image dimensions: ${imageWidth}x${imageHeight}`);

      // Now pass these dimensions along with the image URL to your canvas component
      setDetectionImageWidth(imageWidth);
      setDetectionImageHeight(imageHeight);
    });
    console.log("image width and height ;", detectionImageWidth, detectionImageHeight)
    try {
      const response = await fetchNetlifyFunctionForWallsAndTexturesDetection(imageURL);
      setWallsAndTexturesDetections(response); // Set the detections from the API response
      console.log('Detections set:', response); // Log the detections after setting the state
    } catch (error) {
      console.error('Error during detection:', error);
    }
  };


  // App.js
  async function triggerWallsAndTexturesDetection() {
    handleShowLoader(); // Show loader while detection is happening

    function constructImageUrl(userId, projectName) {
      const encodedUserId = encodeURIComponent(userId);
      const baseNameUnderscore = projectName.replaceAll(" ", "_");
      const encodedProjectNameOriginal = encodeURIComponent(`${baseNameUnderscore}_${userId}`);

      const imagePathOriginal = `usersProjectsImages/${encodedUserId}/canvasImages/${encodedProjectNameOriginal}`;
      const originalImageUrl = `https://firebasestorage.googleapis.com/v0/b/facadewebapp.appspot.com/o/${encodeURIComponent(imagePathOriginal)}?alt=media`;

      return originalImageUrl;
    }

    const imageURL = constructImageUrl(currentUser.uid, projectName);
    setDetectionImage(imageURL); // Set detection image to be used for the canvas

    try {
      // Set the background image on the canvas
      await canvasBackground(imageURL);

      // Apply sharpening and contrast adjustment on the canvas image
      const canvasImage = canvas.backgroundImage; // Assuming this is the Fabric image object

      if (canvasImage) {
        // Apply sharpen filter (Convolute)
        const sharpenFilter = new fabric.Image.filters.Convolute({
          matrix: [
            0, -1, 0,
            -1, 5, -1,
            0, -1, 0
          ]
        });
        canvasImage.filters.push(sharpenFilter);

        // Apply contrast adjustment (Brightness filter to simulate contrast)
        const contrastFilter = new fabric.Image.filters.Brightness({
          brightness: 0.2  // Adjust for slight contrast increase; try other values as needed
        });
        canvasImage.filters.push(contrastFilter);

        // Apply the filters
        // canvasImage.applyFilters();
        canvas.renderAll();
      }

      // Fetch detection results from Netlify function for walls and textures
      const aiDetectionResults = await fetchNetlifyFunctionForWallsAndTexturesDetection(imageURL);

      // Access the first set of detection results (as in the previous logic)
      const detections = aiDetectionResults[0];  // Assuming the detection data is in index 0

      // Filter to only include walls and textures
      const filteredDetections = detections.filter(detection => detection.class === 'bricks' || detection.class === 'texture');

      // Iterate over each filtered detection
      filteredDetections.forEach((detection, index) => {
        console.log(`Processing detection #${index} (class: ${detection.class}):`, detection); // Log each detection object

        // Adjust and add the detection to the canvas
        addDetectedWallAndTexture(canvas, detection);
      });

      handleHideLoader(); // Hide loader after detection
      setIsDetectionTriggered(true);
    } catch (error) {
      console.error("Error setting up the canvas background:", error);
      console.error("Error during walls and textures detection:", error);
      handleHideLoader(); // Hide loader in case of error
    }
  }



  function adjustDetectionCoordinates(detection, fabricCanvas) {
    if (!detection.tlbr || detection.tlbr.length < 4) {
      console.error("Invalid detection.tlbr:", detection.tlbr);
      return null;
    }

    let scaleX = fabricCanvas.backgroundImage.scaleX;
    let scaleY = fabricCanvas.backgroundImage.scaleY;
    let offsetX = 0;
    let offsetY = 0;

    const canvasWidth = fabricCanvas.width;
    const bgImgWidth = fabricCanvas.backgroundImage.width * scaleX;
    const canvasHeight = fabricCanvas.height;
    const bgImgHeight = fabricCanvas.backgroundImage.height * scaleX;


    // Center horizontally if the background image is narrower than the canvas
    if (bgImgWidth < canvasWidth) {
      offsetX = (canvasWidth - bgImgWidth) / 2;
    }
    if (bgImgWidth < canvasWidth) {
      offsetY = (canvasHeight - bgImgHeight) / 2;
    }

    // Adjust coordinates based on scaleX and apply offsetX if needed
    const adjustedCoords = {
      left: detection.tlbr[0] * scaleX + offsetX,
      top: detection.tlbr[1] * scaleY, // We use scaleY for vertical adjustments
      width: (detection.tlbr[2] - detection.tlbr[0]) * scaleX,
      height: (detection.tlbr[3] - detection.tlbr[1]) * scaleY, // We use scaleY for vertical size adjustments
      scaleX, // Return scale factors for further usage
      scaleY,
      offsetX, // Offset in case you need horizontal adjustments
      offsetY,

    };

    return adjustedCoords;
  }



  function addDetectedWallAndTexture(canvas, detection) {
    // Adjust the detection coordinates based on the canvas and background image
    const adjustedCoords = adjustDetectionCoordinates(detection, canvas);

    if (!adjustedCoords) {
      console.error("Invalid detection coordinates:", detection);
      return;
    }

    // Convert rectangle bounding box to polygon points
    const points = [
      { x: adjustedCoords.left, y: adjustedCoords.top },
      { x: adjustedCoords.left + adjustedCoords.width, y: adjustedCoords.top },
      { x: adjustedCoords.left + adjustedCoords.width, y: adjustedCoords.top + adjustedCoords.height },
      { x: adjustedCoords.left, y: adjustedCoords.top + adjustedCoords.height }
    ];

    const polygon = new fabric.Polygon(points, {
      fill: detection.class === 'bricks' ? 'rgba(0, 0, 255, 0.3)' : 'rgba(255, 0, 0, 0.3)',
      stroke: 'blue',
      strokeWidth: 1,
      selectable: true,
      objectCaching: false,
      hasBorders: true,
      hasControls: true,
      lockScalingFlip: true,
      perPixelTargetFind: true,
      cornerStyle: 'circle',
      cornerColor: 'red',
    });

    // Add controls to each point and enable editing
    function updatePolygonControls() {
      polygon.controls = polygon.points.reduce((acc, point, index) => {
        acc[`p${index}`] = new fabric.Control({
          positionHandler: (dim, finalMatrix, fabricObject) => {
            return fabric.util.transformPoint(
              {
                x: fabricObject.points[index].x - fabricObject.pathOffset.x,
                y: fabricObject.points[index].y - fabricObject.pathOffset.y,
              },
              fabricObject.calcTransformMatrix()
            );
          },
          actionHandler: (eventData, transform, x, y) => {
            const polygon = transform.target;
            const currentControl = polygon.controls[polygon.__corner];
            const mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center');
            polygon.points[currentControl.pointIndex] = {
              x: mouseLocalPosition.x + polygon.pathOffset.x,
              y: mouseLocalPosition.y + polygon.pathOffset.y
            };
            polygon.dirty = true;
            canvas.requestRenderAll();
            return true;
          },
          render: fabric.controlsUtils.renderCircleControl,
          cornerSize: 8,
          pointIndex: index
        });
        return acc;
      }, {});
      canvas.renderAll();
    }

    // Function to add a new point to the polygon
    function addPoint() {
      // Find the midpoint of the first edge as an example
      const newPoint = {
        x: (polygon.points[0].x + polygon.points[1].x) / 2,
        y: (polygon.points[0].y + polygon.points[1].y) / 2
      };

      // Insert new point between points[0] and points[1]
      polygon.points.splice(1, 0, newPoint);

      // Update controls after adding a new point
      updatePolygonControls();
    }

    // Function to remove a selected point
    function removePoint(index) {
      if (polygon.points.length > 3) {  // Ensure the polygon has at least 3 points
        polygon.points.splice(index, 1);
        updatePolygonControls();
      } else {
        console.warn("A polygon must have at least 3 points.");
      }
    }

    // Add click event listeners for adding/removing points
    canvas.on('mouse:dblclick', function (event) {
      if (event.target === polygon) {
        addPoint();
      }
    });

    canvas.on('mouse:down', function (event) {
      if (event.target && event.target === polygon && event.e.button === 2) { // Right-click to remove point
        const activeControl = polygon.controls[polygon.__corner];
        if (activeControl && polygon.points.length > 3) {
          removePoint(activeControl.pointIndex);
        }
      }
    });

    // Add the polygon to the canvas
    canvas.add(polygon);

    // Initialize polygon controls
    updatePolygonControls();

    // Render the updated canvas
    canvas.renderAll();
  }



  const triggerAIDetectionWithAnalytics = () => {
    // Log the event
    handleAnalyticsEvent('AIDetectionClick', 'AI Detection', 'AI Detection Triggered');

    // Call the original AI detection function
    triggerAIDetectionForOpenedProject();
  };




  async function triggerAIDetectionForOpenedProject(predictions) {
   if (!isEvaluating) {handleShowLoader()}

    function constructImageUrl(userId, projectName) {
      const encodedUserId = encodeURIComponent(userId);
      const baseNameUnderscore = projectName.replaceAll(" ", "_");
      const encodedProjectNameOriginal = encodeURIComponent(`${baseNameUnderscore}_${userId}`);

      const imagePathOriginal = `usersProjectsImages/${encodedUserId}/canvasImages/${encodedProjectNameOriginal}`;
      const originalImageUrl = `https://firebasestorage.googleapis.com/v0/b/facadewebapp.appspot.com/o/${encodeURIComponent(imagePathOriginal)}?alt=media`;

      return originalImageUrl;
    }

    const imageURL = constructImageUrl(currentUser.uid, projectName);
    setDetectionImage(imageURL);

    // ✅ Ensure we have valid detections (prefer state but fallback to new predictions)
    const finalDetections = roboflowFoundDetections.length > 0 ? roboflowFoundDetections : predictions;

    // console.log("🟢 Using detections for AI placement:", finalDetections);

    try {
      await canvasBackground(imageURL); // Set background image on canvas

      if (!finalDetections || finalDetections.length === 0) {
        console.error("❌ No valid detections found for AI placement.");
        handleHideLoader();
        return;
      }

      await triggerDetection(
        addItem,
        setTypeName,
        setWindowURL,
        canvas,
        finalDetections,  // ✅ Ensures only valid detections are used
        projectScaleFactor,
        setProjectScaleFactor,
        isEvaluating
      );

      console.log("✅ AI detection process completed successfully.");
      handleHideLoader();
      setIsDetectionTriggered(true);

    } catch (error) {
      console.error("❌ Error setting up the canvas background:", error);
      console.error("❌ Error during AI detection:", error);
      handleHideLoader();
    }
  }



  const applyColorsToCanvasItems = (proposedColors) => {
    if (!isEvaluating) {handleShowLoader()}
    const objects = canvas.getObjects();

    if (objects.length === 0) {
      console.error("No objects detected on the canvas.");
      return;
    }

    // Normalize `proposedColors` keys for consistent matching
    const normalizedProposedColors = {};
    Object.keys(proposedColors).forEach(key => {
      const normalizedKey = normalizeItemKey(key); // Use a helper function for consistent normalization
      normalizedProposedColors[normalizedKey] = proposedColors[key];
    });


    // Iterate through canvas objects and apply the colors based on `itemType`
    objects.forEach(obj => {
      if (obj.type === 'group') {
        obj._objects.forEach(subObj => {
          const itemType = subObj.itemType ? normalizeItemKey(subObj.itemType) : null;

          if (!itemType) {
            // console.warn("Item type not found or undefined for object:", subObj);
            return; // Skip objects without a valid itemType
          }


          // Get the color array for the item type
          const colorArray = normalizedProposedColors[itemType];

          if (!colorArray || colorArray.length === 0) {
            // console.warn(`No proposed color found for itemType: ${itemType}`);
            return;
          }

          const colorName = colorArray[0]; // Use the first proposed color
          const rgbValue = getColorByName(colorName); // Convert to RGB

          if (rgbValue) {
            // Check if the color is already applied
            const existingFilters = subObj.filters || [];
            const existingColor = existingFilters.find(filter => filter.color === rgbValue);

            if (existingColor) {

              return;
            }

            // Apply the color filter
            const filter = new fabric.Image.filters.BlendColor({
              color: rgbValue,
              mode: "multiply",
              alpha: 0.95,
            });

            subObj.filters = []; // Clear existing filters
            subObj.filters.push(filter);
            subObj.applyFilters(); // Apply the new filter
          } else {
            console.error(`RGB value not found for color: ${colorName}`);
          }
        });
      }
    });

    // Render the canvas to reflect the changes
    canvas.renderAll();

    // Deselect any active objects and re-render
    canvas.discardActiveObject();
    canvas.renderAll();

    // Mark the canvas as changed and save its state
    setHasCanvasChanged(true);
    saveCanvasState(canvas, hasCanvasChanged);
    handleHideLoader()
  };



  const normalizeItemKey = (key) => {
    return key
      .trim()
      .toLowerCase()
      .replace(/\s+/g, '') // Remove spaces
      .normalize("NFD")    // Normalize diacritics
      .replace(/[\u0300-\u036f]/g, ""); // Remove accents
  };


  function applyColorToSpecificItems(itemType, rgbColor) {
    const objects = canvas.getObjects();

    setItemColor(rgbColor);

    if (objects.length === 0) {
      console.error("No objects detected on the canvas.");
      return;
    }

    // Deselect the currently active object
    canvas.discardActiveObject();
    canvas.renderAll();

    objects.forEach((obj) => {
      // console.log("Checking object:", obj);

      if (obj.type === 'group' && obj.itemType === itemType) {
        // console.log(`Group Object Found for ${itemType}:`, obj);

        if (itemType === "doors") {
          // Dynamically calculate the index for the door image (-2 from the top of the stack)
          const totalObjects = obj._objects.length;
          const doorImageIndex = totalObjects - 3;

          const doorImage = obj._objects[doorImageIndex];
          if (doorImage && doorImage.type === 'image') {
            // console.log(`Door Image Found at Index ${doorImageIndex}:`, doorImage);

            // Update the filter for the door image
            applyOrUpdateColorFilter(doorImage, rgbColor);
          } else {
            console.log(`No door image found at index ${doorImageIndex}`);
          }
        } else if (itemType === "window") {
          // console.log("Processing window item");

          // Handle window logic as before
          const gridElement = obj._objects[1];
          if (
            gridElement &&
            gridElement.type === 'image' &&
            gridElement.windowGridType !== null
          ) {
            // console.log("Grid Element Found:", gridElement);

            // Update the filter for the grid element
            applyOrUpdateColorFilter(gridElement, rgbColor);
          } else {
            // console.log("No grid element found or no grid type defined.");
          }

          const windowFrameElement = obj._objects[2];
          if (windowFrameElement && windowFrameElement.type === 'image') {
            // console.log("Window Frame Element Found:", windowFrameElement);

            // Update the filter for the window frame element
            applyOrUpdateColorFilter(windowFrameElement, rgbColor);
          }
        }
      } else {
        // console.log("Skipping non-group or mismatched object:", obj);
      }
    });

    canvas.renderAll();
  }








  function applyOrUpdateColorFilter(imageObj, rgbColor, alpha = 0.95) {
    // console.log("Applying filter to object:", imageObj);

    const existingFilter = imageObj.filters.find(
      (filter) => filter.type === 'BlendColor'
    );

    if (existingFilter) {
      // console.log("Existing filter found. Updating color:", rgbColor);
      existingFilter.color = rgbColor;
      existingFilter.alpha = alpha;
    } else {
      // console.log("No existing filter found. Adding new filter with color:", rgbColor);
      const colorFilter = new fabric.Image.filters.BlendColor({
        color: rgbColor,
        mode: 'multiply',
        alpha: alpha,
      });
      imageObj.filters.push(colorFilter);
    }

    imageObj.applyFilters(); // Reapply filters to update
  }





  function applyRgbColorToObject(object, rgbColor) {
    const filter = new fabric.Image.filters.BlendColor({
      color: rgbColor,
      mode: "multiply",
      alpha: 0.95,
    });

    object.filters = [];
    object.filters.push(filter);
    object.applyFilters();
    setHasCanvasChanged(true);
    saveCanvasState(canvas, hasCanvasChanged);


  }














  function handleImageUploadSuccess(url, useAI) {

    saveNewProject(url, useAI);

  }

  async function triggerRoboflowDetections(imageUrl) {

    try {
      const response = await fetch('/.netlify/functions/roboflowDetections', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ imageUrl }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch detections');
      }

      const result = await response.json();

      if (!result.predictions || !Array.isArray(result.predictions)) {
        console.error('Invalid API response format:', result);
        return [];
      }

      // Process predictions
      const processedPredictions = result.predictions
        .filter(prediction => prediction.class === 'door' || prediction.class === 'window')
        .map(prediction => ({
          class: prediction.class,
          confidence: prediction.confidence,
          tlbr: [
            prediction.y - prediction.height / 2,
            prediction.x - prediction.width / 2,
            prediction.y + prediction.height / 2,
            prediction.x + prediction.width / 2,
          ],
          detection_id: prediction.detection_id,
        }));

      return processedPredictions;
    } catch (error) {
      console.error('Error triggering RoboFlow detections:', error);
      return [];
    }
  }

  const runFullAIDetectionFlow = async () => {
    try {
      console.log("🚀 Starting full AI detection process...");

      const backgroundImage = canvas.backgroundImage;
      const imageUrlFromCanvas = backgroundImage?.getSrc();
      const chosenImageUrl = newProjectImage || imageUrlFromCanvas;

      if (!chosenImageUrl) {
        console.error("❌ No valid image found for detection.");
        toast.error(t('assistant.noImageFound'));
        return;
      }

      // 🔹 Step 1: Run Roboflow Detection
      const predictions = await triggerRoboflowDetections(chosenImageUrl);
      updateProgress("detecting")
      if (!predictions || predictions.length === 0) {
        console.warn("⚠️ No valid predictions found or no doors/windows detected.");
        toast.warning(t("assistant.noDetections"));
        return;
      }

      // console.log("✅ Roboflow detections found:", predictions);

      // 🔹 Save predictions to state before calling AI detection
      setRoboflowFoundDetections(predictions);

      // 🔹 Step 2: Trigger AI placement with detections
      // console.log("🟢 Passing detections to AI placement...");
      triggerAIDetectionForOpenedProject(predictions);

      // console.log("✅ Full AI detection process completed.");

    } catch (error) {
      console.error("❌ Error in AI detection flow:", error);
      toast.error(t("assistant.detectionFailed"));
    }
  };





  async function saveNewProject(url, useAI) {
    await canvasBackground(url);

    const imageUrls = await toJson(); // Assuming this returns only the main URL

    setNewProjectImage(url);
    // handleHideLoader();

    const collectionRef = collection(db, "Projects");
    const payload = {
      projectName: newProjectCreationName.replaceAll(" ", "_"),
      userId: currentUser.uid,
      imageUrls,
      imageName: `${newProjectCreationName.replaceAll(" ", "_") + "_" + currentUser.uid}`,
      projectJson: JSON.stringify(canvas.toJSON()), // Serialize the updated canvas state
      scaleFactor: scaleFactor,
      projectScaleFactor: projectScaleFactor,
      savedTimestamp: null,
      creationDate: Timestamp.now(),
    };

    try {
      const docRef = await addDoc(collectionRef, payload);
      const projectId = docRef.id;

      const backgroundImage = canvas.backgroundImage;
      const imageUrl = backgroundImage?.getSrc();

      // console.log("backgroundImage in saveNew", imageUrl);

      if (imageUrl) {
        setBackgroundImageUrl(imageUrl); // Set the background image URL in state
      }

      // Signal project change to reset FacadeDesigner
      setProjectChangeKey((prevKey) => prevKey + 1);
      console.log("New project created and project change signaled.");

      if (useAI) { setIsEvaluating(true) } // Show progress UI


      // Update user's projectIds array
      const userDocRef = doc(db, "users", currentUser.uid);
      await updateDoc(userDocRef, {
        projectIds: arrayUnion(projectId),
      });

      // Upload the image to OpenAI and get a fileId
      const response = await fetch('/.netlify/functions/openAIImageUpload', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ imageUrl: imageUrl }),
      });

      const data = await response.json();
      const fileId = data.file_id; // Extract fileId from the response
      // console.log("Received fileId from OpenAI:", fileId);

      // Save fileId to state immediately
      setFileId(fileId);

      // Update Firestore document with fileId
      const projectRef = doc(db, "Projects", projectId);
      await updateDoc(projectRef, {
        fileId: fileId,
        evaluation: []  // Initialize the evaluation field (to be updated later)
      });
      // console.log("FileId stored in Firestore.");

      // **Trigger the evaluation function immediately**
      if (useAI) { // ✅ Only trigger AI evaluation if useAI is true

        console.log("AI evaluation is enabled, starting process...");
        startEvaluation(projectId, fileId);

        // ✅ Step 2: Run Roboflow Detection & AI Placement
        await runFullAIDetectionFlow();
      } else {
        console.log("AI evaluation is disabled, skipping evaluation.");
      }

      setHasCanvasChanged(true);
      saveCanvasState(canvas, hasCanvasChanged);

    } catch (error) {
      console.error("Error creating new project:", error);
    } finally {
      setUploadComplete(true);
      // setOpenDesigner(true);
      // console.log(canvas);
    }
  }


  const startEvaluation = async (projectId, fileId) => {
    updateProgress("analyzing"); // ✅ Step 3
    setIsEvaluating(true);
    setIsEvaluationComplete(false);
    setHasError(false);
    setCurrentProjectId(projectId);
    setCurrentFileId(fileId);

    try {
      await evaluateProjectImage(projectId, fileId, (colors) => {
        updateProgress("applyingColors"); // ✅ Step 4
        console.log("🎨 Recommended Colors Set:", colors);
        setRecommendedColors(colors);
      }, (main) => {
        console.log("🌟 Main Colors Set:", main);
        setMainColors(main);
      });

      // ✅ Force a re-render when evaluation is done
      setIsEvaluationComplete(true);
    } catch (error) {
      console.error("❌ Evaluation failed:", error);
      setHasError(true);
    }
  };



  // Retry function
  const retryEvaluation = () => {
    startEvaluation(currentProjectId, currentFileId);
  };






  const resetNewProjectImage = () => {
    setNewProjectImage('');
  };



  function loadProject(proj, scaleFact) {
    if (proj) {
      setIsLoaderFinish(false);
      setCanvasProject(proj);
      setIsSameType(true);

      try {

        // Load project JSON onto the canvas
        canvas.loadFromJSON(proj, () => {
          let objects = canvas.getObjects();
          objects.forEach((obj) => {
            if (obj.type === "polygon" && obj.usePerspective) {
              obj.originObject = getRelatedObject(canvas, obj);
              obj.objectCaching = false;
            }
          });

          // Check and apply the background image properties
          if (proj.backgroundImageProps) {
            const { x, y, newWidth, newHeight, src } = proj.backgroundImageProps;
            fabric.Image.fromURL(src, (img) => {
              img.set({
                left: x,
                top: y,
                width: newWidth,
                height: newHeight,
                selectable: false,
              });
              canvas.setBackgroundImage(img);
              canvas.renderAll();

              // Toggle dimensions visibility after the background image is set
              toggleDimensionVisibilityOnCanvas(canvas, isDimensionsVisible);
            });
          } else {
            canvas.renderAll();

            // Toggle dimensions visibility if no background image is set
            toggleDimensionVisibilityOnCanvas(canvas, isDimensionsVisible);
          }
        });
      } catch (error) {
        console.error("Failed to load project:", error);
      }

      // Load scale factor
      if (scaleFact !== undefined) {
        setScaleFactor(scaleFact);
      }

      setHasCanvasChanged(false);
    } else {
      console.error("Invalid proj object:", proj);
    }
  }


  function clearCanvas() {
    canvas.clear();
    setCalibrationDisplay(false)
    canvas.renderAll();
  }

  // clear canvas
  function deleteAll() {
    if (canvas.getObjects().length === 0) {
      toast.warn(t("app-noobjectoncanvas"));
      return;
    }

    if (window.confirm(t("app-deleteallmessage"))) {
      //saveCanvasState(canvas);
      canvas.remove(...canvas.getObjects());
      optionUrl = "";
      setIsSelectedItem(false);
      setIsSelectedShape(false);
      setIsOptionsPanelVisible(false);
      canvas.discardActiveObject();
      canvas.requestRenderAll();
      setIsOptionsPanelVisible(false);
      setIsAvaillableDoorsPanelVisible(false);
      setIsPanelVisible(false);
      setIsItemAdded(false);
      setIsSelectedItem(false);
    }
  }

  function selectAll() {
    setIsSelectAll(true);
    setIsOptionsPanelVisible(false);

    // Filter to select only objects that are visible, not dimension objects, and have a windowType including 'Window'
    const objectsToSelect = canvas.getObjects().filter(obj =>
      obj.visible && !obj.isDimension && obj.itemUrl && obj.itemUrl.includes("Window")
    );

    if (objectsToSelect.length > 0) {
      setIsSelectedItem(true);
      canvas.discardActiveObject();
      var sel = new fabric.ActiveSelection(objectsToSelect, {
        canvas: canvas,
      });

      canvas.setActiveObject(sel);
      canvas.requestRenderAll();
    } else {
      // Optionally handle the case where no objects meet the criteria
      console.log("No 'Window' type objects found or selected.");
      setIsSelectAll(true);
      return
    }
  }


  const deselectAllItems = () => {
    if (canvas) {
      canvas.discardActiveObject().renderAll();
    }
  };

  function removeObjects() {
    setIsSelectedItem(false);
    setIsAvaillableDoorsPanelVisible(false);
    removeAllObjects(canvas);
    if (canvas.getActiveObject() < 1) setIsItemAdded(false);
  }

  function updateObjectSelected() {
    var aObj = canvas.getActiveObject();
    if (aObj) {
      setIsObjSelected(true);
    } else {
      setIsObjSelected(false);
    }
  }

  function updateSkew() {
    var aObj = canvas.getActiveObject();
    if (aObj && aObj.type != "activeSelection") {
      setIsSkewVisible(true);
      setSkewX(aObj.get("skewX"));
      setSkewY(aObj.get("skewY"));
      // var absCoords = aObj.calcTransformMatrix();
      // aObj.setCoords();
      // setSkewSliderLeft(aObj.left + 'px');
      // setSkewSliderTop(aObj.top + 'px');
    } else {
      setIsSkewVisible(false);
    }
  }

  function handlePanel(itemToSearch) {

    if (itemToSearch === "Accessories") {
      handleAccessoriesPanel()
    } else {

      setItemToSearch(itemToSearch);
      setIsAvaillableDoorsPanelVisible(false);
      setIsOptionsPanelVisible(false);
      setIsPanelVisible(true);
    }
  }

  function handleAccessoriesPanel() {

    setIsAvaillableDoorsPanelVisible(false);
    setIsOptionsPanelVisible(false);
    setIsPanelVisible(false);
    setIsAccessoriesPanelVisible(true)

  }

  function handleAccessoriesSelectionPanel() {

    setIsAvaillableDoorsPanelVisible(false);
    setIsOptionsPanelVisible(false);
    setIsPanelVisible(false);
    setIsAccessoriesSelectionPanelVisible(true)

  }

  function handleOptionsPanel(itemToSearch) {
    setIsPanelVisible(false);
    if (itemToSearch === "transum") {
      setIsTransum(true);
      setIsOptionsPanelVisible(true);
      setIsAvaillableDoorsPanelVisible(false);
    } else setIsTransumPanelVisible(false);
    setIsOptionsPanelVisible(true);
    // setIsAvaillableDoorsPanelVisible(false);
  }

  function handleDoorsView(itemToSearch, URLToSave) {
    setTypeName(itemToSearch);

    setWindowURL(URLToSave);

    setIsAvaillableDoorsPanelVisible(false);
    setIsPanelVisible(false);
    setIsTransumPanelVisible(false);
    setIsOptionsPanelVisible(false);
    setIsAvaillableDoorsPanelVisible(true);
  }

  useEffect(() => {
    const cleanupKeyboardEvents = initKeyboardEvents();

    // Cleanup on unmount
    return () => {
      cleanupKeyboardEvents();
    };
  }, []);


  useEffect(() => {
    const fetchProjects = async () => {
      if (currentUser) {
        // console.log("uid ", currentUser.uid)
        const projectsQuerySnapshot = await getDocs(
          query(collection(db, "Projects"), where("userId", "==", currentUser.uid))
        );
        const projectsData = projectsQuerySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));
        setProjects(projectsData);

      }
    };

    fetchProjects().then(() => {
      if (uploadComplete) {
        setUploadComplete(false);  // Reset uploadComplete after fetching projects
      }
    });

  }, [currentUser, uploadComplete, editComplete, isUpdateMode]);

  const handleProjectUpdate = async (projectName, canvasProject, scaleFactor, projectScaleFactor) => {
    const idFromName = projects.find((na) => na.projectName === projectName.replaceAll(" ", "_"));
    if (!idFromName) {
      console.error("Project not found:", projectName);
      toast.update(toastId, {
        render: "Project not found!",
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
      setIsUpdateMode(false);
      toast.dismiss(toastId);
      return;
    }

    const id = idFromName.id;

    try {
      // Check if the current user is a guest
      const isGuest = currentUser.email.includes('guest');

      // Set initial project JSON and background properties
      let latestBgProps = null;
      if (canvas['backgroundImageProps']) {
        latestBgProps = canvas['backgroundImageProps'];
      }

      // Generate image data URL from canvas
      let dataURL;
      if (latestBgProps) {
        const { x, y, newWidth, newHeight } = latestBgProps;
        dataURL = canvas.toDataURL({
          format: 'png',
          left: x,
          top: y,
          width: newWidth,
          height: newHeight,
          multiplier: 1,
        });
      } else {
        dataURL = canvas.toDataURL({
          format: 'png',
          multiplier: 1,
        });
      }

      if (!isGuest) {
        // Generate a unique filename for the original image
        const userId = currentUser.uid;
        const originalFilename = `${projectName.replaceAll(" ", "_")}_${userId}.png`;

        // Upload canvas image to Firebase Storage in canvasImagesTreated
        const storage = getStorage();
        const treatedStorageRef = ref(
          storage,
          `usersProjectsImages/${userId}/canvasImages/canvasImagesTreated/${originalFilename}`
        );
        await uploadString(treatedStorageRef, dataURL, 'data_url');
        const downloadURL = await getDownloadURL(treatedStorageRef);

        // Resize the image using react-image-file-resizer
        const dataURItoBlob = (dataURI) => {
          const byteString = atob(dataURI.split(',')[1]);
          const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          return new Blob([ab], { type: mimeString });
        };
        const file = dataURItoBlob(dataURL);

        let resizedDataURL = await new Promise((resolve, reject) => {
          Resizer.imageFileResizer(
            file,
            800,
            800,
            'PNG',
            100,
            0,
            (uri) => {
              resolve(uri);
            },
            'base64'
          );
        });

        const resizedFilename = `${projectName.replaceAll(" ", "_")}_${userId}_resized.png`;

        const resizedStorageRef = ref(
          storage,
          `usersProjectsImages/${userId}/canvasImages/canvasImagesResized/${resizedFilename}`
        );
        await uploadString(resizedStorageRef, resizedDataURL, 'data_url');

        // Update the project JSON with the image URL and background image properties
        const updatedProjectJson = canvas.toJSON([...customProps, 'backgroundImageProps']);
        updatedProjectJson.canvasProjectImage = downloadURL ? downloadURL : "MISSING_IMAGE_URL";

        setCanvasProject(updatedProjectJson);

        // Update project in Firestore
        await updateDoc(doc(db, "Projects", id), {
          projectName: projectName.replaceAll(" ", "_"),
          userId: currentUser?.uid,
          imageURL: downloadURL,
          projectJson: JSON.stringify(canvas.toJSON(customProps)),
          scaleFactor: scaleFactor,
          projectScaleFactor: projectScaleFactor,
          savedTimestamp: new Date().toISOString(),
        });

        toast.update(toastId, {
          render: "Project updated successfully!",
          type: toast.TYPE.SUCCESS,
          autoClose: 5000
        });
      } else {
        // If the user is a guest, just update the project JSON without uploading images
        const updatedProjectJson = canvas.toJSON([...customProps, 'backgroundImageProps']);
        updatedProjectJson.canvasProjectImage = "GUEST_SESSION_NO_IMAGE";

        setCanvasProject(updatedProjectJson);

        // Update project in Firestore without the image URLs
        await updateDoc(doc(db, "Projects", id), {
          projectName: projectName.replaceAll(" ", "_"),
          userId: currentUser?.uid,
          projectJson: JSON.stringify(canvas.toJSON(customProps)),
          scaleFactor: scaleFactor,
          projectScaleFactor: projectScaleFactor,
          savedTimestamp: new Date().toISOString(),
        });

        toast.update(toastId, {
          render: "Project updated successfully for guest session!",
          type: toast.TYPE.SUCCESS,
          autoClose: 5000
        });
      }

    } catch (error) {
      toast.update(toastId, {
        render: "Project update failed!",
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
      console.error("Error updating project:", error);
    } finally {
      setIsUpdateMode(true);
      toast.dismiss(toastId);
      setToastId(null);
    }
  };


  const handleGuestLogin = async () => {
    try {
      handleAnalyticsEvent("guestCliked", "TryAsGuest", "Try as a guest Triggered");

      const email = "guest_20240827T173821059Z@facadewebapp.com";
      const password = "TempPassword!123"; // Predefined guest credentials

      await signInWithEmailAndPassword(auth, email, password);

      // **✅ Save guest session flag**
      sessionStorage.setItem("wasGuest", "true");

      setAccount(true); // Ensure this is passed from the parent App component
    } catch (error) {
      console.error("Guest Login Error:", error);
      alert(t("auth-loginError"));
    }
  };




  const saveUpdate = async () => {

    const id = toast.info(<LoadingToast />, { autoClose: false });
    setToastId(id);

    try {


      await handleProjectUpdate(
        projectName,
        canvasProject,
        canvasProjectImage,
        scaleFactor,
        projectScaleFactor
      );

      // console.log("project name :", projectName)

    } catch (error) {
      toast.update(id, {
        render: "Project update failed!",
        type: toast.TYPE.ERROR,
        autoClose: 5000
      });
      console.error("Error updating project:", error);
    } finally {
      setIsAccountModalOpen(false);
      setIsUpdateMode(false);
      setUploadComplete(false);
      setEditComplete(true);
      toast.dismiss(toastId);
      setToastId(null);
    }
  };





  async function handleLogOut() {
    setProjectName("");
    try {
      await logout();
      toJson();
      setIsLoginOut(true)
      setShowAuthModal(false);
      setIsAccountModalOpen(false); // Close the modal on successful logout
    } catch {
      alert(t("auth-error"));
    }
    setUserRole("")
    clearCanvas();
  }

  function toDefault() {
    setUploadImageUrl("");
    deleteAll();
    canvasBackground();
    setShowAuthModal(true);
    setProjectName("");
  }

  function cutobjs() {
    setIsSelectedItem(false);
    setIsAvaillableDoorsPanelVisible(false);
    setIsPanelVisible(false);
    setIsOptionsPanelVisible(false);
    cutItem(canvas);
    if (canvas.getActiveObject() < 1) {
      setIsItemAdded(false);
    }
  }

  const handleRedo = () => {
    console.log("Inside handleRedo function");
    setHasCanvasChanged(true);
    redo(canvas, true);
  };

  // Example event for undo
  const handleUndo = () => {
    console.log("Inside handleUndo function");
    setHasCanvasChanged(true);
    undo(canvas, true);
  };

  const [perspective, setPerspective] = useState(false);

  const changePerspective = () => {
    canvas.isPerspective = !perspective;
    setPerspective(!perspective);
    setObjectPerspective(canvas, null, true);
  }

  //deselect for eraser mode
  function deselectItem() {
    if (canvas && canvas.getActiveObject()) {
      canvas.getActiveObject().set({ opacity: 1 });
      canvas.discardActiveObject();
      canvas.renderAll();
    }
  }

  const handleColorChange = (newColor) => {

    enableDraw(canvas, brushWidth, newColor);  // Pass the new color to your function
  };

  const handleFadeObjects = () => {


    // Change the button class to indicate that something is happening
    setButtonClass('before-after-button btn btn-warning');

    fadeObjects(canvas, () => {

      // Restore the original button class after fade operation
      setButtonClass('before-after-button btn btn-light');
    });


  };
  //calibration button
  const handleDropdownChange = (eventKey) => {
    if (eventKey === "removeDisplay") {
      setCalibrationDisplay(!calibrationDisplay)
      setShowCalibrationOverlay(false);
    }
    if (eventKey === 'toggleCalibration') {
      setShowCalibrationOverlay(!showCalibrationOverlay);
      setIsCalibrating(true)
      setCalibrationDisplay(false)
      if (!hasCalibration) {
        toggleCalibrationMode()
      }
    }
  };

  const toggleCalibrationMode = () => {
    setCalibrationMode(!isCalibrationMode);

    if (!isCalibrationMode) {
      // Create a square shape
      let rect = new fabric.Rect({
        height: 150,
        width: 150,
        fill: 'transparent',
        stroke: 'yellow',
        strokeWidth: 3,
        originX: 'center',
        originY: 'center',  // centering for group
      });

      // Create a text shape
      let text = new fabric.Text('Calibration', {
        fontSize: 20,
        originX: 'center',
        originY: 'center', // centering for group
        fill: "#4caf50",
      });

      // Create a group and add the square and text shapes to it
      let calibrationGroup = new fabric.Group([rect, text], {
        left: canvas.width / 2,
        top: 100,
        typeName: "calibration",
      });

      setCalibrationGroup(calibrationGroup);

      canvas.add(calibrationGroup);
      canvas.bringToFront(calibrationGroup);
      canvas.requestRenderAll();
    }
  };

  const deselectActiveObject = () => {
    if (canvas) { // Assuming fabricCanvas is the variable that holds your Fabric.js canvas instance in App
      canvas.discardActiveObject().requestRenderAll();
    }
  };


  // Function to set pixel length (called from Calibration component)

  const handlePixelLengthSet = (pixelLength) => {

    if (scaleFactor === 1) {
      const newScaleFactor = knownLengthRef.current / pixelLength;
      setScaleFactor(newScaleFactor);

      const newRealDimensions = {
        width: (pixelLength * newScaleFactor).toFixed(2),
        height: (pixelLength * newScaleFactor).toFixed(2), // or whatever calculation is appropriate
      };

      setRealDimensions(newRealDimensions);

    }
  };

  // Set cookies using the native browser API

  const handleAccept = () => {
    ;
    document.cookie = "user_consent_beta=true; path=/; max-age=2592000";
    document.cookie = "user_consent=true; path=/; max-age=2592000";
    setModalOpen(false);
    toast.dismiss("consent-toast");
    setTermsAccepted(true)
    setShowConsentBanner(false);  // Hide the banner
  };

  const handleDecline = () => {

    document.cookie = "user_consent_beta=false; path=/; max-age=2592000";
    document.cookie = "user_consent=false; path=/; max-age=2592000";
    setModalOpen(false);
    toast.dismiss("consent-toast");
    setShowConsentBanner(false);  // Hide the banner
  };

  function showConnectionRequiredToast(t) {
    toast.warning(t("assistant.toastMessage.connectionRequired"), {
      position: "top-center",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  // Function to set all objects to be non-selectable
  function setObjectsSelectable(selectable) {
    canvas.forEachObject(function (obj) {
      obj.selectable = selectable;
    });
    canvas.discardActiveObject(); // Deselect any selected objects
    canvas.requestRenderAll();
  }


  function drawGrid(canvas, cellSize) {
    // Clear any existing grid and text first
    const existingLines = canvas.getObjects('line');
    const existingTexts = canvas.getObjects('text');
    canvas.remove(...existingLines, ...existingTexts);

    for (let i = 0; i <= (canvas.width / cellSize); i++) {
      // Vertical lines
      const verticalLine = new fabric.Line([i * cellSize, 0, i * cellSize, canvas.height], {
        stroke: '#ccc',
        selectable: false,
        evented: false,
        type: 'line'
      });
      canvas.add(verticalLine);

      // Horizontal lines
      const horizontalLine = new fabric.Line([0, i * cellSize, canvas.width, i * cellSize], {
        stroke: '#ccc',
        selectable: false,
        evented: false,
        type: 'line'
      });
      canvas.add(horizontalLine);

      // Coordinate labels
      const xLabel = new fabric.Text(i * cellSize + '', {
        left: i * cellSize,
        top: 0,
        fontSize: 24,
        fill: 'yellow',
        selectable: false,
        evented: false,
      });
      canvas.add(xLabel);

      const yLabel = new fabric.Text(i * cellSize + '', {
        left: 0,
        top: i * cellSize,
        fontSize: 24,
        fill: 'yellow',
        selectable: false,
        evented: false,
      });
      canvas.add(yLabel);
    }

    canvas.renderAll();
  }

  function rgbaToHex(rgba) {
    var parts = rgba.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+)\)$/);
    delete (parts[0]);
    for (var i = 1; i <= 3; ++i) {
      parts[i] = parseInt(parts[i]).toString(16);
      if (parts[i].length === 1) parts[i] = '0' + parts[i];
    }
    return '#' + parts.join('').toUpperCase(); // "#RRGGBB"
  }

  const onEyedropperColorChange = (color) => {
    setEyePickerActiveColor(color); // Assuming this is your state update function
    // Any other logic you need when the color changes
  };

  const handleOpenContactForm = () => {
    setShowContactForm(true);
  };

  const toggleNewsVisibility = () => {
    setIsNewsVisible(!isNewsVisible);
  };

  const handleAddAccessory = (accessory, props) => {
    setIsAccessoryTypeSelected(false)

    console.log("handleAddAccessory called")


    addAccessoryImage(accessory, { ...props, canvas }, itemColor);
  };

  return (
    <div className="container-fluid p-0">

      <HelmetProvider>
        <Helmet>

          <title>{t('title')}</title>
          <link rel="canonical" href={canonicalUrl} />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
          <meta name="description" content={t('description')} />

          <link rel="alternate" hreflang="en" href="https://facadewebapp.com" />

          <link rel="alternate" hreflang="fr" href="https://facadewebapp.com/fr" />

          <link rel="alternate" hreflang="x-default" href="https://facadewebapp.com" />

        </Helmet>
      </HelmetProvider>

      <div className="App siteMainBackgroundColor">
        <header className="header">
          <ToastContainer position="bottom-left" />

          {showAuthModal && (
            <Auth
              closeSignup={setShowAuthModal}
              toJson={toJson}
              clearCanvas={clearCanvas}
              loginSet={login}
              setLogin={setLogin}
              isLoginOut={isLoginOut}
              setIsLoginOut={setIsLoginOut}
              setProjectName={setProjectName}
              setCloseAccount={setAccount}
              closeUpload={setUpload}
              setUserRole={setUserRole}
              termsAccepted={termsAccepted}
              handleAccept={handleAccept}
              handleDecline={handleDecline}
              sendWelcomeEmail={sendWelcomeEmail}
              handleLogOut={handleLogOut}


            />
          )}
          {account && (
            <Account
              closeAccount={setAccount}
              imageURL={setUploadImageUrl}
              canvasProjectImage={canvasProjectImage}
              canvasProject={canvasProject}
              loadProject={loadProject}
              handleProjectName={handleProjectName}
              projectName={projectName}
              setProjectName={setProjectName}
              newProjectCreationName={newProjectCreationName}
              setNewProjectCreationName={setNewProjectCreationName}
              setIsProjectList={setIsProjectList}
              isProjectList={isProjectList}
              downloadpng={downloadpng}
              canvas={canvas}
              uploadImageUrl={uploadImageUrl}
              backgroundImageStorageUrl={backgroundImageStorageUrl} // url to storage
              canvasImageName={canvasImageName}
              setBackgroundImageStorageUrl={setBackgroundImageStorageUrl}
              toJson={toJson}
              onShowLoader={handleShowLoader}
              onHideLoader={handleHideLoader}
              saveNewProject={saveNewProject}
              isLoaderFinish={isLoaderFinish}
              setIsLoaderFinish={setIsLoaderFinish}
              clearCanvas={clearCanvas}
              scaleFactor={scaleFactor}
              setScaleFactor={setScaleFactor}
              projectScaleFactor={projectScaleFactor}
              setProjectId={setProjectId}
              isUpdateMode={isUpdateMode}
              setIsUpdateMode={setIsUpdateMode}
              toastId={toastId}
              projects={projects} // Pass projects to Account
              setEditComplete={setEditComplete}
              setProjectChangeKey={setProjectChangeKey}
              setOpenDesigner={setOpenDesigner}
            />
          )}
          {upload && (
            <UploadImage
              imageURL={setUploadImageUrl}
              setProjectName={setProjectName}
              closeUpload={setUpload}
              closeAccount={setAccount}
              setNewProjectCreationName={setNewProjectCreationName}
              setBackgroundImageStorageUrl={setBackgroundImageStorageUrl}
              setCanvasImageName={setCanvasImageName}
              clearCanvas={clearCanvas}
              showLoader={handleShowLoader}
              hideLoader={handleHideLoader}
              toJson={toJson}
              onImageUploadSuccess={handleImageUploadSuccess}
              setImageName={setImageName}
              updateProgress={updateProgress}
              setIsEvaluating={setIsEvaluating}
              useAI={useAI}
              setUseAI={setUseAI}
            />
          )}
          <SiteNav
            closeUpload={setUpload}
            closeSignup={setShowAuthModal}
            setLogin={setLogin}
            setIsLoginOut={setIsLoginOut}
            closeAccount={setAccount}
            toJson={toJson}
            setIsProjectList={setIsProjectList}
            projectName={projectName}
            upload={upload}
            account={account}
            showCanvasPopupModal={showCanvasPopupModal}
            userRole={userRole}
            setShowModal={setShowModal}
            handleClose={handleClose}
            showConnectionRequiredToast={showConnectionRequiredToast}
            // termsAccepted={termsAccepted}
            handleOpenContactForm={handleOpenContactForm}
            triggerAIDetectionForOpenedProject={triggerAIDetectionForOpenedProject}
            isDimensionsVisible={isDimensionsVisible}
            toggleDimensionsVisibility={toggleDimensionsVisibility}
            handleLogOut={handleLogOut}
            setIsAccountModalOpen={setIsAccountModalOpen}
            isAccountModalOpen={isAccountModalOpen}
            setDesignerModalOpen={setDesignerModalOpen}
            handleAnalyticsEvent={handleAnalyticsEvent} // Pass the analytics function
            handleGuestLogin={handleGuestLogin}


          />

          <UserNoticeModal show={showNotice} handleCloseNotice={handleCloseNotice} />

          {/* <EditItems canvas={canvas} targetObject={selectedObject} /> */}
          <SecondaryNav />
        </header>
        <div>



          <Routes>
            <Route path="/cookies" element={null} />
            <Route path="/terms" element={null} />


            <Route path="/" element={!projectName && <Home setAccount={() => setAccount(true)} handleAnalyticsEvent={handleAnalyticsEvent} handleGuestLogin={handleGuestLogin} />} />

            <Route path="/fr" element={!projectName && <Home setAccount={() => setAccount(true)} handleAnalyticsEvent={handleAnalyticsEvent} handleGuestLogin={handleGuestLogin} />} />

          </Routes>

          {isDetailModalOpen && (
            <ItemDetailsModal
              isOpen={isDetailModalOpen}
              details={modalDetails.details || {}}
              imageUrl={modalDetails.imageUrl || ""}
              itemColor={modalDetails.itemColor || ""}
              onClose={closeDetailModal}
              isDoor={isDoor}
              selectedObject={selectedObject}
            // cutItem={cutItem(canvas)}
            />
          )}

          <ContentModal
            show={isModalOpen}
            title={modalTitle}
            content={modalContent}
            setModalOpen={setModalOpen}
            handleAccept={handleAccept}
            handleDecline={handleDecline}
          />

          <EmailConfirmationSuccessModal
            show={showConfirmationModal}
            onClose={() => setShowConfirmationModal(false)}
            setShowCanvasPopupModal={setShowCanvasPopupModal}
          />

          {/* Your main app contents */}
          {showConsentBanner && (
            <CookieConsent
              location="bottom"
              buttonText={t("consent.accept")}
              cookieName="user_consent_beta"
              style={{ background: "#2B373B" }}
              buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
              onAccept={handleAccept}
            >
              {t("consent.betaConsentText")}
              <span className="legalLinks">
                <Link to="/cookies">{t("consent.betaConsent")}</Link>
                <Link to="/terms">{t("consent.betaTerms")}</Link>
              </span>
            </CookieConsent>
          )}


          {/* Rest of your main app contents */}

          {/* {!currentUser && <Home closeSignup={setShowAuthModal} />} */}

          <div className="mainContainer">


            {<div className="mainContainerLeft">
              {/* {projectName && <div className="news-banner-container">
                <h2 className="news-title" onClick={toggleNewsVisibility}>
                  <img src={process.env.PUBLIC_URL + '/svg-and-icons/megaphoneIcon.svg'} alt="Megaphone Icon" className="icon" />
                  {t("betanews")}
                </h2>
                <div className="news-banner">
                  {isNewsVisible && projectName && <NewsBanner />}
                </div>
              </div>} */}
              {currentUser && projectName && !isCalibrating && (
                <div ref={topBarRef} className="topBar">


                  {/* <button
                    className="openProject btn btn-primary border-6 border-dark"
                    type="button"
                    size="sm"
                    title={t("save-project")}
                    name="saveProject"
                    // disabled={currentUser.email.startsWith("guest_")}  // Disable for guest users
                    onClick={saveUpdate}
                  >
                    <SaveIcon />
                    <span className="button-text p-1">
                      {!projectName ? "" : t("app-saveThisProject")}
                      {projectName.replaceAll("_", " ") ||
                        t("app-startOrOpenProject")}
                    </span>
                  </button> */}

                  <Tooltip
                    title={tooltipMessage}
                    position="top"
                    trigger="mouseenter"
                    disabled={!isGuestUser}
                  >
                    <button
                      className="openProject btn btn-primary border-6 border-dark"
                      type="button"
                      size="sm"
                      title={t("save-project")}
                      name="saveProject"
                      disabled={currentUser.email.startsWith("guest_")}  // Disable for guest users
                      onClick={saveUpdate}
                    >
                      <SaveIcon />
                      <span className="button-text p-1">
                        {!projectName ? "" : t("app-saveThisProject")}
                        {projectName.replaceAll("_", " ") ||
                          t("app-startOrOpenProject")}
                      </span>
                    </button>
                  </Tooltip>


                  {/* <span style={{ padding: "7px 10px" }}>
                    <CustomTooltip content="Hover me!" videoSrc="/gifs_app/perspective-tool.webm">
                      <Form.Check
                        checked={perspective}
                        type={'checkbox'}
                        label={`Perspective`}
                        id={`check-perspective`}
                        onChange={changePerspective}
                      />
                    </CustomTooltip>
                  </span> */}

                  <div className="undoRedoSmall">
                    <button
                      title={t("app-undo")}
                      className="actionBtn"
                      onClick={handleUndo}
                    >
                      <div className="undoIcon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z" />
                        </svg>
                      </div>
                    </button>
                    <button
                      title={t("app-redo")}
                      className="actionBtn"
                      onClick={handleRedo}
                    >
                      <div className="redoIcon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M18.4 10.6C16.55 8.99 14.15 8 11.5 8c-4.65 0-8.58 3.03-9.96 7.22L3.9 16c1.05-3.19 4.05-5.5 7.6-5.5 1.95 0 3.73.72 5.12 1.88L13 16h9V7l-3.6 3.6z" />
                        </svg>
                      </div>
                    </button>
                  </div>

                  {projectName && (
                    <div className="undoRedoWrapper">
                      <div className="undoRedo ms-auto">
                        <button
                          title={t("app-undo")}
                          className="btn btn-primary me-3 border-6 border-dark"
                          onClick={handleUndo}
                        >
                          <UndoIcon />
                          <span className="button-text p-1">{t("app-undo")}</span>
                        </button>
                        <button
                          title={t("app-redo")}
                          className="btn btn-primary me-3 border-6 border-dark"
                          onClick={handleRedo}
                        >
                          <RedoIcon />
                          <span className="button-text p-1">{t("app-redo")}</span>
                        </button>
                      </div>
                    </div>
                  )}

                  <div className="zoomButton undoRedo ms-auto ">
                    <Button
                      title={t("app-zoom")}
                      onClick={() => setIsZoomModalOpen(true)}
                    >
                      <ZoomInIcon />
                      <span className="button-text  p-1">Zoom</span>
                    </Button>
                  </div>
                </div>

              )}
              {isGuestUser && (
                <div className="guestContainer">
                  {t("app-guestMode")}
                </div>
              )}



              {showCalibrationOverlay && (<div className="calibrationContainer">
                <Calibration
                  onKnownLengthChange={setKnownLength}
                  onDimensionToggle={setDimension}
                  onUnitToggle={setUnit}
                  setIsCalibrating={setIsCalibrating}
                  setCalibrationDisplay={setCalibrationDisplay}
                  setCalibrationMode={setCalibrationMode}
                  deselectActiveObject={deselectActiveObject} // to deselect the Calibration Tool
                  setShowCalibrationOverlay={setShowCalibrationOverlay}
                />

              </div>)}

              {(currentAction === 'erase' || currentAction === 'draw') && !isCalibrating && currentAction !== "null" && (
                <div className="eraserDrawContainer">
                  <EraserDraw handleColorChange={handleColorChange} onWidthChange={handleWidthChange} currentWidth={brushWidth} handleActionChange={handleActionChange} mode={modeType} />
                </div>
              )}



              {/* <div className="selectionView">
                {isPanelVisible && (
                  <div className="itemSelectionView">
                    <ItemSelectionView
                      itemToSearch={itemToSearch}
                      addItem={addItem}
                      typeName={typeName}
                      setIsPanelVisible={setIsPanelVisible}
                      handleDoorsView={handleDoorsView}
                      canvasHeight={canvasHeight}
                    />
                  </div>
                )}

                {isAccessoriesPanelVisible && (
                  <div className="itemSelectionView">
                    <AccessoriesSelectionView
                      setIsAccessoryTypeSelected={setIsAccessoryTypeSelected}
                      setIsPanelVisible={setIsPanelVisible}
                      setAccessoryType={setAccessoryType}
                      setIsAccessoriesPanelVisible={setIsAccessoriesPanelVisible}
                    />
                  </div>
                )}

                {isAccessoryTypeSelected && (
                  <div className="itemSelectionView">
                    <AccessoriesSelection
                      accessoryType={accessoryType}
                      addItem={handleAddAccessory}
                      setIsAccessoriesSelectionPanelVisible={setIsAccessoriesSelectionPanelVisible}
                      setIsAccessoriesPanelVisible={setIsAccessoriesPanelVisible}
                      setIsAccessoryTypeSelected={setIsAccessoryTypeSelected}
                    />
                  </div>
                )}

                {isOptionsPanelVisible && (
                  <div className="optionsSelectionView">
                    <OptionsSelectionView
                      isTransum={isTransum}
                      setIsTransum={setIsTransum}
                      addOption={addOption}
                      addTransum={addTransum}
                      windowURL={windowURL}
                      typeName={typeName}
                      setGlassToSearch={setGlassToSearch}
                      setStringToSearch={setStringToSearch}
                      setStringToSearchSide={setStringToSearchSide}
                      setIsOptionsPanelVisible={setIsOptionsPanelVisible}
                    />
                  </div>
                )}
                {isTransumPanelVisible && (
                  <div className="transumSelectionView">
                    <TransumSelectionView
                      addOption={addOption}
                      windowURL={windowURL}
                      typeName={typeName}
                      setGlassToSearch={setGlassToSearch}
                      setIsOptionsPanelVisible={setIsOptionsPanelVisible}
                    />
                  </div>
                )}

                {isAvaillableDoorsPanelVisible && (
                  <div className="doorsSelectionView">
                    <DoorsSelectionView
                      addItem={addItem}
                      windowURL={windowURL}
                      typeName={typeName}
                      itemToSearch={itemToSearch}
                      setIsOptionsPanelVisible={setIsOptionsPanelVisible}
                      setIsPanelVisible={setIsPanelVisible}
                      setIsAvaillableDoorsPanelVisible={
                        setIsAvaillableDoorsPanelVisible
                      }
                      setIsAccessoriesPanelVisible={setIsAccessoriesPanelVisible}
                    />
                  </div>
                )}
              </div> */}

              {showLoader && <div className="pre-loader"></div>}

              <div className="canvasAndBannersParent" style={{ display: projectName ? 'block' : 'none' }}>

                {currentUser && (
                  <>




                    <div ref={canvasContainerRef} className={showCalibrationOverlay ? "canvasContainer showCalibrationOverlay" : "canvasContainer"}>

                      <canvas ref={mainCanvasRef} id="canv" />
                      {/* <CanvasTooltip typeName={typeName} /> */}

                      {isEvaluating && useAI && (
                        <EvaluationProgress
                          isVisible={isEvaluating}
                          isEvaluationComplete={isEvaluationComplete}
                          hasError={hasError}
                          onRetry={retryEvaluation}
                          onClose={() => setIsEvaluating(false)}
                          currentStep={currentStep} // ✅ Pass the dynamic step
                          useAI={useAI}
                        />
                      )}
                      {recommendedColors.length > 0 && (
                        <DesignVariationCarousel
                          canvas={canvas}  // Fabric.js canvas instance
                          applyColorToSpecificItems={applyColorToSpecificItems} // Function to apply colors
                          customProps={customProps}
                          recommendedColors={recommendedColors} // ✅ AI-recommended colors
                          mainColor={mainColors} // ✅ AI base colors
                          updateProgress={updateProgress}
                        />
                      )}

                      {/* New SelectionComponent above Menu */}
                      <SelectionComponent
                        isFixed={isFixed} // Now controls fixed positioning
                        isPanelVisible={isPanelVisible}
                        isAccessoriesPanelVisible={isAccessoriesPanelVisible}
                        isAccessoryTypeSelected={isAccessoryTypeSelected}
                        isOptionsPanelVisible={isOptionsPanelVisible}
                        isTransumPanelVisible={isTransumPanelVisible}
                        isAvaillableDoorsPanelVisible={isAvaillableDoorsPanelVisible}
                        itemToSearch={itemToSearch}
                        addItem={addItem}
                        typeName={typeName}
                        accessoryType={accessoryType}
                        setIsPanelVisible={setIsPanelVisible}
                        handleDoorsView={handleDoorsView}
                        canvasHeight={canvasHeight}
                        setIsAccessoryTypeSelected={setIsAccessoryTypeSelected}
                        setAccessoryType={setAccessoryType}
                        setIsAccessoriesPanelVisible={setIsAccessoriesPanelVisible}
                        handleAddAccessory={handleAddAccessory}
                        addOption={addOption}
                        addTransum={addTransum}
                        windowURL={windowURL}
                        setGlassToSearch={setGlassToSearch}
                        setStringToSearch={setStringToSearch}
                        setStringToSearchSide={setStringToSearchSide}
                        setIsOptionsPanelVisible={setIsOptionsPanelVisible}
                        setIsAvaillableDoorsPanelVisible={setIsAvaillableDoorsPanelVisible}
                        setIsTransumPanelVisible={setIsTransumPanelVisible}
                      />

                      {/* MenuComponent that will position at the bottom of the screen or canvas */}
                      <MenuComponent
                        isFixed={isFixed}
                        handlePanel={handlePanel}
                        addOption={addOption}
                        typeName={typeName}
                        projectName={projectName}
                        isSelectedItem={isSelectedItem}
                        updateColor={updateColor}
                        handleColorChange={handleColorChange}
                        handleOptionsPanel={handleOptionsPanel}
                        eyePickerActiveColor={eyePickerActiveColor}
                        applyColorToSpecificItems={applyColorToSpecificItems}
                        setIsOptionsPanelVisible={setIsOptionsPanelVisible}
                        itemColor={itemColor}
                        setIsItemAdded={setIsItemAdded}
                        isCalibrating={isCalibrating}
                        windowURL={windowURL}
                        isSameType={isSameType}
                        isMixMatched={isMixMatched}
                        checkForSeletedItems={checkForSeletedItems}
                        checkSelectedObjectType={checkSelectedObjectType}
                        toggleOptionsPanel={toggleOptionsPanel}
                        getSelectedItemType={getSelectedItemType}
                        activeObjectDetails={activeObjectDetails}
                        canvas={canvas}
                        handleActionChange={handleActionChange}
                        copyItem={copyItem}
                        selectAll={selectAll}
                        cutItem={cutItem}
                        removeAllObjects2={removeAllObjects2}
                        pasteItem={pasteItem}
                        copyAndPasteItem={copyAndPasteItem}
                        objectfliphorizontal={objectfliphorizontal}
                        objectflipvertical={objectflipvertical}
                        drawGrid={drawGrid}
                        unGroupItems={unGroupItems}
                        setModeType={setModeType}
                        setEraseMode={setEraseMode}
                        setDrawMode={setDrawMode}
                        changePerspective={changePerspective}
                        calibrationDisplay={calibrationDisplay}
                        t={t}
                        scaleFactor={scaleFactor}
                        showCalibrationOverlay={showCalibrationOverlay}
                        handleDropdownChange={handleDropdownChange}
                      />

                      <ContactFormModal show={showContactForm} handleClose={() => setShowContactForm(false)} />
                      {/* {projectName && <button className={buttonClass} title={t("app-beforeAfter")}
                        onClick={handleFadeObjects}><CompareArrowsIcon className="d-md-none" /><span className="before-after-label">{t("app-beforeAfter")}</span> </button>} */}
                      {/* {isGuestUser && (
                      <div className="guest-mode-title">
                        {t("app-guestMode")}
                      </div>
                    )} */}

                      {projectName && (
                        <div>
                          {/* <button onClick={triggerWallsAndTexturesDetection}>Detect Walls and Textures</button> */}
                          {/* <button className="ai-Detect-button-AI btn btn-light" onClick={triggerAIDetectionWithAnalytics}>{t("ai-detect")}</button>
                        <div className="ai-Measurement-button">
                          <input
                            type="checkbox"
                            id="toggleDimensions"
                            checked={isDimensionsVisible}
                            onChange={toggleDimensionsVisibility}
                          />
                          <label htmlFor="toggleDimensions"> Dimensions</label>
                        </div> */}

                          {detectionFeedbackVisible && (
                            <DetectionFeedback
                              canvas={canvas}
                              projectImageUrl={backgroundImageUrl}
                              onSubmit={handleFeedbackSubmit}
                              onClose={() => setDetectionFeedbackVisible(false)}
                              t={t} // Replace with your translation function
                            />
                          )}

                          <FacadeDesigner
                            projects={projects}
                            projectId={projectId}
                            newProjectImage={newProjectImage}
                            resetNewProjectImage={resetNewProjectImage}
                            userRole={userRole}
                            isDesignerModalOpen={isDesignerModalOpen}
                            setDesignerModalOpen={setDesignerModalOpen}
                            handleAnalyticsEvent={handleAnalyticsEvent} // Pass the analytics function
                            applyColorsToCanvasItems={applyColorsToCanvasItems}
                            triggerAIDetectionForOpenedProject={triggerAIDetectionForOpenedProject}
                            fabricCanvas={canvas} // Pass the canvas as a prop
                            saveUpdate={saveUpdate}
                            isDetectionTriggered={isDetectionTriggered}
                            setIsDetectionTriggered={setIsDetectionTriggered}
                            detectAndApplyColors={detectAndApplyColors}
                            projectChangeKey={projectChangeKey}
                            handleHideLoader={handleHideLoader}
                            handleShowLoader={handleShowLoader}
                            roboflowFoundDetections={roboflowFoundDetections}
                            setRoboflowFoundDetections={setRoboflowFoundDetections}
                            setGeminiScaleFactor={setGeminiScaleFactor}
                            backgroundImageUrl={backgroundImageUrl}
                            setBackgroundImageUrlInApp={setBackgroundImageUrl}
                            isGuestUser={isGuestUser}
                            openDesigner={openDesigner}
                            handleDesignerModalOpen={handleDesignerModalOpen}

                          />

                        </div>
                      )}

                      {wallsAndTexturesDetections && <WallTextureCanvas imageUrl={detectionImage} detections={wallsAndTexturesDetections} imageWidth={detectionImageWidth}
                        imageHeight={detectionImageHeight} />}

                      {calibrationDisplay && (
                        <CalibrationDisplay width={realDimensions.width} height={realDimensions.height} unit={unit} />
                      )}

                      {!projectName && currentUser && currentUser.emailVerified && (
                        <CanvasPopup
                          setUpload={setUpload}
                          setAccount={setAccount}
                          setShowCanvasPopupModal={setShowCanvasPopupModal}
                          showCanvasPopupModal={showCanvasPopupModal}
                        />
                      )}

                      <div>


                        {/* <Button onClick={handleOpen}>Open Facade Designer</Button> */}

                        {/* <FacadeDesigner showModal={showModal} handleClose={handleClose} projectId={projectId} projects={projects} newProjectImage={newProjectImage} resetNewProjectImage={resetNewProjectImage} userRole={userRole} /> */}
                        {/* <FacadeDesigner
                        projects={projects}
                        projectId={projectId}
                        newProjectImage={newProjectImage}
                        resetNewProjectImage={resetNewProjectImage}
                        userRole={userRole}
                        isDesignerModalOpen={isDesignerModalOpen}
                        setDesignerModalOpen={setDesignerModalOpen}
                        handleAnalyticsEvent={handleAnalyticsEvent} // Pass the analytics function
                        applyColorsToCanvasItems={applyColorsToCanvasItems}
                        triggerAIDetectionForOpenedProject={triggerAIDetectionForOpenedProject}
                        fabricCanvas={canvas} // Pass the canvas as a prop
                        saveUpdate={saveUpdate}
                        isDetectionTriggered={isDetectionTriggered}
                        setIsDetectionTriggered={setIsDetectionTriggered}
                        detectAndApplyColors={detectAndApplyColors}
                        projectChangeKey={projectChangeKey}
                        handleHideLoader={handleHideLoader}
                        handleShowLoader={handleShowLoader}
                        roboflowFoundDetections={roboflowFoundDetections}
                        setRoboflowFoundDetections={setRoboflowFoundDetections}
                        setGeminiScaleFactor={setGeminiScaleFactor}
                        backgroundImageUrl={backgroundImageUrl}

                      /> */}

                      </div>



                      {projectName && (
                        <div className="zoom-buttons">
                          <div className="zoomout-wrap">
                            <ZoomAndPan
                              show={isZoomModalOpen}
                              onHide={() => setIsZoomModalOpen(false)}
                              canvas={canvas}
                              projectName={projectName}
                              canvasWidth={canvasWidth}
                              canvasHeight={canvasHeight}
                            />



                          </div>

                        </div>

                      )}

                    </div>
                  </>


                )}
                {/* <div className="bannersContainer"> */}
                {/* Sticky Banner under the canvas */}
                {/* <div className="sticky-banner">
                
                  {userPlan === "Free" && projectName && <Banner1 onContactTextClick={handleOpenContactForm} />}

                </div> */}

              </div>
            </div>}
          </div>
        </div>
      </div>
      {projectName && <Routes>
        <Route path="/cookies" element={null} />
        <Route path="/terms" element={null} />


        <Route path="/" element={<Home setAccount={() => setAccount(true)} handleAnalyticsEvent={handleAnalyticsEvent} handleGuestLogin={handleGuestLogin} />} />

        <Route path="/fr" element={<Home setAccount={() => setAccount(true)} handleAnalyticsEvent={handleAnalyticsEvent} handleGuestLogin={handleGuestLogin} />} />

      </Routes>}


      <ScrollToTopButton />
    </div>
  );
};

export default App;
