import React, { useState } from "react";
import { toast } from "react-toastify";
import { storage } from "../../base";
import { ref, listAll, getMetadata, deleteObject } from "firebase/storage";
import { Button, Modal, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AdminDashboard = () => {
    const { t } = useTranslation();
    const [showImagesModal, setShowImagesModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [imageList, setImageList] = useState([]);
    const [loading, setLoading] = useState(false);

    const threeMonthsAgo = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

    // Fetch images older than 3 months
    const fetchOldImages = async () => {
        setLoading(true);
        setImageList([]);
        console.log("Fetching images older than 3 months...");

        try {
            const storageRef = ref(storage, "usersProjectsImages/");
            const userFolders = await listAll(storageRef);

            console.log(`Found ${userFolders.prefixes.length} user folders.`);
            let allImages = [];

            for (const userFolder of userFolders.prefixes) {
                console.log(`Checking user folder: ${userFolder.fullPath}`);

                const mainCanvasFolder = `${userFolder.fullPath}/canvasImages`;
                const subfolderNames = ["canvasImagesResized", "canvasImagesTreated", "canvasImagesWebP"];

                // Function to process a folder
                const processFolder = async (folderPath) => {
                    try {
                        const folderRef = ref(storage, folderPath);
                        const images = await listAll(folderRef);
                        console.log(`Found ${images.items.length} images in ${folderPath}`);

                        for (const imgRef of images.items) {
                            try {
                                const metadata = await getMetadata(imgRef);
                                const updatedDate = metadata.updated ? new Date(metadata.updated) : null;

                                if (!updatedDate || updatedDate >= threeMonthsAgo) continue; // Skip recent images

                                if (imgRef.name.toLowerCase().includes("duplex")) continue; // Skip "duplex" images

                                allImages.push({
                                    name: imgRef.name,
                                    fullPath: imgRef.fullPath,
                                    updated: updatedDate.toLocaleString(),
                                    size: (metadata.size / 1024).toFixed(2) + " KB",
                                });
                            } catch (error) {
                                console.warn(`Could not fetch metadata for ${imgRef.fullPath}:`, error);
                            }
                        }
                    } catch (error) {
                        console.warn(`Could not list images in ${folderPath}:`, error);
                    }
                };

                // Process main canvasImages folder
                await processFolder(mainCanvasFolder);

                // Process subfolders inside canvasImages/
                for (const subfolderName of subfolderNames) {
                    await processFolder(`${mainCanvasFolder}/${subfolderName}`);
                }
            }

            if (allImages.length === 0) {
                console.log("No old images found.");
                toast.info("No images older than 3 months found in storage.");
            } else {
                console.log(`Total images found: ${allImages.length}`);
                setImageList(allImages);
                setShowImagesModal(true);
            }
        } catch (error) {
            console.error("Error fetching images:", error);
            toast.error("Error retrieving images from storage.");
        }
        setLoading(false);
    };

    // Delete all images found
    const confirmDeletion = async () => {
        setShowConfirmModal(false);
        setLoading(true);

        try {
            for (const image of imageList) {
                console.log(`Deleting image: ${image.fullPath}`);
                const fileRef = ref(storage, image.fullPath);
                await deleteObject(fileRef);
            }

            toast.success("All selected images (except protected ones) have been deleted.");
            setImageList([]);
            setShowImagesModal(false);
        } catch (error) {
            console.error("Error deleting images:", error);
            toast.error("Error deleting selected images.");
        }

        setLoading(false);
    };

    return (
        <>
            <Button onClick={fetchOldImages} variant="info" className="mb-3">
                {t("Find and Delete Images Older Than 3 Months")}
            </Button>

            {/* Modal to display old images */}
            <Modal show={showImagesModal} onHide={() => setShowImagesModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t("Images Older Than 3 Months")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <p>{t("Loading...")}</p>
                    ) : imageList.length > 0 ? (
                        <>
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>{t("Image Name")}</th>
                                        <th>{t("Last Updated")}</th>
                                        <th>{t("Size")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {imageList.map((image, index) => (
                                        <tr key={index}>
                                            <td>{image.name}</td>
                                            <td>{image.updated}</td>
                                            <td>{image.size}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Button variant="danger" onClick={() => setShowConfirmModal(true)}>
                                {t("Confirm Delete All")}
                            </Button>
                        </>
                    ) : (
                        <p>{t("No old images found.")}</p>
                    )}
                </Modal.Body>
            </Modal>

            {/* Confirmation Modal */}
            <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Confirm Deletion")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t("Are you sure you want to delete these images? This action cannot be undone.")}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="danger" onClick={confirmDeletion}>
                        {t("Delete")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AdminDashboard;
