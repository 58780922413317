import { useTranslation } from "react-i18next";
import { presetColors } from "./presetColors"; // Ensure correct path

export const useFindCarouselColor = () => {
  const { t } = useTranslation();

  return (colorName) => {
    if (!colorName) return { color: "#ffffff", name: t("itemColor-white") };

    // ✅ Normalize color name (remove spaces, accents, lowercase)
    const formattedName = colorName
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") // Remove accents
      .replace(/\s+/g, "") // Remove spaces
      .toLowerCase();

    // ✅ Search for color in `presetColors`
    const matchedColor = presetColors.find((color) => {
      const normalizedTitleKey = color.titleKey.replace("itemColor-", "").toLowerCase();
      return formattedName === normalizedTitleKey;
    });

    if (!matchedColor) return { color: "#ffffff", name: t("itemColor-white") };

    // ✅ Fetch translated name
    const translatedName = t(matchedColor.titleKey);

    // ✅ Capitalize first letter of translated name
    const capitalizedName = translatedName.charAt(0).toUpperCase() + translatedName.slice(1);

    return { color: matchedColor.color, name: capitalizedName };
  };
};
