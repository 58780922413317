import React, { useState, useMemo, useEffect } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "bootstrap/dist/css/bootstrap.css";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useTranslation } from "react-i18next";
import { useAuth, db } from "../base"; // Firebase configuration
import { doc, updateDoc, getDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import EyeDropperComponent from "./eyeDropper/EyeDropperComponent";
import presetColors from "../components/colors/presetColors"; // Import the external preset colors

function ItemColors(props) {
  const { t, i18n } = useTranslation(["translation"]);
  const [selectedColor, setSelectedColor] = useState("#ffffff");
  const [customColors, setCustomColors] = useState([]);
  const [selectedMode, setSelectedMode] = useState("Selected Item");
  const [isPickerVisible, setIsPickerVisible] = useState(false);
  const [isCustomColorsVisible, setIsCustomColorsVisible] = useState(false);

  const currentUser = useAuth(); // Get the current logged-in user

  const togglePickerVisibility = () => {
    setIsPickerVisible(!isPickerVisible);
  };

  const closePicker = () => {
    setIsPickerVisible(false);
  };

  // Fetch custom colors from Firebase for the logged-in user
  useEffect(() => {
    const fetchCustomColors = async () => {
      if (currentUser) {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          setCustomColors(userDocSnap.data().customColors || []);
        }
      }
    };

    fetchCustomColors();
  }, [currentUser]);

  // Save custom color to Firestore
  const saveColorToFirebase = async (pickedColor) => {
    if (currentUser) {
      const userDocRef = doc(db, "users", currentUser.uid);
      try {
        await updateDoc(userDocRef, {
          customColors: arrayUnion(pickedColor),
        });
        setCustomColors([...customColors, pickedColor]);
      } catch (error) {
        console.error("Error saving color to Firestore: ", error);
      }
    }
  };

  // Delete custom color from Firestore
  const deleteColorFromFirebase = async (colorObject) => {
    if (currentUser) {
      const userDocRef = doc(db, "users", currentUser.uid);
      try {
        await updateDoc(userDocRef, {
          customColors: arrayRemove(colorObject),
        });
        setCustomColors(customColors.filter((item) => item.color !== colorObject.color));
      } catch (error) {
        console.error("Error removing color from Firestore: ", error);
      }
    }
  };

  // Handle color change and apply color
  const handleColorChange = (color) => {
    setSelectedColor(color.hex);

    let itemType = "";
    if (selectedMode === "All Windows") itemType = "window";
    if (selectedMode === "All Doors") itemType = "doors";

    if (selectedMode === "Selected Item") {
      props.updateColor?.(color.hex);
      props.handleColor?.(color);
    } else if (itemType && props.applyColorToSpecificItems) {
      console.log(`Applying color to all ${itemType}`);
      props.applyColorToSpecificItems(itemType, color.hex);
    }

    closePicker();
  };

  return (
    <div>
      {/* Color Picker */}
      <Tippy
        interactive
        placement={"top"}
        visible={isPickerVisible}
        onClickOutside={closePicker}
        content={
          <div className="sketch-picker-container" style={{ minWidth: "220px", padding: "10px" }}>
            
            {/* Move the Dropdown Selector Above the Color Swatches */}
            <div className="color-mode-selector" style={{ marginBottom: "10px" }}>
              <label htmlFor="colorMode" style={{ fontSize: "12px", fontWeight: "bold", color: "#fff", marginBottom: "5px", display: "block" }}>
                {t("itemColor-applyMode")}
              </label>
              <select
                id="colorMode"
                className="form-select"
                value={selectedMode}
                onChange={(e) => setSelectedMode(e.target.value)}
                style={{
                  padding: "4px 6px",
                  fontSize: "12px",
                  width: "100%",
                  maxWidth: "90vw",
                  backgroundColor: "#444",
                  color: "#fff",
                  border: "1px solid #666",
                  borderRadius: "4px",
                }}
              >
                <option value="Selected Item">{t("itemColor-selectedItem")}</option>
                <option value="All Windows">{t("itemColor-allWindows")}</option>
                <option value="All Doors">{t("itemColor-allDoors")}</option>
              </select>
            </div>

            {/* Preset Colors */}
            <div className="color-swatch-container">
              {presetColors.map((colorObj, index) => (
                <div
                  key={index}
                  className="color-swatch"
                  style={{ backgroundColor: colorObj.color, height: "30px", width: "30px", margin: "3px", cursor: "pointer", borderRadius: "4px" }}
                  title={t(colorObj.titleKey)}
                  onClick={() => handleColorChange({ hex: colorObj.color })}
                />
              ))}
            </div>

            {/* Custom Colors Section */}
            <h4 onClick={() => setIsCustomColorsVisible(!isCustomColorsVisible)}
              style={{ cursor: 'pointer', color: "#4CAF50", marginTop: "10px", marginBottom: "5px" }}>
              {t("itemColor-customcolors")}
            </h4>
            {isCustomColorsVisible && (
              <div className="color-swatch-container">
                {customColors.map((colorObject, index) => (
                  <div key={index} className="custom-color-entry" style={{ alignItems: "center" }}>
                    <div
                      className="color-swatch"
                      style={{ backgroundColor: colorObject.color, width: "30px", height: "30px", marginRight: "5px", borderRadius: "4px" }}
                      onClick={() => handleColorChange({ hex: colorObject.color })}
                    />
                    <span style={{ color: "red", cursor: "pointer", fontSize: "14px" }} 
                      onClick={() => deleteColorFromFirebase(colorObject)}>✖</span>
                  </div>
                ))}
              </div>
            )}

            {/* EyeDropper Tool */}
            <EyeDropperComponent onColorSelected={(color) => handleColorChange({ hex: color })} />
          </div>
        }
      >
        <DropdownButton
          title={<img className="colorButton" src="./icons/colorBucketButton.png" height="41px" alt="Color" />}
          onClick={togglePickerVisibility}
          className="btn mt-1 btn-block pb-0 m-0"
          id="dropdown-button-dark"
          variant="primary"
          size="sm"
          drop="up"
        />
      </Tippy>
    </div>
  );
}

export default ItemColors;
