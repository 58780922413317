import React from "react";
import ItemSelection from "../ItemSelection"; 
import ItemColors from "../ItemColors";
import Grids from "../options/grids/Grids";
import ShapeSelection from "../ShapeSelection";
import ToolsSelection from "../tools/ToolsSelection"; // Import ToolsSelection
import { DropdownButton, Dropdown } from "react-bootstrap";
import "./MenuComponent.css"; // Import the new CSS file

function MenuComponent({
  isFixed,
  handlePanel,
  typeName,
  addOption,
  projectName,
  isSelectedItem,
  updateColor,
  handleColorChange,
  eyePickerActiveColor,
  applyColorToSpecificItems,
  setIsOptionsPanelVisible,
  handleOptionsPanel,
  itemColor,
  setIsItemAdded,
  isCalibrating,
  windowURL,
  isSameType,
  isMixMatched,
  checkForSeletedItems,
  checkSelectedObjectType,
  toggleOptionsPanel,
  getSelectedItemType,
  activeObjectDetails,
  canvas,
  handleActionChange,
  copyItem,
  selectAll,
  cutItem,
  removeAllObjects2,
  pasteItem,
  copyAndPasteItem,
  objectfliphorizontal,
  objectflipvertical,
  drawGrid,
  unGroupItems,
  setModeType,
  setEraseMode,
  setDrawMode,
  changePerspective,
  calibrationDisplay,
  t,
  scaleFactor,
  showCalibrationOverlay,
  handleDropdownChange
}) {
  return (
    <div
      className={`menu-container ${isFixed ? "fixed" : "absolute"}`}
    >
      <ItemSelection handlePanel={handlePanel} typeName={typeName} />
      {projectName && (
        <ItemColors
          updateColor={updateColor}
          pickerColor={eyePickerActiveColor}
          onColorChange={handleColorChange}
          applyColorToSpecificItems={applyColorToSpecificItems}
        />
      )}
      {isSelectedItem && (
        <Grids
          isSelectedItem={isSelectedItem}
          handleOptionsPanel={handleOptionsPanel}
          addOption={addOption}
          typeName={typeName}
          windowURL={windowURL}
          isSameType={isSameType}
          isMixMatched={isMixMatched}
          checkForSeletedItems={checkForSeletedItems}
          checkSelectedObjectType={checkSelectedObjectType}
          toggleOptionsPanel={toggleOptionsPanel}
          isInCalibrationMode={isCalibrating}
          getSelectedItemType={getSelectedItemType}
          activeObjectDetails={activeObjectDetails}
        />
      )}
      <ShapeSelection
        canvas={canvas}
        itemColor={itemColor}
        setIsOptionsPanelVisible={setIsOptionsPanelVisible}
        setIsItemAdded={setIsItemAdded}
      />

      {/* ToolsSelection */}
      {projectName && (
        <ToolsSelection
          changeAction={(e) => handleActionChange(e)}
          isObjectSelected={isSelectedItem}
          copyItem={() => copyItem(canvas)}
          selectAll={() => selectAll()}
          cutItem={() => cutItem(canvas)}
          removeAllObjects2={() => removeAllObjects2(canvas)}
          pasteItem={() => pasteItem(canvas)}
          copyAndPasteItem={() => copyAndPasteItem(canvas)}
          flipX={() => objectfliphorizontal(canvas)}
          flipY={() => objectflipvertical(canvas)}
          drawGrid={() => drawGrid(canvas, 50)}
          ungroupItems={() => unGroupItems(canvas)}
          setModeType={setModeType}
          setEraseMode={setEraseMode}
          setDrawMode={setDrawMode}
          changePerspective={() => changePerspective()}
        />
      )}

      {/* Calibration Dropdown */}
      {/* {projectName && (
        <div className={"calibration"}>
          <DropdownButton
            title={
              <img
                className="addItemsButton"
                src="./icons/compast.png"
                height="40px"
                alt="Items"
                title="Calibration"
                style={{ filter: "invert(100%)" }}
              />
            }
            className="btn mt-1 btn-block pb-0 m-0"
            variant="primary"
            menuVariant="dark"
            size="sm"
            drop="bottom"
            autoClose="true"
            onSelect={handleDropdownChange}
          >
            <Dropdown.Item eventKey="removeDisplay">
              {!calibrationDisplay
                ? t("app-turnoncalibration")
                : scaleFactor === 1
                ? t("app-calibrationdisplaynull")
                : t("app-turnoffcalibration")}
            </Dropdown.Item>
            <Dropdown.Item eventKey="toggleCalibration">
              {scaleFactor < 1
                ? t("app-recalibration")
                : showCalibrationOverlay
                ? t("app-hidecalibration")
                : t("app-showcalibration")}
            </Dropdown.Item>
          </DropdownButton>
        </div>
      )} */}
    </div>
  );
}

export default MenuComponent;
