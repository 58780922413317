import React from "react";
import "../itemSelectionView/SelectionComponent.css";
import ItemSelectionView from "./ItemSelectionView";
import AccessoriesSelectionView from "../accessories/AccessoriesSelectionView";
import AccessoriesSelection from "../accessories/AccessoriesSelection";
import OptionsSelectionView from "../optionsSelectionView/OptionsSelectionView";
import TransumSelectionView from "../options/transums/TransumSelectionView";
import DoorsSelectionView from "../doorsSelectionView/DoorsSelectionView";

const SelectionComponent = (props) => {
  const {
    isFixed,
    isPanelVisible,
    isAccessoriesPanelVisible,
    isAccessoryTypeSelected,
    isOptionsPanelVisible,
    isTransumPanelVisible,
    isAvaillableDoorsPanelVisible,
    itemToSearch,
    addItem,
    typeName,
    accessoryType,
    setIsPanelVisible,
    handleDoorsView,
    canvasHeight,
    setIsAccessoryTypeSelected,
    setAccessoryType,
    setIsAccessoriesPanelVisible,
    handleAddAccessory,
    addOption,
    addTransum,
    windowURL,
    setGlassToSearch,
    setStringToSearch,
    setStringToSearchSide,
    setIsOptionsPanelVisible,
    setIsAvaillableDoorsPanelVisible,
    setIsTransumPanelVisible,
  } = props;

  const panelConfig = [
    {
      isVisible: isPanelVisible,
      className: "itemSelectionView",
      component: (
        <ItemSelectionView
          itemToSearch={itemToSearch}
          addItem={addItem}
          typeName={typeName}
          setIsPanelVisible={setIsPanelVisible}
          handleDoorsView={handleDoorsView}
          canvasHeight={canvasHeight}
        />
      ),
    },
    {
      isVisible: isAccessoriesPanelVisible,
      className: "itemSelectionView",
      component: (
        <AccessoriesSelectionView
          setIsAccessoryTypeSelected={setIsAccessoryTypeSelected}
          setIsPanelVisible={setIsPanelVisible}
          setAccessoryType={setAccessoryType}
          setIsAccessoriesPanelVisible={setIsAccessoriesPanelVisible}
        />
      ),
    },
    {
      isVisible: isAccessoryTypeSelected,
      className: "itemSelectionView",
      component: (
        <AccessoriesSelection
          accessoryType={accessoryType}
          addItem={handleAddAccessory}
          setIsAccessoriesSelectionPanelVisible={setIsAccessoriesPanelVisible}
          setIsAccessoriesPanelVisible={setIsAccessoriesPanelVisible}
          setIsAccessoryTypeSelected={setIsAccessoryTypeSelected}
        />
      ),
    },
    {
      isVisible: isOptionsPanelVisible,
      className: "optionsSelectionView",
      component: (
        <OptionsSelectionView
          isTransum={isTransumPanelVisible}
          setIsTransum={setIsTransumPanelVisible}
          addOption={addOption}
          addTransum={addTransum}
          windowURL={windowURL}
          typeName={typeName}
          setGlassToSearch={setGlassToSearch}
          setStringToSearch={setStringToSearch}
          setStringToSearchSide={setStringToSearchSide}
          setIsOptionsPanelVisible={setIsOptionsPanelVisible}
        />
      ),
    },
    {
      isVisible: isTransumPanelVisible,
      className: "transumSelectionView",
      component: (
        <TransumSelectionView
          addOption={addOption}
          windowURL={windowURL}
          typeName={typeName}
          setGlassToSearch={setGlassToSearch}
          setIsOptionsPanelVisible={setIsOptionsPanelVisible}
        />
      ),
    },
    {
      isVisible: isAvaillableDoorsPanelVisible,
      className: "doorsSelectionView",
      component: (
        <DoorsSelectionView
          addItem={addItem}
          windowURL={windowURL}
          typeName={typeName}
          itemToSearch={itemToSearch}
          setIsOptionsPanelVisible={setIsOptionsPanelVisible}
          setIsPanelVisible={setIsPanelVisible}
          setIsAvaillableDoorsPanelVisible={setIsAvaillableDoorsPanelVisible}
          setIsAccessoriesPanelVisible={setIsAccessoriesPanelVisible}
        />
      ),
    },
  ].filter(panel => panel.isVisible); // Filter out invisible panels

  // If no panels are visible, don't render anything
  if (panelConfig.length === 0) return null;

  return (
    <div className={`selectionComponent ${isFixed ? "fixed" : ""}`}>
      {panelConfig.map((panel, index) => (
        <div key={index} className={panel.className}>
          {panel.component}
        </div>
      ))}
    </div>
  );
};

export default SelectionComponent;
