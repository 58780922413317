import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useTranslation } from "react-i18next";
import "../homepage/home.css";
import FeatureSection from "./FeatureSection";
import Contact from "./Contact";
import About from "./About";
import FAQ from "../help/FAQ";
import ImageSlider from "../../components/ImageSlider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WelcomeVideoModal from "./WelcomeVideoModal";

function Home(props) {
  const { i18n, t } = useTranslation();
  const [isFAQOpen, setIsFAQOpen] = useState(false);
  const faqRef = useRef(null);

  // Load the language namespace for AI features if missing
  useEffect(() => {
    if (!i18n.hasResourceBundle(i18n.language, "aiFeatureLang")) {
      i18n
        .loadNamespaces("aiFeatureLang")
        .then(() => console.log("aiFeatureLang namespace loaded"))
        .catch((err) => console.error("Error loading aiFeatureLang namespace:", err));
    }
  }, [i18n]);

  const images = [
    "/maisons-modeles/converted_images/maison_demo_App-min_old2.webp",
    "/maisons-modeles/converted_images/test_erase_1-min.webp",
    "/maisons-modeles/converted_images/test_erase_1 (1)-min.webp",
    "/maisons-modeles/converted_images/test_erase_1 (2)-min.webp",
  ];

  const openFAQ = () => {
    setIsFAQOpen(true);
    faqRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      {/* Hero Section */}
      <header className="masthead container-fluid text-center text-white py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <h2>{t("masthead.transformYourHome")}</h2>
              <h1>{t("masthead.dontJustImagine")}</h1>
              <a
                href="#guest"
                className="tryGuest btn btn-outline-light mt-3"
                onClick={(e) => {
                  e.preventDefault();
                  props.handleGuestLogin();
                }}
              >
                {t("masthead.tryAsGuest")}
              </a>
            </div>
            <div className="col-md-5">
              <WelcomeVideoModal isInline={true} />
            </div>
          </div>
        </div>
      </header>

      {/* Feature Section */}
      <section className="feature-section container py-5">
        <h2 className="text-center">{t("aiFeatureLang.intro.title")}</h2>
        <p className="text-center subtitle">{t("aiFeatureLang.intro.description")}</p>
        <div className="row">
          {["step1", "step2", "step3", "step4"].map((step, index) => (
            <div key={index} className="col-lg-3 col-md-6 col-sm-12 text-center mb-4">
              <img
                src={t(`aiFeatureLang.steps.${step}.mediaPath`)}
                alt={t(`aiFeatureLang.steps.${step}.altText`)}
                className="img-fluid mb-3"
              />
              <h5 className="text-primary">{t(`aiFeatureLang.steps.${step}.title`)}</h5>
              <p className="text-muted">{t(`aiFeatureLang.steps.${step}.description`)}</p>
            </div>
          ))}
        </div>
        <div className="text-center">
          <a
            href="#guest"
            className="btn btn-outline-primary mt-4"
            onClick={(e) => {
              e.preventDefault();
              props.handleGuestLogin();
            }}
          >
            {t("aiFeatureLang.cta")}
          </a>
        </div>
      </section>

      {/* Slider Section */}
      <section className="slider-section container-fluid py-5 bg-light">
        <div className="container">
          <h2 className="text-center showcase-title mb-4">{t("slider.title")}</h2>
          <div className="row">
            <div className="col-md-6">
              <img
                src={images[0]}
                alt="Original House"
                className="img-fluid rounded shadow-sm"
              />
            </div>
            <div className="col-md-6">
              <ImageSlider images={images} />
            </div>
          </div>
        </div>
      </section>

{/* How It Works Section */}
<section id="howitworks" className="how-it-works-section py-5">
  <div className="container">
    <FeatureSection
      titleKey="homepage.how_it_works.title"
      descriptionKeys={[
        "homepage.how_it_works.steps.step1",
        "homepage.how_it_works.steps.step2",
        "homepage.how_it_works.steps.step3",
        "homepage.how_it_works.steps.step4",
      ]}
      handleGuestLogin={props.handleGuestLogin}
      mediaKey="feature1.mediaPath"
    />
  </div>
</section>

{/* About Section */}
<section id="about" className="about-section py-5">
  <div className="container">
    <About />
  </div>
</section>

<section id="contact" className="contact-section container-fluid py-5 bg-light">
  <div className="container d-flex flex-column align-items-center">
    <Contact openFAQ={openFAQ} />
  </div>
</section>



      <section id="faq" className="faq-section container-fluid py-5">
        <div className="container">
          <div ref={faqRef}>
            <FAQ isFAQOpen={isFAQOpen} />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
