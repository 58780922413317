import { fabric } from "fabric-with-erasing";
import { saveCanvasState } from "../../helpers/helpers";
import { v1 as uuid } from "uuid";

// New function to add accessory images to the canvas
export function addAccessoryImage(accessory, props, itemColor) {
  const canvas = props.canvas;

  function setDecorativeObjectControls(object) {
    // Ensure controls are visible but only allow uniform scaling
      
    object.setControlsVisibility({
      mt: false, // middle top (allow)
      mb: false, // middle bottom (allow)
      ml: false, // middle left (disallow)
      mr: false, // middle right (disallow)
      bl: true, // bottom left (disallow)
      br: true, // bottom right (disallow)
      tl: true, // top left (disallow)
      tr: true, // top right (disallow)
      mtr: true, // rotating point (disallow)
    });
    
    // Since you want uniform scaling, ensure `lockUniScaling` is false.
    // This may seem counterintuitive, but `lockUniScaling` being false does not prevent uniform scaling;
    // it just doesn't force it when other controls are used. Since we're disabling those, it's okay.
    // object.lockUniScaling = true;
  }
  
  
  console.log(accessory.type)

  fabric.Image.fromURL(process.env.PUBLIC_URL + accessory.url, function(img) {

    console.log(accessory.type)
    // Apply a color filter if itemColor is specified
    if (itemColor && accessory.type !== "Decorative") {
      const filter = new fabric.Image.filters.BlendColor({
        color: itemColor,
        mode: 'tint',
        alpha: 0.5 // Adjust alpha to control the intensity of the color
      });
      img.filters.push(filter);
      img.applyFilters();
    }

    // Set image properties
    img.set({
        id: uuid(),
        groupId: uuid(),
        originX: 'center',
        originY: 'top',
        left: canvas.width / 2,
        top: 100,
        scaleX: 0.3,
        scaleY: 0.3,
        itemType: 'accessory', // Custom property to identify accessory images
      });

      // Customize controls for decorative objects
    if (accessory.type === "Decorative") {
        setDecorativeObjectControls(img);
      }

    // Add the image to the canvas
    canvas.add(img).setActiveObject(img);
    saveCanvasState(canvas);
  });
}

export default addAccessoryImage;
