import React from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.css";
import { addShape } from '../helpers/handleShapes'
import { useTranslation } from "react-i18next";

function ShapeSelection(props) {
  const { t, i18n } = useTranslation(["translation"]);

  return (
    <>
      <DropdownButton
      title={
        <img
        className="shapesButton"
          src="./icons/shapesButton.png"
          height="40px"
          alt="Shapes"
          title={t("shapeSelection-shapes")}
        />
      }
        id="dropdown-button-dark-example2"
        variant="primary"
        menuVariant="dark"
        drop="up"
        size="sm"
        onSelect={(e)=> addShape(e, props)}
        className="btn mt-1 btn-block pb-0 m-0"
      >
        <div className="dropdownSection">
          <div className="dropdownTitleSection">{t("shapeSelection-shapes")}</div>
        <Dropdown.Item className="itemTypeTitle" eventKey="circle">{t("shapeSelection-circle")}</Dropdown.Item>
        <Dropdown.Item className="itemTypeTitle" eventKey="rectangle">{t("shapeSelection-rectangle")}</Dropdown.Item>
        <Dropdown.Item className="itemTypeTitle" eventKey="triangle">{t("shapeSelection-triangle")}</Dropdown.Item>
        </div>
      </DropdownButton>
      
    </>
  );
}

export default ShapeSelection;
